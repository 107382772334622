export const DEFAULT_SORT = "-system.modifiedAt";

export type MapPaginationDataList<Type> = Map<string, PaginationDataList<Type>>;

export interface PaginationDataList<Type> {
  data: Type[];
  pageIndex: number;
  itemsInPage: number;
  sort?: string;
  searchTerm?: string;
  isLoading: boolean;
  totalItems?: number;
}

export interface CalendarPaginationDataList<Type> {
  data: Type[];
  months: Array<{ from: Date; to: Date }>;
  isLoading: boolean;
}

export interface DataItem<Type> {
  data?: Type;
  isLoading: boolean;
}

export const getSkipAndLimitFromPage = (args: {
  pageIndex: number;
  itemsInPage: number;
  sortBy?: string;
}): any => {
  const skip: number = args.pageIndex * args.itemsInPage;
  const limit: number = args.itemsInPage;
  const sort = { sort: args.sortBy ?? DEFAULT_SORT };
  return { skip, limit, ...sort };
};
