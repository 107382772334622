import React from "react";
import "./fixed.controller.toolbar.component.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalStore, ModalType } from "stores/modal.store";
import Row from "components/general.compoenents/row.component/row.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";

interface FixedControllersToolbarProps {
  hasUnsavedChanges: boolean;
  disabled: boolean;
  hideSubmitButton?: boolean;
  formID?: string;
  isLoading?: boolean;
  keyString?: string;
  className?: string;
  variant?: "default" | "primary";
  title?: string;
  selectedStatus?: any;
  imageUrl?: string;
  options?: any[];
  modalStore?: ModalStore;
  fromIsDirty?: boolean;
  handleDelete?: () => Promise<void>;
  handleStatusSelect?: (option: any) => void;
  renderButtons?: () => JSX.Element;
}

const FixedControllersToolbar = ({
  hasUnsavedChanges,
  formID,
  disabled,
  isLoading = false,
  keyString = Date.now().toString(),
  className,
  variant = "default",
  selectedStatus,
  title,
  imageUrl,
  options,
  modalStore,
  hideSubmitButton = false,
  fromIsDirty = true,
  handleDelete,
  handleStatusSelect,
  renderButtons,
}: FixedControllersToolbarProps): JSX.Element => {
  const { t } = useTranslation();

  const fixedControllersToolbarWrapperClassName = classNames(
    {
      "fixed-controllers-toolbar": true,
      "fixed-controllers-toolbar--default": variant === "default",
      "fixed-controllers-toolbar--primary": variant === "primary",
    },
    className
  );

  const leftSideClassName = classNames(
    "mr-20 fixed-controllers-toolbar--left-side",
    {
      "fixed-controllers-toolbar--left-side-large":
        !options && !handleStatusSelect,
    }
  );

  const imageClassName = classNames({
    "visibility-hidden": !imageUrl,
  });

  // ! Handlers
  // Function to handle status change
  const onDropdownSelect = (option: any): void => {
    handleStatusSelect?.(option);
  };

  // hide toolbar when any other modal is open
  if (modalStore?.currentModal !== ModalType.INVISIBLE) return <></>;

  return (
    <Row
      justifyContent="space-between"
      alignItems="center"
      className={fixedControllersToolbarWrapperClassName}
    >
      <Row alignItems="center" gap={15} className={leftSideClassName}>
        <Image
          className={imageClassName}
          rounded={false}
          imageUrl={imageUrl}
          size={ImageSizes.M}
        />
        {title && (
          <SmallHeadline className="toolbar-title">{title}</SmallHeadline>
        )}
      </Row>
      <Row
        alignItems="center"
        gap={15}
        justifyContent="flex-end"
        className="mr-20 fixed-controllers-toolbar--right-side"
      >
        {handleDelete && (
          <div className="fixed-controllers-toolbar--delete-button">
            <FontAwesomeIcon
              className="fixed-controllers-toolbar--delete-button--icon"
              icon={faTrashCan}
              onClick={handleDelete}
            />
          </div>
        )}
        {handleStatusSelect && options && (
          <div className="fixed-controllers-toolbar-select-dropdown">
            <SelectDropDown
              selectedItem={selectedStatus}
              onChange={onDropdownSelect}
              items={options ?? []}
              menuPlacement="top"
            />
          </div>
        )}

        {!hideSubmitButton && renderButtons == null && (
          <FilledButton
            label={
              hasUnsavedChanges ? t("formNavbar.save") : t("formNavbar.saved")
            }
            type="submit"
            form={formID}
            disabled={disabled || !fromIsDirty}
            key={keyString}
            isLoading={isLoading}
          />
        )}

        {renderButtons?.()}
      </Row>
    </Row>
  );
};

export default inject("modalStore")(observer(FixedControllersToolbar));
