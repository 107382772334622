import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { SignUpCredentials } from "services/httpClients/http.auth.client";
import UserStore from "stores/user.store";
import "./sign.up.page.scss";
import { inject, observer } from "mobx-react";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticationSchema } from "schemas/authentication.schema";
import GoogleAuth from "components/auth.components/google.auth.component/google.auth.component";
import Center from "components/general.compoenents/center.component/center.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import Row from "components/general.compoenents/row.component/row.component";
import { useTranslation } from "react-i18next";

interface SignUpPageProps {
  userStore?: UserStore;
}

const SignUpPage = ({ userStore }: SignUpPageProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(authenticationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleSignUp = async (data: any): Promise<void> => {
    setIsLoading(true);
    const credentials: SignUpCredentials = { ...data, kind: "INTERNAL" };

    const user = await userStore?.signUp(credentials);
    if (user != null) {
      navigate("/");
    }

    setIsLoading(false);
  };

  const _buildForm = (): JSX.Element => {
    return (
      <div className="sign-up-form-container">
        <div className="sign-up-form-wrapper">
          <Headline> {t("auth.welcome")}</Headline>
          <RunningText className="mb-20">
            {t("auth.signUpDescription")}
          </RunningText>
          <form
            className="sign-up-form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(handleSignUp)}
            noValidate
          >
            <OutlinedTextInput
              type="email"
              placeholder={t("auth.email") ?? "Email"}
              inputRef={register("email")}
              inputRefValue={getValues("email")}
              validationMessage={errors.email?.message?.toString()}
            />
            <OutlinedTextInput
              className="mt-15"
              type="password"
              placeholder={t("auth.password") ?? "Password"}
              inputRef={register("password")}
              inputRefValue={getValues("password")}
              validationMessage={errors.password?.message?.toString()}
            />
            <FilledButton
              type="submit"
              label={t("auth.signUp")}
              isLoading={isLoading}
              className="mt-15 mb-15 full-width"
            />

            <Center className="mb-15">
              <Spacer width="90%" />
            </Center>

            <Center className="mb-15 mt-15">
              <GoogleAuth />
            </Center>
            <div className="additional-actions">
              <RunningText navLink="/sign-in" className=" mt-10">
                {t("auth.alreadyRegistered")}
              </RunningText>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="sign-up-form-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="sign-up-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="sign-up-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="sign-up-page">
      <div className="sign-up-page-wrapper">
        {_buildPreview()}
        <div className="auth-form-wrapper">{_buildForm()}</div>
      </div>
    </Row>
  );
};

export default inject("userStore")(observer(SignUpPage));
