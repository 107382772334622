import React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ModalStore, ModalType, ModalSize } from "stores/modal.store";
import TaskStore from "stores/task.store";
import { Task } from "schemas/task.schemas/task.schema";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import {
  TaskStatusType,
  PriorityType,
  TaskType,
} from "globals/enums/global.enum";

import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import Column from "components/general.compoenents/column.component/column.component";

interface TaskListProps {
  tasks: Task[];
  onClick?: (task: Task) => void;
  isLoading?: boolean;
  taskStore?: TaskStore;
  modalStore?: ModalStore;
}

const TaskList = ({
  tasks,
  onClick,
  isLoading = false,
  taskStore,
  modalStore,
}: TaskListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.overview");

  const _setTaskStatusValue = (status: string): any => {
    switch (status) {
      case TaskStatusType.BACKLOG:
        return translate("status.backlog");
      case TaskStatusType.TO_DO:
        return translate("status.toDo");
      case TaskStatusType.IN_PROGRESS:
        return translate("status.inProgress");
      case TaskStatusType.DONE:
        return translate("status.done");
      case TaskStatusType.ARCHIVE:
        return translate("status.archive");
    }
  };

  const _setTaskTypeValue = (type: string): any => {
    switch (type) {
      case TaskType.TYPE_1:
        return translate("type.type1");
      case TaskType.TYPE_2:
        return translate("type.type2");
    }
  };

  const _setTaskPriorityValue = (priority: string): any => {
    switch (priority) {
      case PriorityType.LOW:
        return translate("priority.low");
      case PriorityType.MEDIUM:
        return translate("priority.medium");
      case PriorityType.HIGH:
        return translate("priority.high");
    }
  };

  const _buildNamesList = (items: any[]): JSX.Element => {
    if (!items.length) return <></>;
    return (
      <Column>
        {items.map((item) => {
          return (
            <RunningText key={item._id}>
              {`${item.firstName} ${item.lastName}`}
            </RunningText>
          );
        })}
      </Column>
    );
  };

  const removeTask = (task: Task): void => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (task?._id == null) return;
        taskStore?.removeTask({ taskId: task._id });
      },
      confirmLabel: translate("deleteButton.confirmLabel"),
      title: translate("deleteButton.title"),
      description: translate("deleteButton.description"),
    });
  };

  return (
    <ComponentWrapper noPadding className="mt-5">
      <ListDataTable
        gap={20}
        data={tasks || []}
        noDataMessage={translate("list.noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: translate("list.listDropdownMenu.edit"),
              icon: faEdit,
              onClick: (task: Task) => {
                if (task?._id == null) return;
                taskStore?.setCurrentTask(task);

                modalStore?.openModal(
                  ModalType.TASK_MANAGEMENT_MODAL,
                  ModalSize.LARGE
                );
              },
            },
            {
              label: translate("list.listDropdownMenu.delete"),
              icon: faTrash,
              onClick: (task: Task) => removeTask(task),
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("list.title")}</TitleText>,
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>{translate("list.status")}</TitleText>
            ),
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("list.type")}</TitleText>,
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>
                {translate("list.responsibility")}
              </TitleText>
            ),
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>{translate("list.member")}</TitleText>
            ),
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>{translate("list.priority")}</TitleText>
            ),
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>{translate("list.dueDate")}</TitleText>
            ),
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: Task) => {
          return {
            key: dataItem._id,
            children: [
              {
                child: <RunningText>{dataItem?.title}</RunningText>,
              },
              {
                child: (
                  <RunningText>
                    {_setTaskStatusValue(dataItem.status)}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>{_setTaskTypeValue(dataItem?.type)}</RunningText>
                ),
              },
              {
                child: <>{_buildNamesList(dataItem?.responsibilities)}</>,
              },
              {
                child: <>{_buildNamesList(dataItem?.members)}</>,
              },
              {
                child: (
                  <HighlightedInfoText
                    color={
                      dataItem.priority === PriorityType.LOW
                        ? "success"
                        : dataItem.priority === PriorityType.MEDIUM
                        ? "warning"
                        : "error"
                    }
                  >
                    {_setTaskPriorityValue(dataItem.priority)}
                  </HighlightedInfoText>
                ),
              },
              {
                child: (
                  <RunningText>{formatDate(dataItem?.dueDate)}</RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject("taskStore", "modalStore")(observer(TaskList));
