import React from "react";
import "../task.management.modal.component.scss";
import { useTranslation } from "react-i18next";
import { formatDate, createTranslate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { Asset } from "schemas/asset.schemas/asset.schema";
import classNames from "classnames";
import { toast } from "react-toastify";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import ListDropdownMenu, {
  DropdownPlacements,
  ListDropdownItem,
} from "components/input.components/dropdown.components/list.dropdown.component/list.dropdown.component";
import AttachmentItem from "./task.attachment.item";

export interface ListDropdownMenuProps<T> {
  className?: string;
  items: Array<ListDropdownItem<T>>;
  placement?: DropdownPlacements;
}

interface CommentData {
  id: string | undefined;
  profileImageUrl: string | undefined;
  email: string | undefined;
  text: string | undefined;
  modifiedAt: Date | undefined;
  attachments?: Asset[] | undefined;
}

interface CommentCardProps<T> {
  data: CommentData;
  isDescription?: boolean;
  listDropdownMenu?: ListDropdownMenuProps<T>;
}

const CommentCard = <T extends unknown>({
  data,
  isDescription,
  listDropdownMenu: listDropdownProps,
}: CommentCardProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.modal");

  const { id, profileImageUrl, email, text, modifiedAt, attachments } = data;
  let content: string | object = "";

  if (text != null) {
    try {
      content = JSON.parse(text);
    } catch (err) {
      if (typeof text === "string") {
        content = text;
      } else {
        toast.error(translate("form.onParseError"));
      }
    }
  }

  const _buildAttachmentsContainer = (): JSX.Element => {
    return (
      <div className="comment-attachment-container">
        {attachments?.length &&
          attachments.map((item: Asset) => {
            return <AttachmentItem key={item._id} asset={item} />;
          })}
      </div>
    );
  };

  const commentCardContainerClassName = classNames({
    "comment-card-container": true,
    "comment-card-container--description": isDescription,
  });

  return (
    <Column className={commentCardContainerClassName}>
      <Row
        className="comment-card-container-header"
        justifyContent="space-around"
      >
        <Row alignItems="center" gap={10}>
          <Image
            className="ml-10 comment-card-container-image"
            imageUrl={profileImageUrl}
          />
          <SmallHeadline className="comment-card-container-email">
            {email}
          </SmallHeadline>
        </Row>
        <Row className="comment-card-container-icon mr-20" alignItems="center">
          {/* DROPDOWN MENU */}
          {listDropdownProps && (
            <div key={id} onClick={(e) => e.stopPropagation()}>
              <ListDropdownMenu
                dataItem={data as T}
                dropdownMenuButton={(props) => (
                  <FontAwesomeIcon {...props} icon={faEllipsisH} />
                )}
                {...listDropdownProps}
              />
            </div>
          )}
        </Row>
      </Row>
      <Column
        justifyContent="space-between"
        className="comment-card-container-data"
      >
        <TextEditor
          className="text-editor-container-plain-text"
          minHeight={20}
          content={content}
          inputContentChanged={(content: any) => {}}
          isPlainText={true}
        />
        <Row className="task-card-date">{formatDate(modifiedAt)}</Row>
        {!!attachments?.length && _buildAttachmentsContainer()}
      </Column>
    </Column>
  );
};

export default CommentCard;
