import React, { useState, useEffect, InputHTMLAttributes } from "react";
import classNames from "classnames";
import "./search.input.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/pro-regular-svg-icons";

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  searchPath: string;
  className?: string;
  onChange?: (value: any) => void;
}

const SearchInput = ({
  value,
  searchPath,
  className,
  onChange,
  ...props
}: SearchInputProps): JSX.Element => {
  const { placeholder } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const handleChange = (e: any): void => {
    if (onChange) {
      onChange(e.target.value);
    }

    setCurrentValue(e.target.value);
  };

  const handleClear = (): void => {
    if (currentValue && onChange) {
      onChange("");
    }

    setCurrentValue("");
  };

  const defaultSearchContainerClassName = classNames(
    {
      "default-search-input-container": true,
    },
    className
  );

  const searchInputFieldClassName = classNames({
    "default-search-input-field": true,
  });

  return (
    <div className={defaultSearchContainerClassName}>
      <input
        name="search"
        type="text"
        className={searchInputFieldClassName}
        value={currentValue}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <FontAwesomeIcon icon={faSearch} className="search-input-icon" />
      <FontAwesomeIcon
        icon={faClose}
        className="close-input-icon"
        onClick={handleClear}
      />
    </div>
  );
};

export default SearchInput;
