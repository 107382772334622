import { Task } from "schemas/task.schemas/task.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpTaskService extends GenericHttpClient<Task> {
  static _instance: HttpTaskService;
  static getInstance(): HttpTaskService {
    if (this._instance == null) {
      this._instance = new HttpTaskService("/tasks");
    }
    return this._instance;
  }
}
