import { dataAttributeCollectionSchema } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";
import { Studio } from "schemas/studio.schemas/studio.schema";
import { systemSchema } from "schemas/system.schema";
import * as yup from "yup";
import { dataAttributeOptionSchema } from "./data.attribute.options.schema";

const stringAttributeSettingSchema = yup.object({
  validation: yup.string().required().default(null).nullable(),
});

const uploadAttributeSettingSchema = yup.object({
  restrictions: yup.string().notRequired().default(null),
});

const objectAttributeSettingSchema = yup.object({
  referenceTo: dataAttributeCollectionSchema.required(),
  showInNewTab: yup.boolean().required().default(false),
});

const arraytAttributeSettingSchema = yup.object({
  referenceTo: dataAttributeCollectionSchema.required(),
  showInNewTab: yup.boolean().required().default(false),
});

const selectAttributeSettingSchema = yup.object({
  options: yup.array().of(dataAttributeOptionSchema).notRequired(),
});

export type DataAttributeType =
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "upload"
  | "select"
  | "object"
  | "array";

export const dataAttributeSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  dataAttributeType: yup
    .string()
    .required() as yup.StringSchema<DataAttributeType>,
  collection: dataAttributeCollectionSchema.required(),
  label: yup.string().required(),
  labelIcon: yup.string().notRequired(),
  placeholder: yup.string().notRequired(), // TODO
  fieldID: yup
    .string()
    .matches(/^[A-Za-z0-9_]+$/, {
      excludeEmptyString: true,
    })
    .required(),
  required: yup.boolean().required(),
  readOnly: yup.boolean().notRequired(),
  attributeSettings: yup.lazy((_, options) => {
    const type = options.context.dataAttributeType;

    if (type == null) {
      return yup.object().strip();
    }

    switch (type as DataAttributeType) {
      case "string":
        return stringAttributeSettingSchema;
      case "upload":
        return uploadAttributeSettingSchema;
      case "object":
        return objectAttributeSettingSchema;
      case "array":
        return arraytAttributeSettingSchema;
      case "select":
        return selectAttributeSettingSchema;
      default:
        return yup.object().strip();
    }
  }),

  // visibility: dataAttributeVisibilitySchema.required().default(undefined), // TODO

  system: systemSchema.notRequired().default(undefined),
});

export interface DataAttribute
  extends yup.InferType<typeof dataAttributeSchema> {
  isRuntime: boolean;
  path: string;
  studio: Studio;
}

export const attributeToJson = (dataAttribute: DataAttribute): any => {
  return {
    ...dataAttribute,
    collection: dataAttribute?.collection?._id,
    attributeSettings: {
      ...dataAttribute?.attributeSettings,
      referenceTo:
        (dataAttribute.attributeSettings as any)?.referenceTo?._id || null,
    },
  };
};
