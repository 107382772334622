import React, { useEffect } from "react";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import { inject, observer } from "mobx-react";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { Outlet, Route, Routes, useLocation } from "react-router";
import MarketplaceBrowsePage from "./components/marketplace.browse.page/marketplace.browse.page";
import MarketplaceAddOnPage from "./components/merketplace.add.on.page/merketplace.add.on.page";
import MarketplaceIntegrationPage from "./components/marketplace.integration.page/marketplace.integration.page";
import MarketplaceContentLibraryPage from "./components/marketplace.content.library.page/marketplace.content.library.page";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import ContentLibraryStore from "stores/content.library.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import MarketplaceInstalledAppsPage from "./components/marketplace.installed.apps.page/marketplace.installed.apps.page";
import {
  getAccessToken,
  getRefreshToken,
  getResource,
} from "globals/helpers/storage.helper";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface MarketPlacePageProps {
  contentLibraryStore?: ContentLibraryStore;
}

const MarketplaceOverviewPage = ({
  contentLibraryStore,
}: MarketPlacePageProps): JSX.Element => {
  const location = useLocation();

  // get the active marketplace tab from the url
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const marketplaceIndex = pathSegments.indexOf("marketplace");

  const activeTab = pathSegments[marketplaceIndex + 1];

  const { t } = useTranslation();
  const translate = createTranslate(t, `marketplacePage.overview.${activeTab}`);
  const transTab = createTranslate(t, `marketplacePage.overview`);

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    await contentLibraryStore?.fetchAndSetContentLibraries({});
  };

  const navigateToContentPortal = (): void => {
    const token = getAccessToken();
    const refreshToken = getRefreshToken();
    const resource = getResource();
    const contentPortalUrl = process.env.REACT_APP_GYMO_CONTENT_PORTAL_URL;

    // redirect to content portal
    window.location.href = `${contentPortalUrl}/?token=${token}&refreshToken=${refreshToken}&resource=${resource}`;
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        tabs={[
          <Tab
            label={transTab("browse.tabTitle")}
            key="marketplace-browse-tab"
            path="browse"
            rootPath="marketplace"
          />,
          <Tab
            label={transTab("addons.tabTitle")}
            key="marketplace-add-ons-tab"
            path="addons"
            rootPath="marketplace"
          />,
          <Tab
            label={transTab("integrations.tabTitle")}
            key="marketplace-integrations-tab"
            path="integrations"
            rootPath="marketplace"
          />,
          <Tab
            label={transTab("content.tabTitle")}
            key="marketplace-content-libraries-tab"
            path="content"
            rootPath="marketplace"
          />,
          <Tab
            label={transTab("installed.tabTitle")}
            key="marketplace-installed-apps-tab"
            path="installed"
            rootPath="marketplace"
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            imagePosition="center"
            title={translate("introTitle")}
            collapsedTitle={translate("collapsedIntroTitle")}
            description={translate("introDescription")}
            id="marketplace-overview-page"
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  navigateToContentPortal();
                }}
                className="mt-10"
                label="Create Content Libraries"
              />
            )}
          />

          {_buildTabs()}

          <Routes>
            <Route>
              {/* OVERVIEW PAGES */}
              <Route path="browse/*" element={<MarketplaceBrowsePage />} />
              <Route path="addons" element={<MarketplaceAddOnPage />} />
              <Route
                path="integrations"
                element={<MarketplaceIntegrationPage />}
              />
              <Route
                path="content"
                element={<MarketplaceContentLibraryPage />}
              />

              <Route
                path="installed"
                element={<MarketplaceInstalledAppsPage />}
              />
            </Route>
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject("contentLibraryStore")(observer(MarketplaceOverviewPage));
