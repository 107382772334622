import {
  LanguageCode,
  PropertyType,
  TrainingLevel,
  TaskListType,
  AssetType,
} from "globals/enums/global.enum";
import { LanguageDropdownOption } from "globals/intefaces/global.interface";

import widget1 from "assets/widget_templates/widget_1.png";
import widget2 from "assets/widget_templates/widget_2.png";
import widget3 from "assets/widget_templates/widget_3.png";
import widget4 from "assets/widget_templates/widget_4.png";
import widget5 from "assets/widget_templates/widget_5.png";
import widget6 from "assets/widget_templates/widget_6.png";
import widget7 from "assets/widget_templates/widget_7.png";

import aspectRatio11 from "assets/widget_templates/aspect_ratio_1_1.png";
import aspectRatio43 from "assets/widget_templates/aspect_ratio_4_3.png";
import aspectRatio169 from "assets/widget_templates/aspect_ratio_16_9.png";
import aspectRatio34 from "assets/widget_templates/aspect_ratio_3_4.png";

import verticalSwipe from "assets/widget_templates/vertical_swipe.png";
import horizontalSwipe from "assets/widget_templates/horizontal_swipe.png";
import gridSwipe from "assets/widget_templates/grid_swipe.png";

export const languageOptions: LanguageDropdownOption[] = [
  { label: "Deutsch", value: LanguageCode.DE },
  { label: "Englisch", value: LanguageCode.EN },
];

export const difficultyLevelOptions = [
  { label: "Beginner", value: TrainingLevel.BEGINNER },
  { label: "Anfänger", value: TrainingLevel.ROOKIE },
  { label: "Fortgeschritten", value: TrainingLevel.ADVANCED },
  { label: "Profi", value: TrainingLevel.EXPERT },
  { label: "Arnold", value: TrainingLevel.ARNOLD },
];

export const traningsPlanPublishedStatus = [
  { label: "Öffentlich (Für alle in der App verfügbar)", value: true },
  { label: "Vorlage (Nicht in der App sichtbar)", value: false },
];

export const feedPublishedStatus = [
  { label: "Öffentlich", value: true },
  { label: "Entwurf", value: false },
];

export const appConfigurationIsValid = [
  { label: "App Config Valid", value: true },
  { label: "App Config nicht OK", value: false },
];

export const propertyTypeOptions = [
  { label: "Muskelgruppe", value: PropertyType.MUSCLE_GROUP },
  { label: "Tag", value: PropertyType.TAG },
  { label: "Geräte Eigenschaft", value: PropertyType.DEVICE },
  {
    label: "Muskelgruppe (READABLE)",
    value: PropertyType.READABLE_MUSCLE_GROUP,
  },
];

export const dataAttributeTypeOptions = [
  { label: "String", value: "string" },
  { label: "Upload", value: "upload" },
  { label: "Number", value: "number" },
  { label: "Boolean", value: "boolean" },
  { label: "Date", value: "date" },
  {
    label: "Reference",
    value: "reference",
  },
  {
    label: "Object",
    value: "object",
  },
  {
    label: "Array",
    value: "array",
  },
];

export const validationPatternOptions = [
  {
    label: "Email",
    value: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
  },
  {
    label: "URL",
    value: "^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$",
  },
  { label: "Phone Number", value: "^\\+?[1-9]\\d{1,14}$" },
  { label: "Digits Only", value: "^\\d+$" },
  { label: "Alphanumeric", value: "^[a-zA-Z0-9]+$" },
  { label: "No Special Chars", value: "^[a-zA-Z0-9 ]+$" },
  { label: "Keine Validation", value: null },
];

export const dataAttributeUploadRestrictionsOptions = [
  { label: "Keine Einschränkung", value: "NONE" },
  { label: "Bild (JPEG, JPG, PNG)", value: "IMAGE" },
  { label: "PDF", value: "PDF" },
  { label: "Audio (MP3, WAV)", value: "AUDIO" },
  {
    label: "Video (MP4, MOV)",
    value: "VIDEO",
  },
];

export const widgetTemplates = [
  {
    id: "WIDGET_1",
    title: "Design 1",
    description: "Titel und Text mit Hintegrund",
    imageUrl: widget1,
  },
  {
    id: "WIDGET_2",
    title: "Design 2",
    description: "Titel unten offen",
    imageUrl: widget2,
  },
  {
    id: "WIDGET_3",
    title: "Design 3",
    description: "Titel oben offen",
    imageUrl: widget3,
  },
  {
    id: "WIDGET_4",
    title: "Design 4",
    description: "Titel und Text unter Bild",
    imageUrl: widget4,
  },
  {
    id: "WIDGET_5",
    title: "Design 5",
    description: "Titel und Text über Bild",
    imageUrl: widget5,
  },
  {
    id: "WIDGET_6",
    title: "Design 6",
    description: "Titel und Text rechts",
    imageUrl: widget6,
  },
  {
    id: "WIDGET_7",
    title: "Design 7",
    description: "Ohne Titel und Text",
    imageUrl: widget7,
  },
];

export const aspectRatioTemplates = [
  {
    id: "1:1",
    title: "1:1",
    description: "Quadratisch",
    imageUrl: aspectRatio11,
  },
  {
    id: "4:3",
    title: "4:3",
    description: "Standard",
    imageUrl: aspectRatio43,
  },
  {
    id: "16:9",
    title: "16:9",
    description: "Breitbild",
    imageUrl: aspectRatio169,
  },
  {
    id: "3:4",
    title: "3:4",
    description: "Portrait",
    imageUrl: aspectRatio34,
  },
];

export const swipeTemplates = [
  {
    id: "LISTVIEW",
    key: "listview_vertical",
    title: "Vertikal swipen",
    description: "Nach unten swipen",
    imageUrl: verticalSwipe,
    scrollDirection: "VERTICAL",
  },
  {
    id: "LISTVIEW",
    key: "listview_horizontal",
    title: "Horizontal swipen",
    description: "Nach rechts swipen",
    imageUrl: horizontalSwipe,
    scrollDirection: "HORIZONTAL",
  },
  {
    id: "GRIDVIEW",
    title: "Gitter",
    description: "Vertial swipe",
    imageUrl: gridSwipe,
    scrollDirection: "VERTICAL",
  },
];

export const taskListTypeOptions = [
  { label: "List", value: TaskListType.LIST },
  { label: "Board", value: TaskListType.BOARD },
];

export const fileFormatAttributes = {
  [AssetType.ALL]: {
    "image/gif": [".gif"],
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
    "image/webp": [".webp"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/pdf": [".pdf"],
    "application/rtf": [".rtf"],
    "text/plain": [".txt"],
    "text/csv": [".csv"],
    "audio/aac": [".aac"],
    "audio/mpeg": [".mp3"],
    "audio/ogg": [".oga", ".opus"],
    "audio/wav": [".wav"],
    "audio/webm": [".weba"],
    "video/x-msvideo": [".avi"],
    "video/mp4": [".mp4"],
    "video/mpeg": [".mpeg"],
    "video/ogg": [".ogv"],
    "video/webm": [".webm"],
  },
  [AssetType.IMAGE]: {
    "image/gif": [".gif"],
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/svg+xml": [".svg"],
    "image/webp": [".webp"],
  },
  [AssetType.DOC]: {
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/pdf": [".pdf"],
    "application/rtf": [".rtf"],
  },
  [AssetType.TEXT]: {
    "text/plain": [".txt"],
  },
  [AssetType.CSV]: {
    "text/csv": [".csv"],
  },
  [AssetType.AUDIO]: {
    "audio/aac": [".aac"],
    "audio/mpeg": [".mp3"],
    "audio/ogg": [".oga", ".opus"],
    "audio/wav": [".wav"],
    "audio/webm": [".weba"],
  },
  [AssetType.VIDEO]: {
    "video/x-msvideo": [".avi"],
    "video/mp4": [".mp4"],
    "video/mpeg": [".mpeg"],
    "video/ogg": [".ogv"],
    "video/webm": [".webm"],
  },
};
