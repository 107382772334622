import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import TrainingPlanStore from "stores/training.plan.store";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface TrainingPlanListProps {
  plans: TrainingPlan[];
  onClick?: (plan: TrainingPlan) => void;
  isLoading?: boolean;
  trainingPlanStore?: TrainingPlanStore;
  modalStore?: ModalStore;
}

const TrainingPlanList = ({
  plans,
  onClick,
  isLoading = false,
  modalStore,
  trainingPlanStore,
}: TrainingPlanListProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = navigationHelper();
  const translate = createTranslate(t, "trainingPlanPage.overview");

  const archivePlan = (plan: TrainingPlan): void => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (plan?._id == null) return;
        trainingPlanStore?.archivePlan(plan);
      },
      confirmLabel: translate("deleteButton.confirmLabel"),
      title: translate("deleteButton.title"),
      description: translate("deleteButton.description"),
    });
  };

  return (
    <ComponentWrapper noPadding>
      <ListDataTable
        gap={20}
        data={plans || []}
        noDataMessage={translate("noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: translate("listDropdownMenu.edit"),
              icon: faEdit,
              onClick: (plan: TrainingPlan) => {
                if (plan?._id == null) return;
                navigate(`training-plans/${plan?._id}/info`);
              },
            },
            {
              label: translate("listDropdownMenu.delete"),
              icon: faTrash,
              onClick: (plan: TrainingPlan) => {
                archivePlan(plan);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("title")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("subTitle")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("status")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("createdAt")}</TitleText>,
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: TrainingPlan) => {
          return {
            key: dataItem._id,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={dataItem.coverImageUrl}
                      size={ImageSizes.M}
                    />
                    <RunningText className="ml-20">
                      {dataItem.title}
                    </RunningText>
                  </Row>
                ),
              },
              {
                child: <RunningText>{dataItem.subTitle}</RunningText>,
              },
              {
                child: (
                  <HighlightedInfoText
                    color={dataItem?.isPublished ? "success" : "warning"}
                  >
                    {dataItem?.isPublished
                      ? translate("statusPublished")
                      : translate("statusDraft")}
                  </HighlightedInfoText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {formatDate(dataItem?.system?.createdAt)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject(
  "trainingPlanStore",
  "modalStore"
)(observer(TrainingPlanList));
