import React, { useEffect } from "react";
import "./configurator.tab.scss";
import AppPreviewComponent from "components/app.preview.component/app.preview.component";
import ColorInput from "components/input.components/color.input.component/color.input.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import SplitLayout from "layouts/split.layout/split.layout";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import { Upload } from "schemas/upload.schema";
import LocalizationConfiguration from "components/studio.components/localization.configuration.component/localization.configuration";
import { useTranslation } from "react-i18next";
import appStoreIllustration from "assets/illustrations/gymo_apps.png";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import {
  createTranslate,
  generatePlaceholderAvatarUrl,
} from "globals/helpers/global.helper";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface ConfiguratorTabProps {
  studioStore?: StudioStore;
}

const ConfiguratorTab = ({
  studioStore,
}: ConfiguratorTabProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "appConfiguratorPage.configuration");

  const editingStudio = studioStore?.editingStudio;
  const themeData = editingStudio?.studioProperties.appTheme.themeData;

  useEffect(() => {
    studioStore?.setEditingProject();
  }, []);

  const saveAppConfig = (): void => {
    studioStore?.saveEditingProject();
  };

  if (studioStore == null || editingStudio == null) {
    return <></>;
  }

  const _handleChangeColor = (value: string | null): void => {
    if (value == null) return;

    for (let i = 0; i < themeData.length; i++) {
      themeData[i].colorTheme.primary = value;
    }

    studioStore.setEditingStudio({ ...editingStudio });
  };

  const _buildStudioInformation = (): JSX.Element => {
    return (
      <FormWrapper className="mb-20" title="Design">
        <Row>
          <SizedContainer size={ContainerSizes.CUSTOM} customSize={60}>
            <ColorInput
              label={translate("colorInputLabel")}
              value={themeData[1].colorTheme.primary}
              onChange={(value) => {
                _handleChangeColor(value);
              }}
            />
          </SizedContainer>

          <OutlinedTextInput
            label={translate("studioInputLabel")}
            className="mb-30"
            value={editingStudio.name}
            onChange={(value) => {
              setProperty(editingStudio, "name", value);
              studioStore.setEditingStudio({ ...editingStudio });
            }}
          />
        </Row>
      </FormWrapper>
    );
  };

  const _buildAppStoreLogoUpload = (): JSX.Element => {
    return (
      <FormWrapper title="App Logo">
        <Row>
          <SizedContainer className="mr-30" size={ContainerSizes.S}>
            <FileUpload
              label={translate("logoLightInputLabel")}
              className="upload-wrap-item mt-15"
              aspectRatio={1 / 1}
              folder="logos"
              fileUrl={
                themeData.find((theme: any) => theme.themeMode === "LIGHT")
                  .logoUrl
              }
              onFilesUploaded={(files?: Upload[]) => {
                if (files != null && files.length > 0) {
                  const lightAppTheme = themeData.find(
                    (theme: any) => theme.themeMode === "LIGHT"
                  );

                  if (lightAppTheme == null) return;

                  lightAppTheme.logoUrl = files[0].path;
                  studioStore?.setEditingStudio({ ...editingStudio });
                }
              }}
            />
          </SizedContainer>

          <SizedContainer size={ContainerSizes.S}>
            <FileUpload
              label={translate("logoDarkInputLabel")}
              className="upload-wrap-item mt-15"
              aspectRatio={1 / 1}
              folder="logos"
              fileUrl={
                themeData.find((theme: any) => theme.themeMode === "DARK")
                  .logoUrl
              }
              onFilesUploaded={(files?: Upload[]) => {
                if (files != null && files.length > 0) {
                  const darkAppTheme = themeData.find(
                    (theme: any) => theme.themeMode === "DARK"
                  );

                  if (darkAppTheme == null) return;

                  darkAppTheme.logoUrl = files[0].path;
                  studioStore?.setEditingStudio({ ...editingStudio });
                }
              }}
            />
          </SizedContainer>
        </Row>

        <InfoBox className="mt-15">{translate("logoInfo")}</InfoBox>
      </FormWrapper>
    );
  };

  const _buildForm = (): JSX.Element => {
    return (
      <WidthLimiterWrapper maxSize="form">
        <PageIntroCard
          wrapperClassName="mb-20"
          disableMargin
          image={appStoreIllustration}
          imagePosition="center"
          title={translate("introTitle")}
          collapsedTitle={translate("collapsedIntroTitle")}
          description={translate("introDescription")}
          id="configurator-tab"
        />

        <form
          className="studio-setup-form mb-20"
          noValidate
          id="app-configuration-form"
        >
          <ComponentWrapper title={translate("formTitle")}>
            {_buildStudioInformation()}
            {_buildAppStoreLogoUpload()}
          </ComponentWrapper>
        </form>

        <LocalizationConfiguration />

        <FixedControllersToolbar
          disabled={false}
          isLoading={false}
          hasUnsavedChanges={true}
          title={editingStudio?.name ?? translate("pageTitle")}
          imageUrl={generatePlaceholderAvatarUrl(editingStudio?.name)}
          renderButtons={() => (
            <FilledButton
              label={t("formNavbar.save")}
              onClick={saveAppConfig}
            />
          )}
        />
      </WidthLimiterWrapper>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="app-configurator-preview-container">
        <AppPreviewComponent isSticky />
      </div>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildForm()}
      rightChild={_buildPreview()}
      leftGrow={2}
      rightGrow={1}
    />
  );
};

export default inject("studioStore")(observer(ConfiguratorTab));
