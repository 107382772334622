import React, { useEffect, useState } from "react";
import "../task.management.modal.component.scss";
import { UseFormReturn } from "react-hook-form";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import TaskStore from "stores/task.store";
import CustomerStore from "stores/customer.store";
import StudioStore from "stores/studio.store";
import DataAttributeCollectionStore from "stores/data.attribute.collection.store";
import DataAttributeStore from "stores/data.attribute.store";
import { ModalStore } from "stores/modal.store";
import { Task } from "schemas/task.schemas/task.schema";
import { User } from "schemas/user.schemas/user.schema";
import { Customer } from "schemas/customer.schemas/customer.schema";
import {
  TaskStatusType,
  PriorityType,
  TaskType,
} from "globals/enums/global.enum";
import {
  faArchive,
  faShareFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { get } from "lodash";

import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Datepicker from "components/date.components/date.picker.component/date.picker.component";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";

interface UserSelectOption {
  label: string;
  value: string;
}
interface TaskDetailFormProps {
  errors: any;
  onErrorClear: (name: string) => void;
  formMethods: UseFormReturn<Task, any, undefined>;
  taskStore?: TaskStore;
  customerStore?: CustomerStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  dataAttributeCollectionStore?: DataAttributeCollectionStore;
  dataAttributeStore?: DataAttributeStore;
}

const TaskDetailForm = ({
  errors,
  onErrorClear,
  formMethods,
  taskStore,
  customerStore,
  studioStore,
  modalStore,
  dataAttributeCollectionStore: collectionStore,
  dataAttributeStore,
}: TaskDetailFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.modal");

  const task = taskStore?.currentTask?.data;
  const dashboardUsers = studioStore?.teamMembers?.data;
  const customers = customerStore?.customers?.data;
  const taskCollectionID =
    collectionStore?.currentDataAttributeCollection?.data?._id;

  const options = new Map()
    .set(
      "user",
      dashboardUsers?.map((item: User) => {
        const { _id, firstName, lastName } = item;
        return { label: `${firstName} ${lastName}`, value: _id };
      })
    )
    .set(
      "customer",
      customers?.map((item: Customer) => {
        const { _id, firstName, lastName } = item;
        return { label: `${firstName} ${lastName}`, value: _id };
      })
    )
    .set("status", [
      { label: translate("status.backlog"), value: TaskStatusType.BACKLOG },
      { label: translate("status.toDo"), value: TaskStatusType.TO_DO },
      {
        label: translate("status.inProgress"),
        value: TaskStatusType.IN_PROGRESS,
      },
      { label: translate("status.done"), value: TaskStatusType.DONE },
    ])
    .set("type", [
      { label: translate("type.type1"), value: TaskType.TYPE_1 },
      { label: translate("type.type2"), value: TaskType.TYPE_2 },
    ])
    .set("priority", [
      { label: translate("priority.low"), value: PriorityType.LOW },
      { label: translate("priority.medium"), value: PriorityType.MEDIUM },
      { label: translate("priority.high"), value: PriorityType.HIGH },
    ]);

  const { setValue, register, getValues } = formMethods;

  const [mainAttributes, setMainAttributes] = useState([] as DataAttribute[]);
  const [customAttributes, setCustomAttributes] = useState(
    [] as DataAttribute[]
  );

  useEffect(() => {
    initializeDashboardUsers();
    initializeCustomers();
  }, []);

  useEffect(() => {
    if (taskCollectionID) {
      initializeAttributes(taskCollectionID);
    }
  }, [taskCollectionID]);

  const initializeAttributes = async (collectionID: string): Promise<void> => {
    await dataAttributeStore?.fetchAndSetAttributesForCollection({
      collectionID,
    });

    const attributes =
      dataAttributeStore?.getCollectionAttributes(collectionID);
    if (attributes?.data.length) {
      setMainAttributes(attributes.data.filter((item) => item.path === ""));
      setCustomAttributes(
        attributes.data.filter((item) => item.path === "formData")
      );
    }
  };

  const initializeDashboardUsers = async (): Promise<void> => {
    await studioStore?.getTeamMembers({});
  };

  const initializeCustomers = async (): Promise<void> => {
    await customerStore?.fetchAndSetCustomers({});
  };

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const registerInput = (attribute: DataAttribute, basePath = ""): any => {
    const fieldPath = buildFieldPath(attribute, basePath);
    return register(fieldPath as keyof typeof task);
  };

  const getValuesInput = (attribute: DataAttribute, basePath = ""): any => {
    const fieldPath = buildFieldPath(attribute, basePath);
    return getValues(fieldPath as keyof typeof task);
  };

  const buildFieldPath = (attribute: DataAttribute, basePath = ""): string => {
    let pathSegment = attribute.fieldID;

    if (attribute?.path != null && attribute.path !== "") {
      pathSegment = `${attribute.path}.${pathSegment}`;
    }

    if (basePath != null && basePath !== "") {
      return `${basePath}.${pathSegment}`;
    }

    return pathSegment;
  };

  const getFieldError = (
    attribute: DataAttribute,
    basePath = ""
  ): string | undefined => {
    const fieldPath = buildFieldPath(attribute, basePath);
    const error = get(errors, `${fieldPath}.message`) as string | undefined;
    return error;
  };

  const archiveTask = async (): Promise<void> => {
    if (task?._id) {
      await taskStore?.updateTask({
        taskId: task._id,
        data: {
          status: TaskStatusType.ARCHIVE,
        },
      });
    }
    modalStore?.closeModal();
  };

  const getSelectItems = (fieldID: string): any[] => {
    if (fieldID === "creator" || fieldID === "assignee") {
      return options.get("user") ?? [];
    } else if (fieldID === "responsibilities" || fieldID === "members") {
      return options.get("customer") ?? [];
    } else {
      return options.get(fieldID) ?? [];
    }
  };

  const setSelectedItem = (fieldID: string): any => {
    if (fieldID === "creator" || fieldID === "assignee") {
      return {
        label: `
          ${task?.[fieldID].firstName ?? ""} ${task?.[fieldID].lastName ?? ""}
        `,
        value: task?.[fieldID]._id ?? null,
      };
    } else if (fieldID === "responsibilities" || fieldID === "members") {
      return task?.[fieldID].map((item) => {
        return {
          label: `${item.firstName} ${item.lastName}`,
          value: item._id,
        };
      });
    } else if (task && fieldID in task) {
      return options.get(fieldID)?.find((item: any) => {
        return item.value === task?.[fieldID as keyof typeof task];
      });
    }
    return {
      label: null,
      value: null,
    };
  };

  // Render the main Task fields based on the dataAttributeType
  const renderMainTaskDetailField = (attribute: DataAttribute): JSX.Element => {
    const { fieldID, dataAttributeType, readOnly } = attribute;
    switch (dataAttributeType) {
      case "string": {
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">
              {translate(`form.${fieldID}`)}
            </TitleText>
            <SelectDropDown
              disabled={!!readOnly}
              className={
                errors[fieldID] ? "drop-down-select--error" : "drop-down-select"
              }
              items={getSelectItems(fieldID)}
              selectedItem={setSelectedItem(fieldID)}
              placeholder={attribute?.placeholder ?? ""}
              onChange={(item) => {
                if (item != null) {
                  setValueAndMarkDirty(fieldID, item.value);
                  onErrorClear(fieldID);
                }
              }}
            />
          </Row>
        );
      }
      case "date": {
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">
              {translate(`form.${fieldID}`)}
            </TitleText>
            <Datepicker
              disabled={!!readOnly}
              value={task?.[fieldID as keyof typeof task] as Date}
              dateFormat="dd.MM.YYYY"
              isShowIcon={true}
              isShowTimeSelect={false}
              onSelect={(item: Date | null): void => {
                if (item != null) {
                  setValueAndMarkDirty(fieldID, item);
                }
              }}
            />
          </Row>
        );
      }
      case "array": {
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">
              {translate(`form.${fieldID}`)}
            </TitleText>
            <SelectDropDown
              disabled={!!readOnly}
              isMulti={true}
              className={
                errors[fieldID] ? "drop-down-select--error" : "drop-down-select"
              }
              items={getSelectItems(fieldID)}
              selectedItem={setSelectedItem(fieldID)}
              placeholder=""
              onChange={(items: UserSelectOption[]) => {
                if (items != null) {
                  const values = items.map(
                    (item: UserSelectOption) => item.value
                  );
                  setValueAndMarkDirty(fieldID, values);
                }
              }}
            />
          </Row>
        );
      }
      default:
        return <div>Unsupported type {dataAttributeType}</div>;
    }
  };

  // Render the main Task fields based on the dataAttributeType
  const renderCustomTaskDetailField = (
    attribute: DataAttribute,
    basePath = ""
  ): JSX.Element => {
    const { fieldID, dataAttributeType, label, readOnly } = attribute;
    switch (dataAttributeType) {
      case "string":
      case "number": {
        const type =
          dataAttributeType === "string" ? "text" : dataAttributeType;
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">{label}</TitleText>
            <OutlinedTextInput
              key={fieldID}
              type={type}
              className={
                errors[fieldID] ? "text-input-field--error" : "text-input-field"
              }
              disabled={!!readOnly}
              inputRef={registerInput(attribute, basePath)}
              inputRefValue={getValuesInput(attribute, basePath)}
              validationMessage={getFieldError(attribute, basePath)}
            />
          </Row>
        );
      }
      case "date": {
        if (fieldID === "created" && !task?._id) {
          return <></>;
        }
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">{label}</TitleText>
            <Datepicker
              disabled={!!readOnly}
              value={
                fieldID === "created"
                  ? task?.system.createdAt
                  : (task?.[fieldID as keyof typeof task] as Date)
              }
              dateFormat="dd.MM.YYYY"
              isShowIcon={true}
              isShowTimeSelect={false}
              onSelect={(item: Date | null): void => {
                if (item != null) {
                  setValueAndMarkDirty(fieldID, item);
                }
              }}
            />
          </Row>
        );
      }
      case "boolean": {
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">{label}</TitleText>
            <FilledCheckbox
              key={fieldID}
              isDisabled={!!readOnly}
              {...registerInput(attribute, basePath)}
              label={label}
            />
          </Row>
        );
      }
      case "array": {
        return (
          <Row key={fieldID} justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">{label}</TitleText>
            <SelectDropDown
              disabled={!!readOnly}
              isMulti={true}
              className="drop-down-select"
              items={getSelectItems(fieldID)}
              selectedItem={setSelectedItem(fieldID)}
              placeholder=""
              onChange={(items: UserSelectOption[]) => {
                if (items != null) {
                  const values = items.map(
                    (item: UserSelectOption) => item.value
                  );
                  setValueAndMarkDirty(fieldID, values);
                }
              }}
            />
          </Row>
        );
      }
      default:
        return <div>Unsupported type {dataAttributeType}</div>;
    }
  };

  return (
    <Wrap className="task-detail-form-wrapper">
      <Column className="column-bottom-border" gap={5}>
        {mainAttributes?.map((attribute: any) =>
          renderMainTaskDetailField(attribute)
        )}
      </Column>
      <Column className="mt-10 column-bottom-border" gap={5}>
        {customAttributes?.map((attribute: any) =>
          renderCustomTaskDetailField(attribute)
        )}
      </Column>
      {task?._id != null && (
        <Column className="mt-10" gap={5}>
          <Row justifyContent="space-between" alignItems="center">
            <TitleText className="task-detail-name">
              {translate("form.actions")}
            </TitleText>
          </Row>
          <Row justifyContent="space-between" alignItems="center">
            <FilledButton
              label={translate("form.shareLink")}
              icon={faShareFromSquare}
              className="action-button"
              onClick={() => {}}
            />
            <FilledButton
              label={translate("form.archive")}
              icon={faArchive}
              className="action-button"
              color="secondary"
              onClick={archiveTask}
            />
          </Row>
        </Column>
      )}
    </Wrap>
  );
};

export default inject(
  "taskStore",
  "customerStore",
  "studioStore",
  "modalStore",
  "dataAttributeCollectionStore",
  "dataAttributeStore"
)(observer(TaskDetailForm));
