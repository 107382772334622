import React, { useState, useEffect, InputHTMLAttributes, useRef } from "react";
import "./outlined.date.input.component.scss";
import classNames from "classnames";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { formatDate } from "globals/helpers/global.helper";
import { useClickedOutside } from "globals/helpers/hook.helper";
import * as locale from "date-fns/locale";

export interface Range {
  startDate: string | undefined;
  endDate: string | undefined;
}

interface OutlinedDateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string | undefined;
  localization?: any;
  dateRange?: Range;
  disabledDates?: Date[];
  className?: string;
  calendarSize?: "large" | "normal";
  onChange?: (value: any) => void;
}

const DATE = "Date";
const START_DATE = "Start Date";
const END_DATE = "End Date";

const OutlinedDateInput = ({
  value,
  localization = locale.enUS,
  dateRange,
  disabledDates,
  className,
  calendarSize = "large",
  onChange,
  ...props
}: OutlinedDateInputProps): JSX.Element => {
  const { disabled, readOnly } = props;

  const calendarRef = useRef<HTMLDivElement | null>(null);

  // set date values for date input fields
  const [dateValue, setDateValue] = useState<string | undefined>(undefined);
  const [startDateValue, setStartDateValue] = useState<string | undefined>(
    undefined
  );
  const [endDateValue, setEndDateValue] = useState<string | undefined>(
    undefined
  );

  // set date values for the DateRange widget
  const [calendarStartDate, setCalendarStartDate] = useState<Date>(new Date());
  const [calendarEndDate, setCalendarEndDate] = useState<Date>(new Date());

  // open/close Calendar/DateRange widget
  const [isCalendar, setCalendar] = useState<boolean>(false);
  // set the focus on/off for End date field
  const [isFocusEndDate, setFocusEndDate] = useState<boolean>(false);

  const selectionRange = {
    startDate: calendarStartDate,
    endDate: calendarEndDate,
    key: "selection",
  };

  useClickedOutside(calendarRef, () => {
    if (!isCalendar) return;

    setCalendar(false);
  });

  useEffect(() => {
    initializeDateValues();
  }, [value, dateRange]);

  const initializeDateValues = (): void => {
    // if a single value set it as the start date
    if (value) setDateValue(value);

    // if the date range set start and end dates
    if (dateRange) {
      setStartDateValue(dateRange.startDate);
      setEndDateValue(dateRange.endDate);

      if (dateRange.startDate) {
        setCalendarStartDate(new Date(dateRange.startDate));
      }
      if (dateRange.endDate) {
        setCalendarEndDate(new Date(dateRange.endDate));
      }
    }
  };

  const handleSelectCalendarValue = (date: Date): void => {
    const formatedDate = formatDate(date, "YYYY-MM-DD");
    setDateValue(formatedDate);

    if (onChange) {
      onChange({
        type: "single",
        value: formatedDate,
      });
    }
  };

  const handleSelectCalendarDateRange = (ranges: any): void => {
    const {
      selection: { startDate, endDate },
    } = ranges;

    setCalendarStartDate(startDate);
    setCalendarEndDate(endDate);

    const formatedStartDate = formatDate(startDate, "YYYY-MM-DD");
    const formatedEndDate = formatDate(endDate, "YYYY-MM-DD");

    setStartDateValue(formatedStartDate);
    setEndDateValue(formatedEndDate);

    if (onChange) {
      onChange({
        type: "range",
        value: {
          startDate: formatedStartDate,
          endDate: formatedEndDate,
        },
      });
    }
  };

  const dateInputWrapperClassName = classNames(
    {
      "outlined-date-input-wrapper": true,
      "outlined-date-input-wrapper--single": !dateRange,
      "outlined-date-input-wrapper--range": dateRange,
      "outlined-date-input-wrapper--disabled": disabled,
    },
    className
  );

  const outlinedDateInputClassName = classNames({
    "outlined-date-input-container": true,
    "outlined-date-input-container--single": !dateRange,
    "outlined-date-input-container--range": dateRange,
  });

  const startDateFieldClassName = classNames({
    "default-input-field": true,
    "default-input-field--disabled": disabled,
    "default-input-field--readonly": readOnly,
    "default-input-field--start-date":
      (dateValue && !dateRange) ?? startDateValue,
    "default-input-field--start-date--disabled": disabled,
    "default-input-field--active": isCalendar && !isFocusEndDate,
  });

  const endDateFieldClassName = classNames({
    "default-input-field": true,
    "default-input-field--disabled": disabled,
    "default-input-field--readonly": readOnly,
    "default-input-field--end-date": endDateValue,
    "default-input-field--end-date--disabled": disabled,
    "default-input-field--active": isFocusEndDate,
  });

  const startDateLabelClassName = classNames({
    "default-input-label": true,
    "default-input-label--disabled": disabled,
    "default-input-label--readonly": readOnly,
    "default-input-label--on-top": (dateValue && !dateRange) ?? startDateValue,
  });

  const endDateLabelClassName = classNames({
    "default-input-label": true,
    "default-input-label--disabled": disabled,
    "default-input-label--readonly": readOnly,
    "default-input-label--on-top": endDateValue,
  });

  if (!dateRange) {
    return (
      <div ref={calendarRef} className={dateInputWrapperClassName}>
        <div className={outlinedDateInputClassName}>
          <input
            disabled={disabled}
            readOnly={readOnly}
            className={startDateFieldClassName}
            type="date"
            value={dateValue}
            onClick={(e) => {
              e.preventDefault();
              setCalendar(!isCalendar);
            }}
          />
          <label aria-required className={startDateLabelClassName}>
            {DATE}
          </label>
        </div>

        {isCalendar && !disabled && !readOnly && (
          <Calendar
            locale={locale[localization as keyof typeof locale]}
            date={dateValue ? new Date(dateValue) : new Date()}
            onChange={handleSelectCalendarValue}
            disabledDates={disabledDates}
          />
        )}
      </div>
    );
  } else {
    return (
      <div ref={calendarRef} className={dateInputWrapperClassName}>
        <div className={outlinedDateInputClassName}>
          <input
            disabled={disabled}
            readOnly={readOnly}
            id="start-date"
            className={startDateFieldClassName}
            type="date"
            value={startDateValue}
            onClick={(e) => {
              e.preventDefault();
              setCalendar(!isCalendar);
            }}
          />
          <label aria-required className={startDateLabelClassName}>
            {START_DATE}
          </label>
        </div>

        <div className={outlinedDateInputClassName}>
          <input
            disabled={disabled}
            readOnly={readOnly}
            id="end-date"
            className={endDateFieldClassName}
            type="date"
            value={endDateValue}
            onClick={(e) => {
              e.preventDefault();
              setCalendar(!isCalendar);
            }}
          />
          <label aria-required className={endDateLabelClassName}>
            {END_DATE}
          </label>
        </div>

        {isCalendar && !disabled && !readOnly && (
          <DateRange
            className={calendarSize === "large" ? "date-range-large-size" : ""}
            months={calendarSize === "large" ? 2 : 1}
            direction="horizontal"
            locale={locale[localization as keyof typeof locale]}
            ranges={[selectionRange]}
            disabledDates={disabledDates}
            onChange={handleSelectCalendarDateRange}
            onRangeFocusChange={() => setFocusEndDate(!isFocusEndDate)}
          />
        )}
      </div>
    );
  }
};

export default OutlinedDateInput;
