import { makeAutoObservable } from "mobx";
import { Studio } from "schemas/studio.schemas/studio.schema";
import { toast } from "react-toastify";
import { HttpStudioService } from "services/httpClients/http.studio.client";
import {
  getLastVisitedStudio,
  setLastVisitedStudio,
  setResource,
} from "globals/helpers/storage.helper";
import { TeamMember } from "schemas/user.schemas/user.schema";
import { HttpUserService } from "services/httpClients/http.user.client";
import { TeamRole } from "schemas/team.role.schema";
import { HttpRoleService } from "services/httpClients/http.role.client";
import { PaginationDataList } from "globals/intefaces/pageination.data.list.interface";
import { Invitation } from "schemas/invitation.schema";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import { LanguageDropdownOption } from "globals/intefaces/global.interface";
import { languageOptions } from "globals/data/globals.data";
import { Logging } from "globals/helpers/logging.helper";
import Gleap from "gleap";
import RootStore from "./root.store";

class StudioStore {
  private stores: RootStore;

  // Studio Properties
  private _studio?: Studio;
  private _studios: Studio[] = [];
  private _editingStudio?: Studio;
  private _currentLanguage: LanguageDropdownOption = languageOptions[0];

  showIntro: boolean = false;

  private _teamMembersDataList: PaginationDataList<TeamMember> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _teamMemberRolesDataList: PaginationDataList<TeamRole> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _openTeamMemberInvitations: PaginationDataList<Invitation> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setStudio = (studio: Studio): any => {
    this._studio = studio;
    this._editingStudio = studio;
    setLastVisitedStudio(studio._id);
    setResource(studio._id);
    Gleap.setTags([`${studio.name ?? "undefined Studio"}`]);
  };

  setEditingProject = (): any => {
    this._editingStudio = this._studio;
  };

  setEditingStudio = (studio: Studio): any => {
    this._editingStudio = studio;
  };

  setStudios = (studios: Studio[]): any => {
    this._studios = studios;
  };

  setTeamMemberRoles = (teamMemberRoles: TeamRole[]): any => {
    this._teamMemberRolesDataList.data = teamMemberRoles;
  };

  setOpenTeamMemberInvitations = (invitations: Invitation[]): any => {
    this._openTeamMemberInvitations.data = invitations;
  };

  setTeamMembers = (teamMembers: TeamMember[]): any => {
    this._teamMembersDataList.data = teamMembers;
  };

  setCurrentLanguage = (language: LanguageDropdownOption): any => {
    this._currentLanguage = language;
  };

  //! Getters
  get studio(): Studio | undefined {
    if (this._studio == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._studio));
  }

  get editingStudio(): Studio | undefined {
    if (this._editingStudio == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._editingStudio));
  }

  get teamMembers(): PaginationDataList<TeamMember> | undefined {
    if (this._teamMembersDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._teamMembersDataList));
  }

  get teamMemberRoles(): PaginationDataList<TeamRole> | undefined {
    return JSON.parse(JSON.stringify(this._teamMemberRolesDataList));
  }

  get openTeamMemberInvitations(): PaginationDataList<Invitation> | undefined {
    return JSON.parse(JSON.stringify(this._openTeamMemberInvitations));
  }

  get currentLanguage(): LanguageDropdownOption {
    return this._currentLanguage;
  }

  //! Methods
  updateCurrentStudio = async (data: Studio): Promise<Studio | undefined> => {
    try {
      const updatedStudio =
        await HttpStudioService.getInstance().updateCurrentStudio(data);

      if (updatedStudio != null) {
        this.setStudio(updatedStudio);
        toast.success("Studio erfolgreich aktualisiert");
        return this._studio;
      }

      toast.error("Studio aktualisieren fehlgeschlagen");
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "updateCurrentStudio",
        message: "Studio aktualisieren fehlgeschlagen",
        exception: err,
        showAlert: true,
      });
    }
  };

  createStudio = async (): Promise<Studio | undefined> => {
    try {
      const initialStudio =
        await HttpStudioService.getInstance().createInitialStudio();

      if (initialStudio == null) {
        return;
      }

      this.setStudio(initialStudio);
      this.setEditingStudio(initialStudio);

      return initialStudio;
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "createStudio",
        message: "Studio konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  getUserStudios = async (): Promise<Studio | undefined> => {
    try {
      const studios = await HttpStudioService.getInstance().find({});

      if (studios == null || studios.length === 0) {
        return;
      }

      const lastVisitedStudioId = getLastVisitedStudio();
      const lastVisitedStudio = studios.find(
        (s: any) => s._id === lastVisitedStudioId
      );

      if (lastVisitedStudio != null) {
        this.setStudio(lastVisitedStudio);
      } else {
        this.setStudio(studios[0]);
      }

      return this._studio;
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "getUserStudios",
        message: "Studios konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetStudioVerifyCode = async (): Promise<void> => {
    try {
      const verifyCode = await HttpStudioService.getInstance().getVerifyCode();

      if (this.studio != null && verifyCode != null) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const studioWithVerifyCode = {
          ...this.studio,
          verificationCode: verifyCode as string,
        } as Studio;

        // set verify code to studio
        this.setStudio(studioWithVerifyCode);
      }
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "fetchAndSetStudioVerifyCode",
        message: "Verify code konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  regenerateAndSetStudioVerifyCode = async (): Promise<void> => {
    try {
      const verifyCode =
        await HttpStudioService.getInstance().regenerateVerifyCode();

      if (this.studio != null && verifyCode != null) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const studioWithVerifyCode = {
          ...this.studio,
          verificationCode: verifyCode as string,
        } as Studio;

        // set verify code to studio
        this.setStudio(studioWithVerifyCode);
      }
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "regenerateAndSetStudioVerifyCode",
        message: "Verify code konnten nicht neu generiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  saveEditingProject = async (): Promise<void> => {
    try {
      this._studio = this._editingStudio;

      if (this._editingStudio == null) {
        return;
      }

      await HttpStudioService.getInstance().updateCurrentStudio(
        this._editingStudio
      );

      toast.success("Änderungen erfolgreich gespeichert");
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "saveEditingProject",
        message: "Änderungen konnten nicht gespeichert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  // Studio App Users
  getTeamMembers = async (args: { refresh?: boolean }): Promise<void> => {
    try {
      if (
        this._teamMembersDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      this._teamMemberRolesDataList.isLoading = true;

      const teamMembers = await HttpUserService.getInstance().getTeamMembers();

      if (teamMembers == null) {
        this._teamMembersDataList.isLoading = false;
        return;
      }

      this.setTeamMembers(teamMembers);
    } catch (err) {
      this._teamMembersDataList.isLoading = false;
      Logging.error({
        className: "StudioStore",
        methodName: "getTeamMembers",
        message: "Studio App Users konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  getTeamMemberRoles = async (args: { refresh?: boolean }): Promise<void> => {
    try {
      if (
        this._teamMemberRolesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      this._teamMemberRolesDataList.isLoading = true;

      const teamMemberRoles = await HttpRoleService.getInstance().find({});

      if (teamMemberRoles == null) {
        this._teamMemberRolesDataList.isLoading = false;
        return;
      }

      this.setTeamMemberRoles(teamMemberRoles);
      this._teamMemberRolesDataList.isLoading = false;
    } catch (err) {
      this._teamMemberRolesDataList.isLoading = false;
      Logging.error({
        className: "StudioStore",
        methodName: "getTeamMemberRoles",
        message: "Team Member Roles konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  getOpenTeamMemberInvitations = async (args: {
    refresh?: boolean;
  }): Promise<void> => {
    try {
      if (
        this._openTeamMemberInvitations.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      this._openTeamMemberInvitations.isLoading = true;

      const invitations = await HttpInvitationService.getInstance().find({});

      if (invitations == null) {
        this._openTeamMemberInvitations.isLoading = false;
        return;
      }

      this.setOpenTeamMemberInvitations(invitations);
      this._openTeamMemberInvitations.isLoading = false;
    } catch (err) {
      this._openTeamMemberInvitations.isLoading = false;
      Logging.error({
        className: "StudioStore",
        methodName: "getOpenTeamMemberInvitations",
        message: "Team Member Invitations konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  publishApp = async (): Promise<void> => {
    try {
      await HttpStudioService.getInstance().publishBrandedApp();
      this.getUserStudios();
      toast.success("App erfolgreich veröffentlicht");
    } catch (err) {
      Logging.error({
        className: "StudioStore",
        methodName: "publishApp",
        message: "App konnte nicht published werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default StudioStore;
