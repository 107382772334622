import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import trash from "assets/icons/trash.svg";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import Grid from "components/general.compoenents/grid.component/grid.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import OutlinedDateInput from "components/input.components/outlined.date.input.component/outlined.date.input.component";
import NewSelectDropDown, {
  MultiSelectionType,
  SelectOptionType,
} from "components/input.components/dropdown.components/select.dropdown.component/new.dropdown.component";
import UnitInput, {
  UNIT_TYPE,
} from "components/input.components/unit.input.component/unit.input.component";
import TextAreaComponent from "components/input.components/text.area.component/text.area.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

export const testFormSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().notRequired(),
  age: yup.number().integer().min(1).notRequired(),
  email: yup.string().email().required("Email is required"),
  address: yup.string().notRequired(),
  description: yup.string().required("Description is required"),
  date: yup.object().notRequired(),
  info: yup.string().notRequired(),
});

const ExampleComponentPage = (): JSX.Element => {
  const testForm = useForm({
    resolver: yupResolver(testFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      age: "",
      email: "",
      address: "",
      description: "",
      date: "",
      sex: "",
      info: "",
    },
  });

  const {
    register,

    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = testForm;

  const sexOptions = [
    {
      label: "Weiblich",
      value: "FEMALE",
      image:
        "https://storage.gymo.io/training-plans/b834be1130971b6fece0dbc57b78a75aaff0c9687019fd3fb79aa628521f4421.png",
      icon: trash,
      metaData: "info",
    },
    {
      label: "Männlich",
      value: "MALE",
      image:
        "https://storage.gymo.io/training-plans/3ead47e3f8b4c65cdc1c8e29d58b3d607db2423c8d85ea0d554697350289902d.png",
      icon: trash,
      metaData: "info",
    },
    {
      label: "Divers",
      value: "OTHER",
      image:
        "https://storage.gymo.io/training-plans/d5519ec224a15f8cb06896dc077d8061637df91835f710ba957962e14080f5b3.png",
      icon: trash,
      metaData: "info",
    },
  ];

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleInputChange = (value: any): void => {
    console.log("Text input value:", value);
  };

  const handleDateChange = (dateData: {
    type: "single" | "range";
    value: string | Range;
  }): void => {
    console.log("Date value:", dateData);
  };

  const handleChangeSexOption = (value: any): void => {
    console.log("handleChangeSexOption value:", value);
    setValueAndMarkDirty("sex", value);
    clearErrors("sex");
  };

  // Extracted method for the text input section
  const _buildTextInputSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Text Input Components" className="mb-15">
        <FormWrapper>
          <Column className="mb-20">
            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Required Text Input
                </RunningText>
                <OutlinedTextInput
                  required
                  label="First Name"
                  inputRef={register("firstName")}
                  inputRefValue={getValues("firstName")}
                  validationMessage={errors.firstName?.message?.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Optional Text Input
                </RunningText>
                <OutlinedTextInput
                  label="Last Name"
                  placeholder="Enter your last name"
                  inputRef={register("lastName")}
                  inputRefValue={getValues("lastName")}
                  validationMessage={errors.lastName?.message?.toString()}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">Number Input</RunningText>
                <OutlinedTextInput
                  type="number"
                  label="Age"
                  placeholder="Enter your age"
                  inputRef={register("age")}
                  inputRefValue={getValues("age")}
                  validationMessage={errors.age?.message?.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Email Input with Error
                </RunningText>
                <OutlinedTextInput
                  required
                  label="Email"
                  inputRef={register("email")}
                  inputRefValue="invalid-email"
                  validationMessage="Invalid email format"
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">Disabled Input</RunningText>
                <OutlinedTextInput
                  disabled
                  label="Address"
                  value="Home address"
                  inputRef={register("address")}
                  inputRefValue={getValues("address")}
                  validationMessage={errors.address?.message?.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Prefilled Input
                </RunningText>
                <OutlinedTextInput
                  label="Prefilled Field"
                  inputRef={register("info")}
                  inputRefValue="Prefilled Value"
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
          </Column>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  // Extracted method for the text area section
  const _buildTextAreaSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Text Area Components" className="mb-15">
        <FormWrapper>
          <Column className="mb-20">
            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Required Text Area
                </RunningText>
                <TextAreaComponent
                  required
                  label="Description"
                  placeholder="Enter a description"
                  inputRef={register("description")}
                  inputRefValue={getValues("description")}
                  validationMessage={errors.description?.message?.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Text Area with Placeholder
                </RunningText>
                <TextAreaComponent
                  label="Additional Info"
                  placeholder="Enter additional information"
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Disabled Text Area
                </RunningText>
                <TextAreaComponent
                  disabled
                  label="Notes"
                  placeholder="Cannot edit this field"
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Text Area with Error
                </RunningText>
                <TextAreaComponent
                  label="Info"
                  inputRef={register("info")}
                  validationMessage="Comments cannot be empty"
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
          </Column>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  // Extracted method for the datepicker section
  const _buildDatepickerSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Datepicker Components" className="mb-15">
        <FormWrapper>
          <Column className="mb-20">
            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Single Date Picker
                </RunningText>
                <OutlinedDateInput
                  value="2024-10-10"
                  localization="en"
                  disabledDates={[
                    new Date(),
                    new Date("2024-10-15"),
                    new Date("2024-10-20"),
                  ]}
                  onChange={handleDateChange}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Date Range Picker
                </RunningText>
                <OutlinedDateInput
                  calendarSize="normal"
                  localization="en"
                  dateRange={{
                    startDate: undefined,
                    endDate: undefined,
                  }}
                  onChange={handleDateChange}
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div style={{ width: "50%" }}>
                <RunningText className="mb-15 bold">
                  Disabled Date Picker
                </RunningText>
                <OutlinedDateInput
                  value="2024-10-10"
                  disabled
                  onChange={handleDateChange}
                />
              </div>
              <div style={{ width: "50%" }}>
                <RunningText className="mb-15 bold">
                  Date Picker with Error
                </RunningText>
                <OutlinedDateInput
                  value=""
                  // validationMessage="Please select a date"
                  onChange={handleDateChange}
                />
              </div>
            </Grid>
          </Column>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  // Extracted method for the unit input section
  const _buildUnitInputSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Unit Input Components" className="mb-15">
        <FormWrapper>
          <Column>
            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">Length Input</RunningText>
                <UnitInput
                  value={100}
                  unitValue="m"
                  required
                  label="Length"
                  unitType={UNIT_TYPE.LENGTH}
                  initialValue={60}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <RunningText className="mb-15 bold">Weight Input</RunningText>
                <UnitInput
                  value={undefined}
                  required
                  label="Weight"
                  unitType={UNIT_TYPE.WEIGHT}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div style={{ width: "50%" }}>
                <RunningText className="mb-15 bold">
                  Disabled Unit Input
                </RunningText>
                <UnitInput
                  value={undefined}
                  disabled
                  label="Weight"
                  unitType={UNIT_TYPE.WEIGHT}
                />
              </div>

              <div style={{ width: "50%" }}>
                <RunningText className="mb-15 bold">
                  Unit Input with Error
                </RunningText>
                <UnitInput
                  value={undefined}
                  required
                  label="Weight"
                  unitType={UNIT_TYPE.WEIGHT}
                  onChange={handleInputChange}
                  validationMessage="This field is required"
                />
              </div>
            </Grid>
          </Column>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  // Extracted method for the dropdown section
  const _buildDropdownSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Select Dropdown Components" className="mb-15">
        <FormWrapper>
          <Column className="mb-20">
            <Grid templateColumns="repeat(2, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Single Select Dropdown
                </RunningText>
                <NewSelectDropDown
                  required
                  label="Sex"
                  options={sexOptions}
                  inputRef={register("sex")}
                  selectedItem={sexOptions.find(
                    (option) => option.value === getValues("sex")
                  )}
                  onChange={handleChangeSexOption}
                  selectOptionType={SelectOptionType.DEFAULT}
                  isSelectedOptionsFirst={true}
                />
              </div>
              <div>
                <RunningText className="mb-15 bold">
                  Disabled Dropdown
                </RunningText>
                <NewSelectDropDown
                  label="Disabled Dropdown"
                  options={sexOptions}
                  disabled
                />
              </div>
            </Grid>

            <Grid templateColumns="repeat(3, 1fr)" gap={15}>
              <div>
                <RunningText className="mb-15 bold">
                  Multi Select (Count) - Images
                </RunningText>
                <NewSelectDropDown
                  label="Select Options"
                  options={sexOptions}
                  multiple
                  multiSelectionType={MultiSelectionType.COUNT}
                  selectOptionType={SelectOptionType.IMAGE}
                  onChange={(value) => setValueAndMarkDirty("sex", value)}
                />
              </div>

              <div>
                <RunningText className="mb-15 bold">
                  Multi Select (Wrap) - Icons
                </RunningText>
                <NewSelectDropDown
                  label="Select Options"
                  options={sexOptions}
                  multiple
                  multiSelectionType={MultiSelectionType.WRAP}
                  selectOptionType={SelectOptionType.ICON}
                  onChange={(value) => setValueAndMarkDirty("sex", value)}
                />
              </div>

              <div>
                <RunningText className="mb-15 bold">
                  Multi Select (Image) - Metadata
                </RunningText>
                <NewSelectDropDown
                  label="Select Options"
                  options={sexOptions}
                  multiple
                  multiSelectionType={MultiSelectionType.IMAGE}
                  selectOptionType={SelectOptionType.META_DATA}
                  onChange={(value) => setValueAndMarkDirty("sex", value)}
                />
              </div>
            </Grid>

            <div>
              <RunningText className="mt-20 mb-15 bold">
                Newest First with Images
              </RunningText>
              <NewSelectDropDown
                label="Select Options"
                options={sexOptions}
                isSelectedOptionsFirst={false}
                selectOptionType={SelectOptionType.IMAGE}
                onChange={handleChangeSexOption}
              />
            </div>
          </Column>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper maxSize="form">
          <form id="component-test-form">
            {_buildTextInputSection()}

            {_buildTextAreaSection()}

            {_buildDatepickerSection()}

            {_buildDropdownSection()}

            {_buildUnitInputSection()}
          </form>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default ExampleComponentPage;
