import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Column from "components/general.compoenents/column.component/column.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { saveAccessToken } from "globals/helpers/storage.helper";
import { getEmailValidationPattern } from "globals/helpers/validation.helper";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import { Logging } from "globals/helpers/logging.helper";
import { HttpUserService } from "services/httpClients/http.user.client";
import "./password.reset.page.scss";
import Row from "components/general.compoenents/row.component/row.component";
import { useTranslation } from "react-i18next";

const PasswordResetPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  const senderName = params.get("senderName");

  // set token from params to state
  useEffect(() => {
    const token = params.get("token");

    if (token) {
      saveAccessToken(token);
    }
  }, []);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleResetPassword = async (data: any): Promise<void> => {
    try {
      const response = await HttpUserService.getInstance().handleResetPassword({
        email: data.email,
        newPassword: data.newPassword,
      });

      if (!response) {
        navigate("/sign-in");
        toast.error(t("passwordResetPage.toastResponseError"));
        return;
      }

      navigate("/sign-in");
      toast.success(t("passwordResetPage.toastSuccess"));
    } catch (err) {
      Logging.error({
        className: "PasswordResetPage",
        methodName: "handleResetPassword",
        message: t("passwordResetPage.toastResponseError") ?? "",
        exception: err,
        showAlert: true,
      });
    }
  };

  const _buildPasswordResetContent = (): JSX.Element => {
    return (
      <div className="password-reset-form-container">
        <div className="password-reset-form-wrapper">
          <form
            className="password-reset-form"
            onSubmit={handleSubmit(handleResetPassword, (errors) => {
              toast.error(t("passwordResetPage.toastError"));
            })}
            noValidate
          >
            <SizedContainer size={ContainerSizes.L}>
              <Column alignItems="flex-start">
                <Headline className="mb-15">
                  {t("passwordResetPage.headline", {
                    senderName,
                  })}
                </Headline>
                <RunningText className="mb-20">
                  {t("passwordResetPage.instructionText")}
                </RunningText>
                <OutlinedTextInput
                  label="Email"
                  type="email"
                  inputRef={register("email", {
                    required: "Email is required",
                    pattern: {
                      value: getEmailValidationPattern(),
                      message: "Please enter a valid email",
                    },
                  })}
                  inputRefValue={getValues("email")}
                  validationMessage={errors.email?.message?.toString()}
                />
                <OutlinedTextInput
                  label={t("passwordResetPage.newPassword") ?? ""}
                  type="password"
                  inputRef={register("newPassword", {
                    required: "Password is required",
                  })}
                  inputRefValue={getValues("newPassword")}
                  validationMessage={errors.newPassword?.message?.toString()}
                />
                <FilledButton
                  label={t("passwordResetPage.resetButton") ?? ""}
                  className="mt-15 full-width"
                  type="submit"
                />
              </Column>
            </SizedContainer>
          </form>
        </div>
      </div>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="password-reset-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="password-reset-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="password-reset-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="password-reset-page">
      <div className="password-reset-page-wrapper">
        {_buildPreview()}
        <div className="password-reset-form-wrapper">
          {_buildPasswordResetContent()}
        </div>
      </div>
    </Row>
  );
};

export default PasswordResetPage;
