import React, { useEffect, useState } from "react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import { UseFormReturn } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import TrainingPlanStore from "stores/training.plan.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import {
  difficultyLevelOptions,
  traningsPlanPublishedStatus,
} from "globals/data/globals.data";
import { toast } from "react-toastify";
import * as yup from "yup";
import { propertySchema } from "schemas/property.schemas/property.schema";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";
import { useTranslation } from "react-i18next";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import Grid from "components/general.compoenents/grid.component/grid.component";
import Column from "components/general.compoenents/column.component/column.component";
import { createTranslate } from "globals/helpers/global.helper";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { ModalStore, ModalType, ModalSize } from "stores/modal.store";
import "./training.plan.form.component.scss";
import classNames from "classnames";
import UploadContainer from "components/general.compoenents/upload.container.component/upload.container.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import TextAreaComponent from "components/input.components/text.area.component/text.area.component";

export const trainingPlanInfoFormSchema = yup.object().shape({
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  description: yup.string(),
  coverImageUrl: yup.string().required("Bild ist verpflichtend"),
  rhythm: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  properties: yup.array().of(propertySchema([])).default(undefined),
  niveau: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .default(null),
  duration: yup
    .number()
    .integer()
    .min(1, "Dauer muss mindestens 1 sein")
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  isPublished: yup.boolean().required("Verfügbarkeit ist verpflichtend"),
  coverColor: yup.string().notRequired(),
});

interface TrainingPlanFormProps {
  formMethods: UseFormReturn<TrainingPlan, any, undefined>;
  trainingPlanStore?: TrainingPlanStore;
  isEditing: boolean;
  modalStore?: ModalStore;
}

const TrainingPlanForm = ({
  formMethods,
  trainingPlanStore,
  isEditing,
  modalStore,
}: TrainingPlanFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "trainingPlanForm");
  const navigate = navigationHelper();

  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
    clearErrors,
  } = formMethods;

  const [selectedFileUrl, setSelectedFileUrl] = useState(
    trainingPlan?.coverImageUrl
  );

  useEffect(() => {
    localStorage.setItem("shouldNavigateToExercises", "false");
  }, [isDirty]);

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  // handle training plan form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (trainingPlan?._id) {
      await trainingPlanStore?.updateTrainingPlan({
        id: trainingPlan._id!,
        trainingPlan: data,
      });
    } else {
      const createdTrainingPlan = await trainingPlanStore?.createTrainingPlan({
        trainingPlan: data,
      });

      if (createdTrainingPlan?._id == null) return;

      navigate(`training-plans/${createdTrainingPlan._id}/info`);
    }
  };

  const _buildTitleAndDescription = (): JSX.Element => {
    return (
      <Column>
        <Grid templateColumns="repeat(2, 1fr)" gap={15}>
          <OutlinedTextInput
            required={true}
            label={translate("planTitle")}
            inputRef={register("title")}
            inputRefValue={getValues("title")}
            validationMessage={errors.title?.message?.toString()}
          />
          <OutlinedTextInput
            label={translate("planSubtitle")}
            placeholder="Subtitle name"
            inputRef={register("subTitle")}
            inputRefValue={getValues("subTitle")}
            validationMessage={errors.subTitle?.message?.toString()}
          />
        </Grid>

        <TextAreaComponent
          className="mt-10"
          label={translate("planDescription")}
          inputRef={register("description")}
          inputRefValue={getValues("description")}
          validationMessage={errors.description?.message?.toString()}
        />
      </Column>
    );
  };

  const _buildRepresentationInfo = (): JSX.Element => {
    const imageUploadContainerClassName = classNames({
      "image-upload-container": true,
      "image-upload-container--error": errors.coverImageUrl?.message,
    });

    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={15}>
        <Column>
          <SizedContainer size={ContainerSizes.M}>
            <UploadContainer
              isDefaultFileUploader={false}
              label={translate("coverImage") ?? "Titelbild"}
              className={imageUploadContainerClassName}
              selectedFileUrl={selectedFileUrl}
              fileUrl={getProperty(trainingPlan, "coverImageUrl")}
              folder="training-plans"
              inputRef={register("coverImageUrl")}
              errors={errors}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValueAndMarkDirty("coverImageUrl", files[0].path);
                  clearErrors("coverImageUrl");
                }
              }}
              onLibraryChoosed={(data) => {
                setValueAndMarkDirty("coverImageUrl", data.url);
                clearErrors("coverImageUrl");
              }}
              onAssetContainerClick={() => {
                modalStore?.openModal(
                  ModalType.ASSET_SELECTION_MODAL,
                  ModalSize.MEDIUM,
                  {
                    assetSelectionLimit: null,
                    folder: "training-plan",
                    onSave: (data: any) => {
                      setSelectedFileUrl(data?.url || data?.path);
                      setValueAndMarkDirty("coverImageUrl", data.url);
                      clearErrors("coverImageUrl");
                    },
                  }
                );
              }}
            />
          </SizedContainer>
          <ColorPicker
            className="mt-10"
            name="color"
            label={translate("planColor")}
            inputRef={register("coverColor")}
            defaultValue={getValues("coverColor") ?? "#313634e0"}
            validationMessage={errors.coverColor?.message?.toString()}
            onChange={(color: any) => {
              setValueAndMarkDirty("coverColor", color);
            }}
          />
        </Column>
        <Column>
          <SelectDropDown
            label="Verfügbarkeit"
            className="mb-10"
            items={traningsPlanPublishedStatus ?? []}
            inputRef={register("isPublished")}
            validationMessage={errors.isPublished?.message?.toString()}
            selectedItem={traningsPlanPublishedStatus.find(
              (item) => item.value === trainingPlan?.isPublished
            )}
            onChange={(item) => {
              setValueAndMarkDirty("isPublished", item?.value);
              clearErrors("isPublished");
            }}
          />
          <SelectDropDown
            label={translate("planNiveau")}
            items={difficultyLevelOptions ?? []}
            inputRef={register("niveau")}
            validationMessage={errors.niveau?.message?.toString()}
            selectedItem={difficultyLevelOptions?.find((item) => {
              return item.value === trainingPlan?.niveau;
            })}
            onChange={(item) => {
              setValueAndMarkDirty("niveau", item?.value);
              clearErrors("niveau");
            }}
          />

          <OutlinedTextInput
            className="mt-15"
            type="number"
            label={translate("planRhythmLabel")}
            placeholder={translate("planRhythmPlaceholder")}
            inputRef={register("rhythm")}
            inputRefValue={getValues("rhythm")}
            validationMessage={errors.rhythm?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-15"
            type="number"
            label={translate("planDurationLabel")}
            placeholder={translate("planDurationPlaceholder")}
            inputRef={register("duration")}
            inputRefValue={getValues("duration")}
            validationMessage={errors.duration?.message?.toString()}
          />
        </Column>
      </Grid>
    );
  };

  return (
    <form
      id="training-plan-form"
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error(translate("onSubmitError"));
      })}
    >
      <ComponentWrapper
        title={
          isEditing
            ? translate("componentWrapper.edit")
            : translate("componentWrapper.add")
        }
        className="mb-15"
      >
        {_buildTitleAndDescription()}
        {_buildRepresentationInfo()}
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "trainingPlanStore",
  "modalStore"
)(observer(TrainingPlanForm));
