import React, { useEffect } from "react";
import "./attachment.component.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { useFilePicker } from "use-file-picker";
import { HttpUploadService } from "services/httpClients/http.upload.client";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";

interface AttachmentProps {
  onAttach?: any;
  className?: string;
}

const Attachment = ({ onAttach, className }: AttachmentProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "general");

  const [openFileSelector, { plainFiles }] = useFilePicker({
    readAs: "DataURL",
    accept: ["application/*", "image/*"],
    multiple: true,
    limitFilesConfig: { max: 2 },
    maxFileSize: 1,
  });

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      attachFiles(plainFiles);
    }
  }, [plainFiles]);

  const attachFiles = async (files: any): Promise<void> => {
    const uploadedFiles = await HttpUploadService.getInstance().uploadFiles({
      files,
      folder: "attachments",
    });
    onAttach(uploadedFiles);
  };

  const attachmentClassName = classNames(
    { "attachment-container": true },
    className
  );

  return (
    <div
      className={attachmentClassName}
      onClick={() => {
        openFileSelector();
      }}
    >
      <FontAwesomeIcon
        icon={faPaperclip}
        className="attachment-container-icon"
      />
      <span>{translate("attachment")}</span>
    </div>
  );
};

export default Attachment;
