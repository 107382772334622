import { Comment } from "schemas/comment.schemas/comment.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpCommentService extends GenericHttpClient<Comment> {
  static _instance: HttpCommentService;
  static getInstance(): HttpCommentService {
    if (this._instance == null) {
      this._instance = new HttpCommentService("/comments");
    }
    return this._instance;
  }
}
