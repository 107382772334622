import React from "react";
import { StateStore, WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import "./page.intro.component.scss";
import defaultIllustration from "assets/illustrations/invitation.png";
import { PgaeIntroWidgetOptions } from "../schemas/page.intro.schema";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface PageInfoProps {
  widgetID: string;
  widgetStore?: WidgetStore;
  stateStore?: StateStore;
}

const PageIntroWidget = ({
  widgetID,
  widgetStore,
  stateStore,
}: PageInfoProps): JSX.Element => {
  const options: PgaeIntroWidgetOptions =
    widgetStore?.getAllOptionsForWidget(widgetID);

  return (
    <PageIntroCard
      image={defaultIllustration}
      imagePosition="bottom center"
      title={options?.title ?? ""}
      collapsedTitle={options?.collapsedTitle ?? ""}
      description={options?.description ?? ""}
      id="page-intro-component"
      renderButtons={() => (
        <FilledButton
          onClick={() => {}}
          className="mt-10"
          label={options?.buttonLabel ?? ""}
        />
      )}
    />
  );
};

export default inject("widgetStore", "stateStore")(observer(PageIntroWidget));
