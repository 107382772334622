import React, { useState, useEffect } from "react";
import "./filter.component.scss";
import classNames from "classnames";
import {
  faCirclePlus,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FilterValue } from "globals/helpers/storage.helper";

import ListDropdownMenu from "components/input.components/dropdown.components/list.dropdown.component/list.dropdown.component";
import SelectDropDownList, {
  SelectItem,
} from "components/list.components/select.dropdown.list.component/select.dropdown.list.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";

interface FilterProps {
  initialFilterData?: FilterValue[] | undefined;
  options: FilterValue[];
  isDisableFilter: boolean;
  isClearFilter: boolean;
  onFilter: (options: FilterValue[]) => Promise<void>;
}

const Filter = ({
  initialFilterData,
  options,
  isDisableFilter,
  isClearFilter,
  onFilter,
}: FilterProps): JSX.Element => {
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<FilterValue[]>(
    initialFilterData ?? []
  );
  const [selectedOptions, setSelectedOptions] = useState<FilterValue[]>(
    initialFilterData ?? []
  );

  useEffect(() => {
    if (isClearFilter || isDisableFilter) {
      setSelectedColumns([]);
    }
  }, [isClearFilter, isDisableFilter]);

  // convert initial options to required type
  const initialSelectedOptions = initialFilterData?.map((option) => ({
    columnName: option.columnName,
    columnValue: option.columnValue,
    values: option.values.map((v) => ({ label: v.name, value: v.value })),
  }));

  const columnsDropDownItems = options.map((option) => ({
    label: option.columnName,
    onClick: () => {
      setIsDropDownOpened(false);
      if (
        !selectedColumns.find((col) => col.columnValue === option.columnValue)
      ) {
        setSelectedColumns([...selectedColumns, option]);
      }
    },
  }));

  const onSelectFilter = async (
    columnName: string,
    columnValue: string,
    values: SelectItem[]
  ): Promise<void> => {
    const newOption: FilterValue = {
      columnName,
      columnValue,
      values: values.map((v) => {
        return {
          name: v.label,
          value: v.value,
        };
      }),
    };

    const newOptions = values.length
      ? [
          ...selectedOptions.filter(
            (option) => option.columnValue !== columnValue
          ),
          ...[newOption],
        ]
      : selectedOptions.filter((option) => option.columnValue !== columnValue);

    setSelectedOptions(newOptions);
    await onFilter(newOptions);
  };

  const onCloseFilter = async (columnValue: string): Promise<void> => {
    const newOptions = selectedColumns.filter(
      (column) => column.columnValue !== columnValue
    );

    setSelectedColumns(newOptions);
    await onFilter(newOptions);
  };

  const filterClassName = classNames({
    "default-filter-container": true,
    "default-filter-container--disabled": isDisableFilter,
  });

  const filterButtonWrapperClassName = classNames({
    "filter-button-wrapper": true,
    "filter-button-wrapper--disabled": isDisableFilter,
  });

  const multiSelects = selectedColumns.map((column, index) => {
    const optionsToDisplay = column.values.map((v) => ({
      label: v.name,
      value: v.value,
    }));

    // get initial options of the selected column
    const selectedOption = initialSelectedOptions?.find(
      (element) => element.columnValue === column.columnValue
    );

    return (
      <SelectDropDownList
        key={column.columnValue + index.toString()}
        options={optionsToDisplay}
        onClose={async () => await onCloseFilter(column.columnValue)}
        selectDropDownListButtonLabel={column.columnName}
        initialSelectedOptions={selectedOption?.values}
        onSelect={async (items) =>
          await onSelectFilter(column.columnName, column.columnValue, items)
        }
        selectDropDownListButton={(props) => (
          <div className={filterButtonWrapperClassName}>
            <FilledButton color="light" {...props} />
          </div>
        )}
      />
    );
  });

  return (
    <div className={filterClassName}>
      {selectedColumns.length > 0 ? (
        <>
          {multiSelects}
          <ListDropdownMenu
            placement="right"
            items={columnsDropDownItems}
            colorScheme="secondary"
            dropdownMenuButton={(props) => (
              <div className={filterButtonWrapperClassName}>
                <IconButton
                  disabled={isDisableFilter}
                  icon={faPlus}
                  {...props}
                />
              </div>
            )}
          />
        </>
      ) : (
        <ListDropdownMenu
          placement="right"
          items={columnsDropDownItems}
          colorScheme="secondary"
          onDropDownToggle={(value) => setIsDropDownOpened(value)}
          dropdownButtonClasses="font-weight-bold"
          dropdownMenuButton={(props) => (
            <div className={filterButtonWrapperClassName}>
              <FilledButton
                disabled={isDisableFilter}
                label="Add Filter"
                color="light"
                icon={isDropDownOpened ? faChevronUp : faCirclePlus}
                isIconBehind={isDropDownOpened}
                {...props}
              />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default Filter;
