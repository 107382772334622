// helper methods for saveing and removeing access and refresh tokens in local storage

export const saveAccessToken = (token: string): any => {
  return localStorage.setItem("access-token", token);
};

export const getAccessToken = (): any => {
  return localStorage.getItem("access-token");
};

export const saveRefreshToken = (refreshToken: string): any => {
  return localStorage.setItem("refresh-token", refreshToken);
};

export const getRefreshToken = (): any => {
  return localStorage.getItem("refresh-token");
};

export const setLastVisitedStudio = (studioId: string): any => {
  return localStorage.setItem("last-visited-studio", studioId);
};

export const getLastVisitedStudio = (): any => {
  return localStorage.getItem("last-visited-studio");
};

export const setResource = (studioId: string): any => {
  return localStorage.setItem("resource", studioId);
};

export const getResource = (): any => {
  return localStorage.getItem("resource");
};

export const deleteAccessAndRefreshToken = (): any => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
};

// helper methods to manage sort and filter values in local storage

export const LOCAL_STORAGE_SORT_KEY = "sort";
export const LOCAL_STORAGE_FILTER_KEY = "filter";

export type SortOrder = "asc" | "desc";

export interface InitialSortData {
  sortByProp: string;
  sortOrder: SortOrder;
}

export interface FilterValue {
  columnName: string;
  columnValue: string;
  values: Array<{
    name: string;
    value: string;
  }>;
}

export interface LocalSortFilterOption {
  id?: string;
  sortByProp?: string;
  sortOrder?: SortOrder;
  filterValues?: FilterValue[];
  isPagination?: boolean;
  isScroll?: boolean;
  pageIndex?: number;
  itemsInPage?: number;
}

export const getLocalStorageOption = (
  key: string,
  id: string
): LocalSortFilterOption | undefined => {
  const localStorageOptions = localStorage.getItem(key);
  const options: LocalSortFilterOption[] = localStorageOptions
    ? JSON.parse(localStorageOptions)
    : [];
  return options.find((option) => option.id === id);
};

export const updateLocalStorageOption = (
  key: string,
  args: LocalSortFilterOption,
  id: string
): void => {
  const localStorageOptions = localStorage.getItem(key);
  const options: LocalSortFilterOption[] = localStorageOptions
    ? JSON.parse(localStorageOptions)
    : [];

  // get previous option data
  const prevOptionsIndex = options.findIndex((option) => option.id === id);
  const optionToPush: any = {
    id,
  };

  if (args.sortByProp && args.sortOrder) {
    optionToPush.sortByProp = args.sortByProp;
    optionToPush.sortOrder = args.sortOrder;
  }

  if (args.filterValues) {
    optionToPush.filterValues = args.filterValues;
  }

  // update option data
  if (prevOptionsIndex !== -1) {
    options[prevOptionsIndex] = {
      ...options[prevOptionsIndex],
      ...optionToPush,
    };
  } else {
    options.push({ ...optionToPush });
  }

  localStorage.setItem(key, JSON.stringify(options));
};

export const getLocalStorageSortData = (
  id: string
): InitialSortData | undefined => {
  const localStorageItem = getLocalStorageOption(LOCAL_STORAGE_SORT_KEY, id);

  if (localStorageItem?.sortByProp && localStorageItem?.sortOrder) {
    return {
      sortByProp: localStorageItem.sortByProp,
      sortOrder: localStorageItem.sortOrder,
    };
  }
};

export const getLocalStorageFilterData = (
  id: string
): FilterValue[] | undefined => {
  const localStorageItem = getLocalStorageOption(LOCAL_STORAGE_FILTER_KEY, id);

  return localStorageItem?.filterValues;
};

export const removeLocalStorageOptionByID = (key: string, id: string): void => {
  const localStorageOptions = localStorage.getItem(key);
  const options: LocalSortFilterOption[] = localStorageOptions
    ? JSON.parse(localStorageOptions)
    : [];
  const index = options.findIndex((option) => option.id === id);
  options.splice(index, 1);
  localStorage.setItem(key, JSON.stringify(options));
};
