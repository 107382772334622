import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { inject, observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ModalStore } from "stores/modal.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import DataAttributeCollectionStore from "stores/data.attribute.collection.store";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { dataAttributeCollectionSchema } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";

interface DataAttributeCollectionFormModalProps {
  modalStore?: ModalStore;
  dataAttributeCollectionStore?: DataAttributeCollectionStore;
}

const DataAttributeCollectionFormModal = ({
  modalStore,
  dataAttributeCollectionStore: collectionStore,
}: DataAttributeCollectionFormModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(
    t,
    "accountPage.dataAttributeTab.collections.modal"
  );

  const isEditing = modalStore?.customData?.isEditing;
  const currenCollection =
    collectionStore?.currentDataAttributeCollection?.data;

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(dataAttributeCollectionSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: isEditing ? currenCollection : {},
  });

  const onSubmit = async (data: any): Promise<void> => {
    if (!isEditing) {
      const createdCollection =
        await collectionStore?.createDataAttributeCollection({
          dataAttributeCollection: data,
        });

      if (createdCollection != null) {
        collectionStore?.setCurrentDataAttributeCollection(createdCollection);
      }
    } else {
      collectionStore?.updateDataAttributeCollection({
        dataAttributeCollection: data,
      });
    }

    // close modal and reset current data attribute
    modalStore?.closeModal();
  };

  return (
    <ModalHeader
      title={translate(`headline${isEditing ? "Edit" : "New"}`)}
      description={translate(`description${isEditing ? "Edit" : "New"}`)}
    >
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          toast.error(translate("invalidForm"));
          console.log(getValues());
        })}
      >
        <OutlinedTextInput
          label={translate("title")}
          inputRef={register("title")}
          inputRefValue={getValues("title")}
          validationMessage={errors.title?.message}
          className="mb-25"
        />

        <FilledButton
          disabled={!isDirty}
          label={translate("saveButton")}
          type="submit"
          color="secondary"
        />
      </form>
    </ModalHeader>
  );
};

export default inject(
  "modalStore",
  "dataAttributeCollectionStore"
)(observer(DataAttributeCollectionFormModal));
