import React, { useState, useEffect, useMemo } from "react";
import "./task.management.modal.component.scss";
import { inject, observer } from "mobx-react";
import * as yup from "yup";
import TaskStore from "stores/task.store";
import CommentStore from "stores/comment.store";
import AssetStore from "stores/asset.store";
import DataAttributeCollectionStore from "stores/data.attribute.collection.store";
import DataAttributeStore from "stores/data.attribute.store";
import { ModalStore } from "stores/modal.store";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TaskStatusType,
  PriorityType,
  TaskType,
} from "globals/enums/global.enum";
import { generateValidationSchemaNew } from "globals/helpers/data.attribute.helper";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { Comment } from "schemas/comment.schemas/comment.schema";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { showErrorMessage } from "globals/helpers/error.helper";
import { transformToAssets } from "globals/helpers/asset.transform.helper";

import TaskInfoForm from "./components/task.info.form.component";
import TaskAttachmentForm from "./components/task.attachment.form.component";
import TaskDetailForm from "./components/task.detail.form.component";
import TaskActivityForm from "./components/task.activity.form.component";

import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import Column from "components/general.compoenents/column.component/column.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import Grid from "components/general.compoenents/grid.component/grid.component";
import TaskToolbar from "./components/task.toolbar.component";

const taskFormSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().notRequired(),
  creator: yup.string().required(),
  status: yup.string().oneOf(Object.values(TaskStatusType)).required(),
  type: yup.string().oneOf(Object.values(TaskType)).required(),
  assignee: yup.string().required(),
  responsibilities: yup.array().of(yup.string()).notRequired(),
  members: yup.array().of(yup.string()).notRequired(),
  attachments: yup.array().of(yup.string()).notRequired(),
  dueDate: yup.date().notRequired(),
  priority: yup.string().oneOf(Object.values(PriorityType)).required(),
  formData: yup.object().notRequired(),
});

enum MainSectionTabs {
  INFO = "INFO",
  ATTACHMENT = "ATTACHMENT",
}

enum DetailSectionTabs {
  DONE = "DONE",
  ACTIVITY = "ACTIVITY",
}

interface TaskManagementModalProps {
  taskStore?: TaskStore;
  commentStore?: CommentStore;
  assetStore?: AssetStore;
  modalStore?: ModalStore;
  dataAttributeCollectionStore?: DataAttributeCollectionStore;
  dataAttributeStore?: DataAttributeStore;
}

const TaskManagementModal = ({
  taskStore,
  commentStore,
  assetStore,
  modalStore,
  dataAttributeCollectionStore: collectionStore,
  dataAttributeStore,
}: TaskManagementModalProps): JSX.Element => {
  const folder = "attachments";
  const task = taskStore?.currentTask?.data;
  const collections = collectionStore?.dataAttributeCollections?.data;
  const taskCollectionID =
    collectionStore?.currentDataAttributeCollection?.data?._id;
  const comments = commentStore?.comments?.data ?? [];

  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.modal");

  const [mainSectionTab, setMainSectionTab] = useState(MainSectionTabs.INFO);
  const [detailSectionTab, setDetailSectionTab] = useState(
    DetailSectionTabs.DONE
  );
  const [customAttributes, setCustomAttributes] = useState(
    [] as DataAttribute[]
  );

  useEffect(() => {
    initializeComments();
    initializeCollections();

    return () => {
      commentStore?.setComments([]);
      assetStore?.clearAllAssets();
      collectionStore?.setCurrentDataAttributeCollection(undefined);
    };
  }, []);

  useEffect(() => {
    // set the current Task collection
    if (collections != null && collections.length > 0) {
      const currentCollection = collections.find(
        (item) => item.title === "tasks"
      );
      if (currentCollection) {
        collectionStore?.setCurrentDataAttributeCollection(currentCollection);
      }
    }
  }, [collections]);

  useEffect(() => {
    if (taskCollectionID) {
      initializeAttributes(taskCollectionID);
    }
  }, [taskCollectionID]);

  useEffect(() => {
    let taskAssets: Asset[] = [];
    let commentAssets: Asset[] = [];

    if (task?.attachments) {
      // get Task attachments
      const assets = transformToAssets(task.attachments, folder);
      taskAssets = assets;
    }
    if (comments.length) {
      // get Comment attachments
      let commentAttachments: any[] = [];
      comments.forEach((comment: Comment) => {
        if (comment.attachments?.length) {
          commentAttachments = [...commentAttachments, ...comment.attachments];
        }
      });
      const assets = transformToAssets(commentAttachments);
      commentAssets = assets;
    }
    // set assets from both of Task and Comment attachments
    assetStore?.setAssets([...taskAssets, ...commentAssets]);
  }, [task, commentStore?.comments?.data]);

  const initializeAttributes = async (collectionID: string): Promise<void> => {
    await dataAttributeStore?.fetchAndSetAttributesForCollection({
      collectionID,
    });

    const attributes =
      dataAttributeStore?.getCollectionAttributes(collectionID);
    if (attributes?.data.length) {
      setCustomAttributes(
        attributes.data.filter((item) => item.path === "formData")
      );
    }
  };

  const initializeComments = async (): Promise<void> => {
    if (task?._id === undefined) return;
    await commentStore?.fetchAndSetCommentsForTask({ taskId: task?._id });
  };

  const initializeCollections = async (): Promise<void> => {
    await collectionStore?.fetchAndSetDataAttributeCollections({
      refresh: true,
    });
  };

  const taskValidationSchema = useMemo(() => {
    if (dataAttributeStore) {
      const formDataValidationSchema = generateValidationSchemaNew(
        JSON.parse(JSON.stringify(customAttributes)) ?? [],
        dataAttributeStore
      );
      taskFormSchema.fields.formData = formDataValidationSchema.fields.formData;
      return yup.object().shape(taskFormSchema.fields);
    }
  }, [customAttributes, dataAttributeStore]);

  const taskForm = useForm({
    resolver: yupResolver(taskValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: {
      ...task,
      creator: task?.creator?._id,
      assignee: task?.assignee?._id,
      responsibilities: task?.responsibilities?.map((item: any) => item._id),
      members: task?.members?.map((item: any) => item._id),
      attachments: task?.attachments?.map((item: any) => item._id),
    } as any,
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
  } = taskForm;

  const formIsDirty = isDirty;

  const _buildAttachmentsLabel = (): string => {
    if (assetStore?.assets?.data.length) {
      return `${translate("tab.attachment")} 
      (${assetStore?.assets?.data.length})`;
    }
    return translate("tab.attachment");
  };

  const _buildMainTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        tabs={[
          <Tab
            type="button"
            label={translate("tab.info")}
            key="info-tab"
            active={mainSectionTab === MainSectionTabs.INFO}
            onClick={() => {
              setMainSectionTab(MainSectionTabs.INFO);
            }}
          />,
          <Tab
            type="button"
            label={_buildAttachmentsLabel()}
            key="attachment-tab"
            active={mainSectionTab === MainSectionTabs.ATTACHMENT}
            onClick={() => {
              setMainSectionTab(MainSectionTabs.ATTACHMENT);
            }}
          />,
        ]}
      ></LinkTabs>
    );
  };

  const _buildDetailTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        tabs={[
          <Tab
            type="button"
            label={translate("tab.done")}
            key="done-tab"
            active={detailSectionTab === DetailSectionTabs.DONE}
            onClick={() => {
              setDetailSectionTab(DetailSectionTabs.DONE);
            }}
          />,
          <Tab
            type="button"
            label={translate("tab.activity")}
            key="activity-tab"
            active={detailSectionTab === DetailSectionTabs.ACTIVITY}
            onClick={() => {
              setDetailSectionTab(DetailSectionTabs.ACTIVITY);
            }}
          />,
        ]}
      ></LinkTabs>
    );
  };

  const _buildInfoContainer = (): JSX.Element => {
    return (
      <TaskInfoForm
        errors={errors}
        onErrorClear={handleFormFieldErrorClear}
        formMethods={taskForm}
      />
    );
  };

  const _buildAttachmentContainer = (): JSX.Element => {
    return <TaskAttachmentForm formMethods={taskForm} />;
  };

  const _buildDoneContainer = (): JSX.Element => {
    return (
      <TaskDetailForm
        errors={errors}
        onErrorClear={handleFormFieldErrorClear}
        formMethods={taskForm}
      />
    );
  };

  const _buildActivityContainer = (): JSX.Element => {
    return <TaskActivityForm />;
  };

  const handleFormFieldErrorClear = (fieldName: string): void => {
    clearErrors(fieldName);
  };

  const removeTask = async (): Promise<void> => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (task?._id == null) return;
        taskStore?.removeTask({ taskId: task._id });
      },
      confirmLabel: translate("form.deleteButton.confirmLabel"),
      title: translate("form.deleteButton.title"),
      description: translate("form.deleteButton.description"),
    });
  };

  const completeTask = async (): Promise<void> => {
    if (task?._id) {
      await taskStore?.updateTask({
        taskId: task._id,
        data: {
          status: TaskStatusType.DONE,
        },
      });
    }
    modalStore?.closeModal();
  };

  const onTaskSubmit = async (data: any): Promise<void> => {
    // Apply attachments to Task data
    if (assetStore?.selectedUploadedAssets?.length) {
      const selectedUploadedAssetsIds = assetStore?.selectedUploadedAssets.map(
        (item) => item._id
      );
      data.attachments = task?.attachments
        ? [...task.attachments, ...selectedUploadedAssetsIds]
        : selectedUploadedAssetsIds;
    }

    if (task?._id) {
      await taskStore?.updateTask({
        taskId: task._id,
        data,
      });
    } else {
      await taskStore?.createTask({ data });
    }
    modalStore?.closeModal();
  };

  const TaskModalWrapperClassName = classNames({
    "task-modal-wrapper": true,
    "task-modal-wrapper--no-headline": !task?.title,
  });

  return (
    <form
      id="task-form"
      onSubmit={handleSubmit(onTaskSubmit, (errors) => {
        showErrorMessage(errors);
      })}
    >
      <FormWrapper className={TaskModalWrapperClassName}>
        <Headline>{task?.title}</Headline>
        <Grid
          templateColumns="repeat(2, 1fr)"
          className="mt-20 task-modal-form-wrapper"
          gap={30}
        >
          <Column
            justifyContent="space-between"
            className="main-form-container"
          >
            {_buildMainTabs()}

            {mainSectionTab === MainSectionTabs.INFO && _buildInfoContainer()}
            {mainSectionTab === MainSectionTabs.ATTACHMENT &&
              _buildAttachmentContainer()}
          </Column>
          <Column gap={15}>
            <Column gap={20} className="detail-form-container">
              <Column>
                {_buildDetailTabs()}

                {detailSectionTab === DetailSectionTabs.DONE &&
                  _buildDoneContainer()}
                {detailSectionTab === DetailSectionTabs.ACTIVITY &&
                  _buildActivityContainer()}
              </Column>
            </Column>
            <Column>
              <TaskToolbar
                formID="task-form"
                disabled={!formIsDirty}
                handleDelete={removeTask}
                handleComplete={completeTask}
              />
            </Column>
          </Column>
        </Grid>
      </FormWrapper>
    </form>
  );
};

export default inject(
  "taskStore",
  "commentStore",
  "assetStore",
  "modalStore",
  "dataAttributeCollectionStore",
  "dataAttributeStore"
)(observer(TaskManagementModal));
