import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import "./date.picker.component.scss";
import classNames from "classnames";

interface DatepickerProps {
  value?: Date;
  isClearable?: boolean;
  dateFormat?: string;
  isShowIcon?: boolean;
  isShowTimeSelect?: boolean;
  onSelect?: (date: Date | null) => void;
  validationMessage?: string;
  inputRef?: any;
  className?: string;
  disabled?: boolean;
}

const Datepicker = ({
  value,
  isClearable = false,
  dateFormat,
  isShowIcon = false,
  isShowTimeSelect = false,
  onSelect,
  validationMessage,
  className,
  disabled,
}: DatepickerProps): JSX.Element => {
  const [startDate, setStartDate] = useState(value);

  const datepickerClassName = classNames(
    {
      "date-picker-container": true,
      "date-picker-container--error": validationMessage != null,
    },
    className
  );

  return (
    <div className={datepickerClassName}>
      <DatePicker
        disabled={disabled}
        className="date-picker"
        isClearable={isClearable}
        showIcon={isShowIcon}
        toggleCalendarOnIconClick
        showTimeSelect={isShowTimeSelect}
        selected={startDate}
        dateFormat={dateFormat}
        onChange={(date) => {
          if (date !== null) {
            setStartDate(date);
          }
        }}
        onSelect={onSelect}
      />
    </div>
  );
};

export default Datepicker;
