import React from "react";
import "./upload.asset.list.component.scss";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import AssetStore from "stores/asset.store";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { redirectToExternalResource } from "globals/helpers/navigation.helper";
import { AssetType } from "globals/enums/global.enum";

import ListDataTable from "../list.data.table.component/list.data.table.component";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";

interface UploadAssetListProps {
  assetStore?: AssetStore;
  assetSelectionLimit?: number;
  className?: string;
}

const UploadAssetsList = ({
  assetStore,
  assetSelectionLimit,
  className,
}: UploadAssetListProps): JSX.Element => {
  const uploadedAssets = assetStore?.uploadedAssets ?? [];
  const selectedUploadedAssets = assetStore?.selectedUploadedAssets ?? [];

  const checkAssetSelected = (asset: Asset): boolean => {
    return !!selectedUploadedAssets?.find(
      (item: Asset) => item._id === asset._id
    );
  };

  const removeSelectedUploadedAsset = (asset: Asset): void => {
    const selectedAssetIndex: number | undefined =
      selectedUploadedAssets?.findIndex(
        (item: Asset) => item._id === asset._id
      );

    if (selectedAssetIndex !== undefined) {
      selectedUploadedAssets?.splice(selectedAssetIndex, 1);
      assetStore?.setSelectedUploadedAssets([...selectedUploadedAssets]);
    }
  };

  const addSelectedUploadedAsset = (asset: Asset): void => {
    if (!selectedUploadedAssets) {
      assetStore?.setSelectedUploadedAssets([asset]);
    } else {
      if (selectedUploadedAssets.length === assetSelectionLimit) {
        return;
      }

      assetStore?.setSelectedUploadedAssets([
        ...selectedUploadedAssets,
        ...[asset],
      ]);
    }
  };

  const removeUploadedAssets = (index: number): void => {
    uploadedAssets?.splice(index, 1);
    assetStore?.setUploadedAssets([...uploadedAssets]);
  };

  return (
    <ListDataTable
      data={uploadedAssets}
      tableClassName={className}
      onClick={(asset: Asset) => {
        if (checkAssetSelected(asset)) {
          removeSelectedUploadedAsset(asset);
        } else {
          addSelectedUploadedAsset(asset);
        }
      }}
      columns={[
        {
          flex: 1,
        },
        {
          flex: 1,
        },
        {
          flex: 1,
        },
      ]}
      dataTableItemBuilder={(dataItem: Asset, index: number) => {
        const isSelected = selectedUploadedAssets?.find((selectedItem: any) => {
          return selectedItem._id === dataItem._id;
        });

        return {
          key: dataItem._id,
          className: classNames({
            "list-data-table-body-item-selected": isSelected,
          }),
          children: [
            {
              child: (
                <Row className="attachment-title" alignItems="center">
                  <Image
                    className="mr-10 attachment-image"
                    rounded={false}
                    imageUrl={
                      dataItem.type && dataItem.type !== AssetType.IMAGE
                        ? ""
                        : dataItem.url
                    }
                  />
                  <span style={{ fontSize: "14px" }}>
                    {dataItem.name ?? ""}
                  </span>
                </Row>
              ),
            },
            {
              child: (
                <Row justifyContent="center" alignItems="center">
                  <FontAwesomeIcon
                    className="uploaded-check-icon"
                    icon={faCheck}
                  />
                  {`Completed`}
                </Row>
              ),
            },
            {
              child: (
                <Row justifyContent="flex-end">
                  <FontAwesomeIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      redirectToExternalResource(dataItem.url);
                    }}
                    className="uploaded-item-icon"
                    icon={faEye}
                  />
                  <FontAwesomeIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      removeUploadedAssets(index);
                    }}
                    className="uploaded-item-icon"
                    icon={faXmark}
                  />
                </Row>
              ),
            },
          ],
        };
      }}
      gap={30}
      disableHeader={true}
    />
  );
};

export default inject("assetStore")(observer(UploadAssetsList));
