import React, { UIEvent, useState, useEffect, useRef } from "react";
import "./list.data.table.component.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { SortOrder, InitialSortData } from "globals/helpers/storage.helper";
import { SelectType } from "../advanced.list.data.table.component/advanced.list.data.table.component";

import ListDropdownMenu, {
  DropdownPlacements,
  ListDropdownItem,
} from "components/input.components/dropdown.components/list.dropdown.component/list.dropdown.component";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";
import Skeleton from "react-loading-skeleton";
import { LargeTextSkeleton } from "components/text.components/large.text.component/large.text.component";
import { SmallTextSkeleton } from "components/text.components/small.text.component/small.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";
import DataTableFooter, {
  PaginationData,
  PaginationType,
} from "components/footer.component/footer.component";

export interface DataTableHeader {
  flex?: number;
  child?: JSX.Element;
  sortBy?: string;
  filterBy?: string;
  filterByName?: string;
  filterValues?: Array<{
    name: string;
    value: string;
  }>;
}

export interface DataRow {
  children: DataTableRowCell[];
  className?: string;
  key: string;
}

export interface DataTableRowCell {
  child: JSX.Element;
  className?: string;
}

export interface ListDropdownMenuProps<T> {
  className?: string;
  items: Array<ListDropdownItem<T>>;
  placement?: DropdownPlacements;
}

interface ListDataTableProps<T> {
  data: T[];
  selectType?: SelectType;
  selectedItems?: any[];
  columns: DataTableHeader[];
  dataTableItemBuilder: (data: T, index: number) => DataRow;
  itemClassName?: string;
  tableClassName?: string;
  onClick?: (
    data: T,
    index: number,
    event: React.MouseEventHandler<HTMLDivElement>
  ) => void;
  onSelect?: (data: T | T[]) => void;
  onSort?: (propName: string, sortOrder: SortOrder) => void;
  gap?: number;
  disableHeader?: boolean;
  sortable?: boolean;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
  noDataMessage?: string;
  isLoading?: boolean;
  isSelectMode?: boolean;
  isPagination?: boolean;
  paginationData?: PaginationData;
  paginationType?: PaginationType;
  listDropdownMenu?: ListDropdownMenuProps<T>;
  disableSort?: boolean;
  initialSortData?: InitialSortData;
  isClearSort?: boolean;
  handleChangePage?: (page: number) => void;
  handleScrollPage?: () => void;
  handleSelectPageItems?: (page: number) => void;
}

const ListDataTable = <T extends unknown>({
  data,
  selectType,
  selectedItems,
  columns,
  gap = 0,
  dataTableItemBuilder,
  itemClassName,
  tableClassName,
  onClick,
  onSelect,
  disableHeader = false,
  sortable = false,
  onSortEnd,
  noDataMessage,
  isLoading,
  isSelectMode,
  isPagination,
  paginationData,
  paginationType,
  listDropdownMenu: listDropdownProps,
  disableSort = false,
  onSort,
  initialSortData,
  isClearSort,
  handleChangePage,
  handleScrollPage,
  handleSelectPageItems,
}: ListDataTableProps<T>): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null);

  const [sortOrder, setSortOrder] = useState<SortOrder>(
    initialSortData ? initialSortData.sortOrder : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    initialSortData ? initialSortData.sortByProp : ""
  );
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    if (isClearSort) {
      setSortColumn("");
      setSortOrder("desc");
    }
  }, [isClearSort]);

  // set scroll position after getting a new data set
  useEffect(() => {
    if (listRef?.current && scrollPosition) {
      listRef.current.scrollTop = scrollPosition;
    }
  }, [data]);

  const toggleSort = (propName: string | undefined): void => {
    if (!propName) {
      return;
    }

    // toggle sort order on click
    let newSortOrder: SortOrder = sortOrder;

    // reset order (default value) for a new column
    if (sortColumn !== propName) {
      newSortOrder = "desc";
      setSortColumn(propName);
    } else {
      // toggle sort order for the current column on click
      if (sortOrder === "desc") {
        newSortOrder = "asc";
        setSortColumn(propName);
      }
      // reset sort order
      if (sortOrder === "asc") {
        newSortOrder = "desc";
        propName = "system.modifiedAt";
        setSortColumn("");
      }
    }

    setSortOrder(newSortOrder);

    if (onSort) {
      onSort(propName, newSortOrder);
    }
  };

  const checkIsSelectedItem = (dataItem: any): boolean => {
    return !!selectedItems?.find((item: any) => item._id === dataItem._id);
  };

  // handle a scroll event for the table data list
  const handleScroll = (e: UIEvent<HTMLDivElement>): void => {
    if (paginationType === PaginationType.NAVIGATE || isLoading) return;

    e.stopPropagation();

    if (isPagination && paginationData) {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const { pageIndex, itemsInPage, totalItems } = paginationData;

      // the end of the data list
      if (totalItems < (pageIndex + 1) * itemsInPage) return;

      const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
      );

      // request a new data set when scroll ends
      if (position === 100 && handleScrollPage) {
        setScrollPosition(scrollTop);
        handleScrollPage();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _prepareHeader = (): JSX.Element => {
    const headersAreSet = columns.find((column) => column.child != null);

    if (!headersAreSet) {
      return <></>;
    }

    const listDataTableHeaderClassName = classNames({
      "list-data-table-header": true,
      "list-data-table-header--disabled": isLoading,
    });

    const listDataTableHeaderItemClassName = classNames({
      "list-data-table-header-item": true,
      "list-data-table-header-item--disabled": isLoading,
    });

    return (
      <div className={listDataTableHeaderClassName} style={{ gap: gap ?? 0 }}>
        {/* RENDER CHECKBOX IF SELECTABLE */}
        {isSelectMode && selectType === SelectType.CHECKBOX && (
          <div
            className="list-data-table-header-item"
            style={{
              width: "20px",
            }}
          >
            <FilledCheckbox
              checked={!!data.length && selectedItems?.length === data.length}
              onChange={(e: any) => {
                e.stopPropagation();

                if (onSelect != null) {
                  if (selectedItems?.length === data.length) {
                    onSelect([]);
                  } else {
                    return onSelect(data);
                  }
                }
              }}
            />
          </div>
        )}

        {/* RENDER HEADERS */}
        {columns.map((column, index) => {
          const listDataTableHeaderItemIconClassName = classNames({
            "list-data-table-header-item-icon": true,
            "list-data-table-header-item-icon--desc": sortOrder === "desc",
            "list-data-table-header-item-icon--visible":
              sortColumn === column.sortBy,
          });

          return (
            <div
              key={`header-${index}`}
              className={listDataTableHeaderItemClassName}
              style={{ flex: column.flex ?? 1 }}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                if (!sortable || isLoading) return;
                toggleSort(column.sortBy);
              }}
            >
              {column.child}
              {sortable && (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className={listDataTableHeaderItemIconClassName}
                />
              )}
            </div>
          );
        })}

        {/* DROPDOWN MENU */}
        {listDropdownProps && (
          <div
            key="list-dropdown-menu"
            className={listDataTableHeaderItemClassName}
            style={{
              width: "45px",
            }}
          >
            <div style={{ paddingLeft: "8px" }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const _prepareRows = (): JSX.Element => {
    // if no data and no message is provided, return empty
    if ((data == null || data.length === 0) && noDataMessage == null) {
      return <></>;
    }

    return (
      <div
        className="list-data-table-body"
        onScroll={handleScroll}
        ref={listRef}
      >
        {isLoading && <SkeletonListDataTableItem count={8} />}

        {/* RENDER NO DATA MESSAGE */}
        {!isLoading &&
          noDataMessage != null &&
          (data == null || data.length === 0) && (
            <div className="list-data-table-body-item">
              <RunningText>{noDataMessage}</RunningText>
            </div>
          )}

        {/* RENDER DATA ROWS */}
        {!isLoading &&
          data.map((dataItem: any, index) => {
            if (dataItem == null) {
              return <></>;
            }
            return _prepareDataTableItem(
              dataTableItemBuilder(dataItem, index),
              dataItem
            );
          })}
      </div>
    );
  };

  const _prepareSortableRows = (): JSX.Element => {
    if (data == null) {
      return <></>;
    }

    return (
      <div
        className="list-data-table-body"
        onScroll={handleScroll}
        ref={listRef}
      >
        {isLoading && <SkeletonListDataTableItem count={8} />}

        {!isLoading && (
          <SortableList
            disabled={disableSort}
            data={data}
            onSortEnd={onSortEnd}
            itemBuilder={(dataItem, index) => {
              return (
                <>
                  {_prepareDataTableItem(
                    dataTableItemBuilder(dataItem, index),
                    dataItem
                  )}
                  <Spacer />
                </>
              );
            }}
          />
        )}
      </div>
    );
  };

  const _prepareDataTableItem = (
    dataRow: DataRow,
    dataItem: any,
    dragHandle?: any
  ): JSX.Element => {
    return (
      <div
        key={dataRow.key}
        className={classNames(
          {
            "list-data-table-body-item": true,
            "list-data-table-body-item--selected":
              checkIsSelectedItem(dataItem),
          },
          dataRow.className
        )}
        style={{ gap: gap ?? 0 }}
        onClick={(e: any) => {
          if (selectType === SelectType.CHECKBOX) return;

          if (onClick != null) {
            onClick(dataItem, data?.indexOf(dataItem) ?? 0, e);
          }
        }}
      >
        {/* RENDER CHECKBOX IF SELECTABLE */}
        {isSelectMode && selectType === SelectType.CHECKBOX && (
          <div
            className="list-data-table-body-item-cell"
            style={{
              width: "20px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <FilledCheckbox
              checked={selectedItems?.find(
                (item: any) => item._id === dataItem._id
              )}
              onChange={(e: any) => {
                e.stopPropagation();

                if (onSelect != null) {
                  onSelect(dataItem);
                }
              }}
            />
          </div>
        )}

        {/* DATA ROW */}
        {dataRow.children.map((dataRowItem, index) => {
          return (
            <div
              className={classNames({
                "list-data-table-body-item-cell": true,
                [dataRowItem?.className ?? ""]: true,
              })}
              key={`${dataRow.key}-${index}`}
              style={{
                flex: columns[index].flex ?? 1,
              }}
            >
              {dataRowItem.child}
            </div>
          );
        })}

        {/* DROPDOWN MENU */}
        {listDropdownProps && (
          <div
            className="list-data-table-body-item-cell"
            key={`${dataRow.key}-dropdown`}
            style={{
              width: "45px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <ListDropdownMenu
              dataItem={dataItem}
              dropdownMenuButton={(props) => (
                <FontAwesomeIcon {...props} icon={faEllipsisH} />
              )}
              {...listDropdownProps}
            />
          </div>
        )}

        {/* DRAG HANDLE */}
        {dragHandle && (
          <div className="list-data-table-body-item-cell">{dragHandle}</div>
        )}
      </div>
    );
  };

  const _prepareFooter = (): JSX.Element => {
    if (!isPagination || !data.length || !paginationData) return <></>;

    return (
      <DataTableFooter
        type={paginationType}
        data={paginationData}
        selectOptions={[10, 20, 40, 100]}
        handleChangePage={handleChangePage}
        handleSelectPageItems={handleSelectPageItems}
      />
    );
  };

  const listDataTableClassName = classNames(
    {
      "list-data-table": true,
    },
    tableClassName
  );

  return (
    <div className={listDataTableClassName}>
      {!disableHeader && _prepareHeader()}
      {!sortable && _prepareRows()}
      {sortable && _prepareSortableRows()}
      {_prepareFooter()}
    </div>
  );
};

export default ListDataTable;

export const SkeletonSelectionListDataTableItem = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(count)
        .map((_, i) => {
          return (
            <>
              <Row
                key={i}
                justifyContent="space-between"
                className="full-width pl-50 pr-50 pt-15 pb-15"
              >
                <Skeleton count={1} width={60} height={60} className="mr-15" />
                <Column justifyContent="center">
                  <LargeTextSkeleton className="full-width" />
                  <SmallTextSkeleton />
                </Column>
              </Row>
              <Spacer width="100%" />
            </>
          );
        })}
    </>
  );
};

export const SkeletonListDataTableItem = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(count)
        .map((_, i) => {
          return (
            <Column key={i} alignItems="center">
              <div className="skeleton-list-data-item-wrapper">
                <div>
                  <Skeleton count={1} width={40} height={40} />
                </div>
                <div className="skeleton-list-data-item-text-container">
                  <LargeTextSkeleton />
                  <SmallTextSkeleton />
                </div>
              </div>
              <Spacer width="100%" />
            </Column>
          );
        })}
    </>
  );
};
