import React from "react";
import "./task.attachment.item.scss";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { AssetType } from "globals/enums/global.enum";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";

interface AttachmentItemProps {
  asset: Asset;
  isRemovable?: boolean;
  handleRemoveAttachment?: (id: string) => void;
}

const AttachmentItem = ({
  asset,
  isRemovable = false,
  handleRemoveAttachment,
}: AttachmentItemProps): JSX.Element => {
  return (
    <Row className="attachment-item" alignItems="center">
      <Image
        className="attachment-image"
        imageUrl={asset.type && asset.type !== AssetType.IMAGE ? "" : asset.url}
      />
      <SmallText className="attachment-text">
        {asset.originalName ?? ""}
      </SmallText>

      {isRemovable && (
        <IconButton
          icon={faClose}
          className="attachment-icon ml-10"
          onClick={() => {
            if (!handleRemoveAttachment) return;
            handleRemoveAttachment(asset._id);
          }}
        />
      )}
    </Row>
  );
};

export default AttachmentItem;
