import React from "react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faCheck } from "@fortawesome/free-solid-svg-icons";

import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface TaskToolbarProps {
  disabled: boolean;
  formID?: string;
  handleDelete?: () => Promise<void>;
  handleComplete?: () => Promise<void>;
}

const TaskToolbar = ({
  formID,
  disabled,
  handleDelete,
  handleComplete,
}: TaskToolbarProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.modal");

  return (
    <Row
      className="task-toolbar"
      justifyContent="space-between"
      alignItems="center"
      gap={10}
    >
      <div className="task-toolbar--delete-button">
        <FontAwesomeIcon
          className="task-toolbar--delete-button--icon"
          icon={faTrashCan}
          onClick={handleDelete}
        />
      </div>
      <FilledButton
        className="task-toolbar--complete-button"
        label={translate("toolbar.complete")}
        type="button"
        icon={faCheck}
        onClick={handleComplete}
      />
      <FilledButton
        className="task-toolbar--save-button"
        label={
          disabled ? translate("toolbar.saved") : translate("toolbar.save")
        }
        type="submit"
        form={formID}
        color="primary"
        disabled={disabled}
      />
    </Row>
  );
};

export default TaskToolbar;
