import React, { useCallback, useEffect, useState, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { debounce } from "lodash";
import { ModalStore } from "stores/modal.store";
import GlobalSearchStore, { SearchTopic } from "stores/global.search.store";
import GlobalSearchResultList from "./components/global.search.result.list.component";
import Column from "components/general.compoenents/column.component/column.component";
import TagFilterBarComponent, {
  FilterTag,
} from "components/input.components/tag.filter.bar.component/tag.filter.bar.component";
import Gleap from "gleap";
import "./global.search.modal.component.scss";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import Loading from "components/general.compoenents/loading.components/loading.component/loading.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { useInitialFocusAndSelect } from "globals/helpers/hook.helper";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface GlobalSearchModalProps {
  modalStore?: ModalStore;
  globalSearchStore?: GlobalSearchStore;
}

const GlobalSearchModal = ({
  modalStore,
  globalSearchStore: searchStore,
}: GlobalSearchModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "globalSearchModal");

  const { topic: initialTopic } = modalStore?.customData ?? {};

  // prepare tags for tag filter bar based on search topics enum
  const tags = Object.values(SearchTopic).map((topic) => ({
    key: topic,
    label: translate(`${topic}.label`),
  }));

  const [selectedTopic, setSelectedTopic] = useState<SearchTopic>(
    initialTopic ?? SearchTopic.ALL
  );
  const lastSearchTerm = searchStore?.getSearchedTermForTopic(selectedTopic);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const noDataForTopic = useMemo(
    () =>
      searchTerm?.trim().length > 0 &&
      (searchResult as any)?.data?.length === 0,
    [searchTerm, searchResult]
  );

  // focus and select the initial value of the input field
  const autoFocusRef = useInitialFocusAndSelect(searchTerm, lastSearchTerm);

  const loadSearchResult = (): void => {
    const searchResults = Object.values(SearchTopic)
      .filter((t) => t !== SearchTopic.ALL)
      ?.map((topic) => {
        return searchStore?.getSearchedDataForTopic(topic);
      });

    setSearchResult(searchResults);
    setIsLoading(false);
  };

  const debounceSearch = useCallback(
    debounce((nextValue, topic) => {
      searchStore?.globalSearch(nextValue, topic);
      loadSearchResult();
    }, 500),
    []
  );

  useEffect(() => {
    if (autoFocusRef.current) {
      autoFocusRef.current.focus();
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    if (
      searchTerm != null &&
      searchTerm !== lastSearchTerm &&
      searchTerm?.trim().length > 0
    ) {
      setIsLoading(true);
      debounceSearch(searchTerm, selectedTopic);
    }
  }, [searchTerm, debounceSearch]);

  useEffect(() => {
    if (lastSearchTerm != null && initialLoad) {
      setSearchTerm(lastSearchTerm);
    }
  }, []);

  const checkAllTopicsData = (): boolean => {
    const hasData = Object.values(SearchTopic)
      .filter((t) => t !== SearchTopic.ALL)
      .map((topic) => {
        const result = searchStore?.getSearchedDataForTopic(topic);
        return result?.data?.length > 0;
      });

    // return true if all topics have no data
    return !hasData.includes(true);
  };

  const handleTagSelectionChange = (selectedTags: FilterTag[]): void => {
    if (selectedTags?.length === 0) return;

    // set selected topic and reset search input
    const selectedTopic = selectedTags[0].key as SearchTopic;
    setSelectedTopic(selectedTopic);
    setSearchTerm("");
  };

  const renderSearchResults = useCallback((): JSX.Element[] | JSX.Element => {
    const allTopics = Object.values(SearchTopic).filter(
      (t) => t !== SearchTopic.ALL
    );

    if (selectedTopic === SearchTopic.ALL) {
      const results = searchResult.map((items, index) => {
        if (noDataForTopic) {
          return <></>;
        }

        return (
          <Column key={`${allTopics[index]}-search-results`} className="mb-10">
            <SmallHeadline className="mt-10 mb-5 ml-10">
              {translate(`${allTopics[index]}.label`)}
            </SmallHeadline>
            <GlobalSearchResultList
              searchTerm={searchTerm}
              key={allTopics[index]}
              topic={allTopics[index]}
              data={items?.data ?? []}
              isLoading={items?.isLoading ?? false}
            />
            <Spacer bold />
          </Column>
        );
      });

      const allTopicsHaveNoData = checkAllTopicsData();
      if (allTopicsHaveNoData) {
        return (
          <PageIntroCard
            key={`${selectedTopic}-no-data`}
            title={translate("noResultsTitle")}
            collapsedTitle={translate("noResultsTitle")}
            description={translate("noResultsDescripton")}
            wrapperClassName="mt-20"
            imagePosition="bottom center"
            id="all-topics-have-no-data"
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  Gleap.open();
                }}
                className="mt-10"
                label={translate("noResultAction")}
              />
            )}
          />
        );
      }

      return results;
    } else {
      const itemsIndex = allTopics.indexOf(selectedTopic);
      const items = searchResult[itemsIndex];

      if (noDataForTopic) {
        return (
          <PageIntroCard
            key={`${selectedTopic}-no-data`}
            title={translate("noResultsTitle")}
            collapsedTitle={translate("noResultsTitle")}
            description={translate("noResultsDescripton")}
            wrapperClassName="mt-20"
            imagePosition="bottom center"
            id="topic-have-no-data"
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  Gleap.open();
                }}
                className="mt-10"
                label={translate("noResultAction")}
              />
            )}
          />
        );
      }

      return (
        <GlobalSearchResultList
          searchTerm={searchTerm}
          topic={selectedTopic}
          data={items?.data ?? []}
          isLoading={items?.isLoading ?? false}
        />
      );
    }
  }, [selectedTopic, searchTerm, searchResult, noDataForTopic]);

  return (
    <Column>
      <div className="global-search-container">
        <input
          ref={autoFocusRef}
          className="global-search-container-input"
          type="text"
          placeholder={translate("searchPlaceholder")}
          value={searchTerm}
          onChange={(value) => {
            setSearchTerm(value?.target?.value ?? "");
          }}
        />
        {searchStore?.isLoading && <Loading theme="dark" size="semi-medium" />}
      </div>

      {!searchStore?.isLoading && (
        <>
          <Spacer />
          <TagFilterBarComponent
            className="tag-filter-bar-container"
            singleSelection
            tags={tags}
            initialSelectedTags={[{ key: selectedTopic, label: selectedTopic }]}
            onTagSelectionChange={handleTagSelectionChange}
          />
          <Spacer />
          {!isLoading && searchTerm?.trim()?.length > 0 && (
            <Column className="global-search-results">
              {renderSearchResults()}
            </Column>
          )}
        </>
      )}
    </Column>
  );
};

export default inject(
  "modalStore",
  "globalSearchStore"
)(observer(GlobalSearchModal));
