import React from "react";
import "./task.board.component.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import TaskStore from "stores/task.store";
import { Task } from "schemas/task.schemas/task.schema";
import { TaskStatusType, PriorityType } from "globals/enums/global.enum";

import AbstractKanbanBoard from "components/board.components/kanban.board.component/kanban.board.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import Headline from "components/text.components/headline.component/headline.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import Image from "components/image.component/image.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";

interface TaskBoardProps {
  tasks: Task[];
  onClick?: (task: Task) => void;
  isLoading?: boolean;
  taskStore?: TaskStore;
}

const TaskBoard = ({
  tasks,
  onClick,
  taskStore,
}: TaskBoardProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.overview");

  const columns = [
    {
      id: "0",
      label: translate("status.backlog"),
      key: TaskStatusType.BACKLOG,
    },
    {
      id: "1",
      label: translate("status.toDo"),
      key: TaskStatusType.TO_DO,
    },
    {
      id: "2",
      label: translate("status.inProgress"),
      key: TaskStatusType.IN_PROGRESS,
    },
    {
      id: "3",
      label: translate("status.done"),
      key: TaskStatusType.DONE,
    },
  ];

  const dataItems = tasks.map((item: Task) => {
    const { creator, title, status, priority, dueDate } = item;
    return {
      id: item._id,
      creator,
      title,
      status,
      priority,
      dueDate,
    };
  });

  const _buildColumnHeader = ({ title, cards }: any): JSX.Element => {
    return (
      <Row justifyContent="space-between">
        <Row alignItems="center" justifyContent="flex-start">
          <Headline className="board-column-header mr-5">{title}</Headline>
          <Row
            justifyContent="center"
            alignItems="center"
            className="board-column-header-counter"
          >
            {cards.length}
          </Row>
        </Row>
        <Row alignItems="center" justifyContent="flex-end">
          <Row
            justifyContent="center"
            alignItems="center"
            className="board-column-header-icon"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Row>
        </Row>
      </Row>
    );
  };

  const _setTaskPriorityValue = (priority: string): any => {
    switch (priority) {
      case PriorityType.LOW:
        return translate("priority.low");
      case PriorityType.MEDIUM:
        return translate("priority.medium");
      case PriorityType.HIGH:
        return translate("priority.high");
    }
  };

  const _buildItem = ({
    id,
    creator,
    title,
    priority,
    dueDate,
  }: any): JSX.Element => {
    return (
      <Row
        key={id}
        alignItems="flex-end"
        className="task-card-container relative cursor-pointer"
      >
        <Column justifyContent="space-between" className="task-card-info">
          <Row className="task-card-title">{title}</Row>
          <Row justifyContent="space-between" alignItems="center">
            <Row className="task-card-date mr-10">
              {dueDate &&
                `${translate("board.dueDate")}: ${formatDate(dueDate)}`}
            </Row>
            <Row justifyContent="space-between" alignItems="center">
              <HighlightedInfoText
                variant="small"
                color={
                  priority === PriorityType.LOW
                    ? "success"
                    : priority === PriorityType.MEDIUM
                    ? "warning"
                    : "error"
                }
              >
                {_setTaskPriorityValue(priority)}
              </HighlightedInfoText>
              <Image
                className="ml-10 task-card-image"
                imageUrl={creator.profileImageUrl}
              />
            </Row>
          </Row>
        </Column>
      </Row>
    );
  };

  const handleBoardCardMove = (
    card: any,
    source: any,
    destination: any
  ): void => {
    const newCardColumn = columns.find(
      (item) => item.id === destination.toColumnId
    );
    const newCardStatus = newCardColumn?.key;

    taskStore?.updateTask({
      taskId: card.id,
      data: {
        status: newCardStatus,
      },
    });
  };

  return (
    <AbstractKanbanBoard
      className="task-board-container"
      columns={columns}
      items={dataItems as any}
      relateField="status"
      columnHeaderBuilder={_buildColumnHeader}
      itemBuilder={_buildItem}
      onItemMoveCallback={handleBoardCardMove}
      onClick={onClick}
    />
  );
};

export default inject("taskStore")(observer(TaskBoard));
