import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import customerIllustration from "assets/illustrations/placeholders/customer_placeholder.png";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { ModalStore } from "stores/modal.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useLocation } from "react-router";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { SearchTopic } from "stores/global.search.store";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import CustomerList from "./components/customer.list.component/customer.list.component";
import CustomerStore from "stores/customer.store";
import { Customer } from "schemas/customer.schemas/customer.schema";
import Column from "components/general.compoenents/column.component/column.component";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

interface CustomerOverviewPageProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
}

const CustomerOverviewPage = ({
  customerStore,
  modalStore,
}: CustomerOverviewPageProps): JSX.Element => {
  const location = useLocation();
  const avtiveTab = location.pathname.split("/").pop();

  const navigate = navigationHelper();
  const { t } = useTranslation();
  const translate = createTranslate(
    t,
    `customerOverviewPage.${avtiveTab}.overview`
  );

  const customers = customerStore?.customers;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    await customerStore?.fetchAndSetCustomers({});
  };

  const handleClickItem = (
    item: any,
    inventoryType: "members",
    isNew?: boolean
  ): void => {
    if (item?._id != null && !isNew) {
      navigate(`management/${inventoryType}/${item?._id}/info`);
    } else {
      navigate(`management/${inventoryType}/new/info`);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        actions={[
          <FilledButton
            icon={faSearch}
            key={"search-button"}
            className="mb-5"
            label="Search"
            onClick={() => {
              modalStore?.openGlobalSearchModal(SearchTopic.CUSTOMER);
            }}
          />,
        ]}
        tabs={[
          <Tab
            label={t("customerOverviewPage.members.overview.tabTitle")}
            key="members-tab"
            path="members"
            rootPath={"management"}
          />,
          <Tab
            label={"Groups"}
            key="groups-tab"
            path="groups"
            rootPath={"management"}
            disabled
          />,
          <Tab
            label={"Mitgliederfilterlisten"}
            key="members-filter-lists-tab"
            path="filter-lists"
            rootPath={"management"}
            disabled
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildExerciseList = (): JSX.Element => {
    return (
      <Column className="mt-15">
        <CustomerList
          customers={customers?.data ?? []}
          isLoading={customers?.isLoading}
          onClick={(customer: Customer) => {
            handleClickItem(customer, avtiveTab as any, false);
          }}
        />
      </Column>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            image={customerIllustration}
            title={translate("introTitle")}
            collapsedTitle={translate("collapsedIntroTitle")}
            description={translate("introDescription")}
            id="customer-overview"
            renderButtons={() => (
              <FilledButton
                onClick={() => {
                  handleClickItem(undefined, avtiveTab as "members", true);
                }}
                className="mt-10"
                label={translate("addButton")}
              />
            )}
          />

          {_buildTabs()}

          <Routes>
            <Route path="members" element={_buildExerciseList()} />
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "modalStore",
  "customerStore"
)(observer(CustomerOverviewPage));
