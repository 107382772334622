import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpAssetService } from "services/httpClients/http.asset.client";
import { Asset } from "schemas/asset.schemas/asset.schema";
import {
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { AssetType } from "globals/enums/global.enum";

class AssetStore {
  private stores: RootStore;

  // Properties
  private _assetsDataList: PaginationDataList<Asset> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentSelectedAssets?: Asset[] = [];

  private _currentAsset: DataItem<Asset> = {
    data: undefined,
    isLoading: false,
  };

  private _uploadedAssets?: Asset[] = [];

  private _selectedUploadedAssets?: Asset[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setAssets = (assets: Asset[]): void => {
    this._assetsDataList.data = assets;
  };

  setCurrentSelectedAssets = (assets: Asset[]): void => {
    this._currentSelectedAssets = assets;
  };

  setCurrentAsset = (asset: Asset): void => {
    this._currentAsset.data = asset;
  };

  setUploadedAssets = (assets: Asset[]): void => {
    this._uploadedAssets = assets;
  };

  setSelectedUploadedAssets = (assets: Asset[]): void => {
    this._selectedUploadedAssets = assets;
  };

  //! Getters
  get assets(): PaginationDataList<Asset> | undefined {
    if (this._assetsDataList == null) {
      return;
    }
    return this._assetsDataList;
  }

  get currentAsset(): DataItem<Asset> | undefined {
    if (this._currentAsset == null) {
      return;
    }
    return this._currentAsset;
  }

  get currentSelectedAssets(): Asset[] | undefined {
    if (this._currentSelectedAssets == null) {
      return;
    }
    return this._currentSelectedAssets;
  }

  get uploadedAssets(): Asset[] | undefined {
    if (this._uploadedAssets == null) {
      return;
    }
    return this._uploadedAssets;
  }

  get selectedUploadedAssets(): Asset[] | undefined {
    if (this._selectedUploadedAssets == null) {
      return;
    }
    return this._selectedUploadedAssets;
  }

  //! Methods
  fetchAndSetAssets = async (args: {
    isAll: boolean;
    type?: AssetType;
  }): Promise<void> => {
    try {
      if (this._assetsDataList.isLoading) {
        return;
      }

      const { isAll, type } = args;

      this._assetsDataList.isLoading = true;
      const assets = await HttpAssetService.getInstance().find({
        query: { isAll, type },
      });

      if (assets == null) {
        this._assetsDataList.isLoading = false;
        return;
      }

      this.setAssets(assets);

      this._assetsDataList.isLoading = false;
    } catch (err) {
      this._assetsDataList.isLoading = false;

      Logging.error({
        className: "AssetStore",
        methodName: "fetchAndSetAssets",
        message: "Could not get assets",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeAsset = async (args: {
    assetId: string;
    isAll: boolean;
  }): Promise<void> => {
    const { assetId, isAll } = args;

    try {
      await HttpAssetService.getInstance().deleteOne({ id: assetId });

      const assets = await HttpAssetService.getInstance().find({
        query: { isAll },
      });

      if (assets == null) {
        return;
      }
      this.setAssets(assets);
    } catch (err) {
      Logging.error({
        className: "AssetStore",
        methodName: "removeAsset",
        message: "Could not remove asset",
        exception: err,
        showAlert: true,
      });
    }
  };

  clearAllAssets = (): void => {
    this._assetsDataList.data = [];
    this._currentSelectedAssets = [];
    this._uploadedAssets = [];
    this._selectedUploadedAssets = [];
  };

  clearSelectedAssets = (): void => {
    this._currentSelectedAssets = [];
  };

  clearSelectedUploadedAssets = (): void => {
    this._selectedUploadedAssets = [];
  };
}

export default AssetStore;
