/* eslint-disable @typescript-eslint/no-misused-promises */
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import StudioStore from "stores/studio.store";
import FormItemWrapper from "components/general.compoenents/form.item.wrapper.component/form.item.wrapper.component";
import { studioSchema } from "schemas/studio.schemas/studio.schema";
import { useForm } from "react-hook-form";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Gleap from "gleap";
import "./studio.tab.page.scss";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import UserStore from "stores/user.store";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import { ModalStore } from "stores/modal.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface StudioTabPageProps {
  studioStore?: StudioStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
}

const StudioTabPage = ({
  studioStore,
  userStore,
  modalStore,
}: StudioTabPageProps): JSX.Element => {
  const { t } = useTranslation();

  const studio = studioStore?.studio;

  const verifyCodeAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_STUDIO_VERIFY_KEY",
  });

  const editStudioAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_EDIT_STUDIO_SETTINGS",
  });

  useEffect(() => {
    initializePage();
  }, [verifyCodeAccess]);

  const initializePage = async (): Promise<void> => {
    if (verifyCodeAccess) {
      await studioStore?.fetchAndSetStudioVerifyCode();
    }
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(studioSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: studio,
  });

  if (studio == null) {
    return <></>;
  }

  const onSubmit = async (data: any): Promise<void> => {
    await studioStore?.updateCurrentStudio(data);
  };

  const buildVerifyCodePreview = (): JSX.Element => {
    if (studioStore?.studio?.verificationCode == null) {
      return (
        <>
          <FilledButton
            label={t("studio.contactSupport")}
            onClick={() => {
              Gleap.open();
            }}
          />
          <RunningText className="mt-10">
            {t("studio.verifyCodeNotGenerated")}
          </RunningText>
        </>
      );
    }

    return (
      <Row justifyContent="flex-start" className="mt-15" alignItems="center">
        {studioStore?.studio?.verificationCode &&
          studioStore?.studio?.verificationCode
            ?.toString()
            .split("")
            .map((char, index) => {
              return (
                <div className="verification-code-preview mr-15" key={index}>
                  <SmallHeadline>{char}</SmallHeadline>
                </div>
              );
            })}
        <LinkButton
          label={t("studio.regenerateVerifyCode")}
          onClick={async () => {
            modalStore?.showConfirmAlert({
              title: t("studio.regenerateVerifyCode"),
              description: t("studio.regenerateVerifyCodeDescription"),
              confirmLabel: t("studio.generate"),
              onConfirm: async () => {
                await studioStore?.regenerateAndSetStudioVerifyCode();
              },
            });
          }}
        />
      </Row>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error(t("auth.formError"));
      })}
      className="studio-tab-page-wrapper"
    >
      <ComponentWrapper
        disabled={!editStudioAccess}
        outsideActions={
          <Row
            className="mb-20"
            justifyContent="space-between"
            alignItems="center"
          >
            <Headline>{t("studio.myStudio")}</Headline>
            <FilledButton
              label={t("auth.save")}
              type="submit"
              color="secondary"
              disabled={!isDirty}
            />
          </Row>
        }
      >
        <FormItemWrapper
          title={t("studio.name")}
          description={t("studio.nameDescription") ?? ""}
        >
          <OutlinedTextInput
            label={t("studio.studioName") ?? ""}
            inputRef={register("name")}
            inputRefValue={getValues("name")}
            validationMessage={errors.name?.message?.toString()}
          />
          <OutlinedTextInput
            className="mt-30"
            label={t("studio.addition") ?? ""}
            inputRef={register("nameAddition")}
            inputRefValue={getValues("nameAddition")}
            validationMessage={errors.nameAddition?.message?.toString()}
          />
        </FormItemWrapper>
        {verifyCodeAccess && (
          <FormItemWrapper
            title={t("studio.studioVerificationCode")}
            description={t("studio.studioVerificationCodeDescription") ?? ""}
          >
            {buildVerifyCodePreview()}
          </FormItemWrapper>
        )}
        <FormItemWrapper
          title={t("studio.deleteStudio")}
          description={t("studio.deleteStudioDescription") ?? ""}
        >
          <FilledButton
            color="danger"
            label={t("studio.deleteRequest")}
            onClick={() => {
              Gleap.open();
            }}
          />
        </FormItemWrapper>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "studioStore",
  "userStore",
  "modalStore"
)(observer(StudioTabPage));
