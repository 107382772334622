import MobileHeader from "components/navigation.components/mobile.header.component/mobile.header.component";
import AccountPage from "pages/account.page/account.page";
import SignInPage from "pages/auth.pages/sign.in.page/sign.in.page";
import CustomerPage from "pages/customer.pages/customer.overview.page/customer.overview.page";
import DashboardPage from "pages/dashboard.page/dashboard.page";
import ForgotPasswordPage from "pages/general.pages/forgot.password.page/forgot.password.page";
import HandleInvitationPage from "pages/general.pages/handle.invitation.page/handle.invitation.page";
import InvitationPage from "pages/general.pages/invitation.page/invitation.page";
import NotFoundPage from "pages/general.pages/not.found.page/not.found.page";
import PasswordResetPage from "pages/general.pages/password.reset.page/password.reset.page";
import LoadingPage from "pages/loading.page/loading.page";
import TrainingPlanDetailPage from "pages/training.plan.pages/training.plan.detail.page/training.plan.detail.page";
import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./routing.protected.config";
import CustomerDetailPage from "pages/customer.pages/customer.detail.page/customer.detail.page";
import ArchivePage from "pages/archive.page/archive.page";
import PropertyPage from "pages/property.pages/property.page/property.page";
import PropertyDetailPage from "pages/property.pages/property.detail.page/property.detail.page";
import MainModal from "components/modal.components/main.modal.component/main.modal.component";
import AppConfiguratorPage from "pages/studio.pages/app.configurator.page/app.configurator.page";
import AppSetupPage from "pages/setup.pages/app.setup.page/app.setup.page";
import UserSetupPage from "pages/setup.pages/user.setup.page/user.setup.page";
import GymoCustomerDetailPage from "pages/gymo.customer.pages/gymo.customer.detail.page/gymo.customer.detail.page";
import GymoCustomerPage from "pages/gymo.customer.pages/gymo.customer.page/gymo.customer.page";
import CanvasEditorPage from "pages/metool.pages/canvas.editor.page/canvas.editor.page";
import {
  coreQueryConfig,
  coreResources,
  layoutConfig,
} from "config/metool.config";
import {
  ContainerWidget,
  TableWidget,
  TextWidget,
  InitializeMetool,
  NavigationMenuWidget,
  DashboardPageLayout,
} from "@vondot-development/metool_lib";
import MarketingPage from "pages/marketing.pages/marketing.page/marketing.page";
import MarketingPushNotification from "pages/marketing.pages/marketing.detail.pages/marketing.push.notification.page/marketing.push.notification.page";
import MarketingEmailPage from "pages/marketing.pages/marketing.detail.pages/marketing.email.page/marketing.email.page";
import RenderPage from "pages/metool.pages/render.page/render.page";
import { CustomerDataFormWidgetConfig as CustomerDataWidget } from "components/metool.widgets/customer.data.form.widget/customer.data.form.widget.config";
import { BillingDataFormWidgetConfig as BillingDataWidget } from "components/metool.widgets/billing.data.form.widget/billing.data.form.widget.config";
import { CustomerTrainingDataFormWidgetConfig as CustomerTrainingDataWidget } from "components/metool.widgets/customer.training.data.form.widget/customer.training.data.form.widget.config";
import { PageIntroWidgetConfig as PageIntroWidget } from "components/metool.widgets/page.intro.widget/page.intro.widget.config";
import { BookingDataWidgetConfig as BookingDataWidget } from "components/metool.widgets/booking.data.table.widget/booking.data.widget.config";
import { OrderDataWidgetConfig as OrderDataWidget } from "components/metool.widgets/order.data.table.widget/order.data.table.widget.config";
import { CustomerInfoWidgetConfig as CustomerInfoWidget } from "components/metool.widgets/customer.info.widget/customer.info.widget.config";
import SignUpPage from "pages/auth.pages/sign.up.page/sign.up.page";
import LeadPage from "pages/lead.pages/lead.page";
import FeedConfigurationDetailPage from "pages/feed.pages/feed.detail.pages/feed.configuration.detail.page";
import FeedConfigOverviewPage from "pages/feed.pages/feed.config.overview.page/feed.config.overview.page";
import FeedContentDetailPage from "pages/feed.pages/feed.detail.pages/feed.content.detail.pages/feed.content.detail.page/feed.content.detail.page";
import TrainingPlanOverviewPage from "pages/training.plan.pages/training.plan.overview.page/training.plan.overview.page";
import ExerciseDetailPage from "pages/inventory.pages/exercise.detail.page/exercise.detail.page";
import MarketplaceOverviewPage from "pages/marketplace.pages/marketplace.overview.page/marketplace.overview.page";
import MarketplaceContentLibraryDetailPage from "pages/marketplace.pages/marketplace.detail.pages/marketplace.content.library.detail.page/marketplace.content.library.detail.page";
import InventoryOverviewPage from "pages/inventory.pages/inventory.overview.page/inventory.overview.page";
import TaskOverviewPage from "pages/task.pages/task.overview.page/task.overview.page";
import ComponentTestPage from "pages/test.component.page/test.component.page";
import ExampleComponentPage from "pages/test.component.page/example.component.page";

const Routing = (): JSX.Element => {
  useEffect(() => {
    InitializeMetool({
      coreQueryConfig,
      coreResources,
      gridLayoutConfig: layoutConfig,
      widgets: [
        TableWidget,
        ContainerWidget,
        TextWidget,
        NavigationMenuWidget,

        //! HARDCODED WIDGETS FOR FIBO
        CustomerDataWidget,
        BillingDataWidget,
        CustomerTrainingDataWidget,
        PageIntroWidget,
        BookingDataWidget,
        OrderDataWidget,
        CustomerInfoWidget,
      ],
      pageLayoutConfigs: [DashboardPageLayout],
      themeConfig: {
        colors: {
          colorBorder: "#babbbb",
          colorPrimary: "#2bbf8c",
          colorPrimaryHighlight: "#2bbf8c",
          colorPrimaryUltralight: "#e6f9f4",
          colorSecondary: "#000000",
          colorSecondaryHighlight: "#5c5c5c",
          colorBackground: "#ffffff",
          colorInverted: "#000000",
          colorSurface: "rgb(248, 250, 250)",
          colorSurfaceDark: "#f2f2f2",
          colorError: "#fd5d5d",
          colorSuccess: "rgb(92, 195, 122)",
          colorHover: "hsla(158, 66%, 51%, 0.1)",
          colorWarning: "#f8b400",
          colorDisabled: "#cacecd",
        },
      },
    });
  }, []);

  return (
    <BrowserRouter>
      <MainModal />
      <MobileHeader />
      <Routes>
        <Route path="/" element={<LoadingPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/invitation" element={<InvitationPage />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/sign-up" element={<SignUpPage />}></Route>

        <Route path="pages/:pageID/edit" element={<CanvasEditorPage />}></Route>

        <Route path="pages/:pageID" element={<RenderPage />}></Route>
      </Routes>

      <Routes>
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/handle-invitations"
            element={<HandleInvitationPage />}
          />
          <Route path="/user-setup" element={<UserSetupPage />} />
          <Route
            path="/studios/:studioID/app-setup"
            element={<AppSetupPage />}
          />
        </Route>

        <Route
          element={
            <div className="main-content">
              <ProtectedRoute />
            </div>
          }
        >
          <Route path="/studios/:studioID/*" element={<DashboardPage />}>
            <Route path="app/*" element={<AppConfiguratorPage />} />
            <Route path="feed" element={<FeedConfigOverviewPage />} />
            <Route
              path="feed/:feedConfigID/*"
              element={<FeedConfigurationDetailPage />}
            ></Route>
            <Route
              path="feed/:feedConfigID/content/:feedID"
              element={<FeedContentDetailPage />}
            />
            <Route
              path="training-plans"
              element={<TrainingPlanOverviewPage />}
            />
            <Route
              path="training-plans/:trainingPlanID/*"
              element={<TrainingPlanDetailPage />}
            />
            <Route path="inventory/*" element={<InventoryOverviewPage />} />
            <Route
              path="inventory/exercises/:exerciseID/*"
              element={<ExerciseDetailPage />}
            />

            <Route path="management/*" element={<CustomerPage />} />
            <Route
              path="management/members/:customerID/*"
              element={<CustomerDetailPage />}
            />

            <Route path="leads" element={<LeadPage />} />

            <Route path="tasks" element={<TaskOverviewPage />} />

            <Route
              path="test/components"
              element={<ComponentTestPage />}
            ></Route>
            <Route
              path="example-components"
              element={<ExampleComponentPage />}
            ></Route>

            <Route path="properties" element={<PropertyPage />}>
              <Route path=":propertyID/*" element={<PropertyDetailPage />} />
            </Route>
            <Route path="marketing/*" element={<MarketingPage />}>
              <Route
                path="push-notifications/*"
                element={<MarketingPushNotification />}
              />
              <Route path="emails/*" element={<MarketingEmailPage />} />
            </Route>
            <Route path="archive/*" element={<ArchivePage />} />
            <Route path="account/*" element={<AccountPage />} />

            <Route path="gymo-customers" element={<GymoCustomerPage />}>
              <Route
                path=":gymoCustomerID/*"
                element={<GymoCustomerDetailPage />}
              />
            </Route>

            <Route path="marketplace/*" element={<MarketplaceOverviewPage />} />

            {/* DETAIL PAGES */}
            <Route
              path="marketplace/content/:contentLibraryID"
              element={<MarketplaceContentLibraryDetailPage />}
            />

            {/* Fallback route */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
