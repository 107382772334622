import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronUp,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { useClickedOutside } from "globals/helpers/hook.helper";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import "./select.dropdown.list.component.scss";

type DropdownPlacements = "left" | "right";

export interface SelectItem {
  label: string;
  value: string;
}
interface SelectDropDownListProps {
  options: SelectItem[];
  initialSelectedOptions?: SelectItem[];
  placement?: DropdownPlacements;
  selectDropDownListButtonLabel: string;
  selectDropDownListButton: (props: any) => JSX.Element;
  onClose: () => Promise<void>;
  onSelect: (values: SelectItem[]) => void;
}

const SelectDropDownList = ({
  options,
  initialSelectedOptions,
  placement = "right",
  selectDropDownListButtonLabel: ButtonLabel,
  selectDropDownListButton: Button,
  onClose,
  onSelect,
}: SelectDropDownListProps): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectItem[]>(
    initialSelectedOptions ?? []
  );

  const buttonLabelWithQuantity = useMemo(
    () =>
      `${ButtonLabel} ${
        !isOpened && selectedOptions.length > 0
          ? `(${selectedOptions.length})`
          : ""
      }`,
    [isOpened, selectedOptions, ButtonLabel]
  );

  const selectDropDownListMenuWrapperClasses = classNames({
    "select-dropdown-list-menu-wrapper": true,
    "left-sided": placement === "left",
    "right-sided": placement === "right",
  });

  const selectDropDownListMenuButtonClasses = classNames({
    "select-dropdown-list-button": true,
    "font-weight-bold": true,
    "select-dropdown-list-button-opened": isOpened,
  });

  const handleToggleDropdown = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleCloseDropDown = useCallback(async () => {
    await onClose();
    setIsOpened(false);
  }, [onClose]);

  const onItemClicked = useCallback(
    (option: SelectItem) => {
      let newSelectedOptions = [...selectedOptions];
      const isAlreadySelected = selectedOptions.find(
        (selectedOption) => selectedOption.value === option.value
      );

      if (isAlreadySelected) {
        newSelectedOptions = selectedOptions.filter(
          (selectedOption) => selectedOption.value !== option.value
        );
      } else {
        newSelectedOptions.push(option);
      }

      onSelect([...newSelectedOptions]);
      setSelectedOptions([...newSelectedOptions]);
    },
    [selectedOptions]
  );

  useClickedOutside(dropdownRef, () => {
    if (!isOpened) {
      return;
    }

    setIsOpened(false);
  });

  return (
    <div className="select-dropdown-list" ref={dropdownRef}>
      <div className="select-dropdown-list-buttons-wrapper">
        <Button
          label={buttonLabelWithQuantity}
          className={selectDropDownListMenuButtonClasses}
          icon={isOpened && faChevronUp}
          isIconBehind={isOpened}
          onClick={() => {
            handleToggleDropdown();
          }}
        />
        <IconButton
          className="mr-10"
          icon={faXmark}
          onClick={handleCloseDropDown}
        />
      </div>
      {isOpened && (
        <div className={selectDropDownListMenuWrapperClasses}>
          <ul className="select-dropdown-list-menu">
            {options.map((option) => {
              const isSelected = selectedOptions.find(
                (selectedOption) => selectedOption.value === option.value
              );

              return (
                <li
                  key={option.value}
                  className={`select-dropdown-list-menu-item ${
                    isSelected ? "select-dropdown-list-menu-item-selected" : ""
                  }`}
                  onClick={() => {
                    onItemClicked(option);
                  }}
                >
                  {option.label}
                  {isSelected && <FontAwesomeIcon icon={faCheck} />}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectDropDownList;
