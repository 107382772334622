import React, { useState, useEffect, InputHTMLAttributes } from "react";
import classNames from "classnames";
import "./unit.input.component.scss";
import Select from "react-select";

export enum UNIT_TYPE {
  WEIGHT = "WEIGHT",
  LENGTH = "LENGTH",
}

const UNIT_VALUES = {
  WEIGHT: ["g", "kg", "tonna", "mmm"],
  LENGTH: ["mm", "cm", "m", "km"],
};

interface UnitInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  readOnly?: boolean;
  unitType: UNIT_TYPE;
  unitValue?: string;
  value?: string | number | undefined;
  initialValue?: string | number;
  validationMessage?: string;
  onChange?: (value: any) => void;
  onUnitChange?: (value: any) => void;
  autoFocus?: boolean;
  maxMenuHeight?: number;
}

const UnitInput = ({
  className,
  label,
  readOnly = false,
  unitType,
  unitValue,
  value,
  initialValue,
  validationMessage,
  onChange,
  onUnitChange,
  autoFocus,
  maxMenuHeight,
  ...props
}: UnitInputProps): JSX.Element => {
  const { disabled, required } = props;
  const [currentValue, setCurrentValue] = useState(null as any);
  const [currentUnitValue, setCurrentUnitValue] = useState(null as any);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }

    if (unitValue) {
      setCurrentUnitValue({
        label: unitValue,
        value: unitValue,
      });
    }
  }, [value, unitValue]);

  const handleValueChange = (e: any): void => {
    if (onChange) {
      onChange({
        value: e.target.value,
        unit: currentUnitValue?.value,
      });
    }
    setCurrentValue(e.target.value);
  };

  const handleUnitChange = (option: any): void => {
    if (onChange) {
      onChange({
        value: currentValue,
        unit: option.value,
      });
    }
    setCurrentUnitValue({
      value: option.value,
      label: option.label.substring(0, 3),
    });
  };

  const getOptions = (
    unitType: UNIT_TYPE
  ): Array<{ label: string; value: string }> => {
    const options = UNIT_VALUES[unitType].map((item: string) => {
      return { label: item, value: item };
    });
    return options;
  };

  const inputContainerClassName = classNames(
    {
      "unit-input-container": true,
      "unit-input-container--error": validationMessage != null,
      "unit-input-container--disabled": disabled,
      "unit-input-container--readonly": readOnly,
    },
    className
  );

  const inputFieldClassName = classNames({
    "unit-input-field": true,
    "unit-input-field--disabled": disabled,
    "unit-input-field--readonly": readOnly,
  });

  const inputLabelClassName = classNames({
    "unit-input-label": label != null,
    "unit-input-label--disabled": disabled,
    "unit-input-label--readonly": readOnly,
    "unit-input-label--on-top": currentValue ?? initialValue,
    "unit-input-label--error": validationMessage != null,
  });

  const inputRequiredClassName = classNames({
    "required-field": true,
    "required-field--error": validationMessage != null,
  });

  const reactSelectContainerClassName = classNames({
    "unit-select-container": true,
    "unit-select-container--disabled": disabled,
    "unit-select-container--readonly": readOnly,
  });

  return (
    <div className={inputContainerClassName}>
      <input
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        autoFocus={autoFocus}
        className={inputFieldClassName}
        value={currentValue}
        defaultValue={initialValue}
        onChange={handleValueChange}
      />
      {label != null && (
        <label aria-required className={inputLabelClassName}>
          {validationMessage ?? label}
          {required && <span className={inputRequiredClassName}> *</span>}
        </label>
      )}
      <Select
        menuPlacement={"bottom"}
        className={reactSelectContainerClassName}
        openMenuOnClick={!readOnly}
        openMenuOnFocus={!readOnly}
        classNamePrefix="unit-select-dropdown"
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={global ? document.body : null}
        styles={{
          menuPortal: (base) => ({
            ...base,
            offset: 3,
            zIndex: 9999,
            marginTop: "4px",
          }),
        }}
        hideSelectedOptions={false} // show all options on multi selection mode
        value={currentUnitValue ?? null}
        onKeyDown={(e) => {
          if (readOnly) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={(e) => {
          if (readOnly) e.preventDefault();
        }}
        onChange={(value) => handleUnitChange(value)}
        options={getOptions(unitType)}
        isDisabled={disabled ?? readOnly}
        placeholder=""
      />
    </div>
  );
};

export default UnitInput;
