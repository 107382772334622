import React from "react";
import "./training.plan.exercise.list.item.component.scss";
import { faTrash, faBars } from "@fortawesome/pro-regular-svg-icons";
import CollapsibleComponent from "components/general.compoenents/collapsible.component/collapsible.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { ContainerSizes, ImageSizes } from "globals/enums/global.enum";
import Image from "components/image.component/image.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PlanExercise,
  PlanExerciseType,
  WorkoutMode,
} from "schemas/training.plan.schemas/plan.exercise.schema";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import InputSlider from "components/input.components/slider.input.component/slider.input.component";
import classNames from "classnames";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";

const formatDuration = (milliseconds?: any): string => {
  if (!milliseconds) {
    return "0:00min";
  }

  // Convert milliseconds to seconds
  const totalSeconds = Math.floor(milliseconds / 1000);
  // Calculate minutes and seconds
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  // Format the seconds with leading zero if less than 10
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  // Return the formatted time string
  return `${minutes}:${formattedSeconds}min`;
};

const minutesToMilliseconds = (minutes: number): number => {
  return minutes * 60000;
};

const exerciseTypes = [
  { label: "HIIT", value: PlanExerciseType.HIIT },
  { label: "Cardio", value: PlanExerciseType.CARDIO },
];

interface TrainingPlanExerciseListItemProps {
  formMethods: UseFormReturn<TrainingPlan>;
  planExercise: PlanExercise;
  exerciseIndex: number;
  onOpening?: () => void;
  isOpened?: boolean;
  onRemoveExercise: (index: number) => void;
}

const TrainingPlanExerciseListItem = ({
  formMethods,
  planExercise,
  exerciseIndex,
  onOpening,
  isOpened,
  onRemoveExercise,
}: TrainingPlanExerciseListItemProps): JSX.Element => {
  const { register, formState, control, setValue, watch, getValues } =
    formMethods;
  const { errors } = formState;

  const watchedWorkoutMode = watch(`exercises.${exerciseIndex}.workoutMode`);
  const watchedTrainingDuration = watch(
    `exercises.${exerciseIndex}.timeSettings.trainingDurationInMilliseconds`
  );
  const watchedCountdown = watch(
    `exercises.${exerciseIndex}.timeSettings.countdownInMilliseconds`
  );
  const watchedPauseDuration = watch(
    `exercises.${exerciseIndex}.timeSettings.pauseInMilliseconds`
  );
  const watchedRepetitions = watch(
    `exercises.${exerciseIndex}.timeSettings.repetitions`
  );
  const watchedEffortLevel = watch(
    `exercises.${exerciseIndex}.timeSettings.effortLevel`
  );
  const watchedExerciseType = watch(`exercises.${exerciseIndex}.exerciseType`);

  const setsMethods = useFieldArray<any>({
    control,
    name: `exercises.${exerciseIndex}.sets`,
  });

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const onRemoveSet = (setIndex: number): void => {
    setsMethods.remove(setIndex);
  };

  const onAddSet = (): void => {
    // get the exercise with the index
    const exercise: PlanExercise = getValues().exercises[exerciseIndex];
    let newSet = {
      reps: 3,
      weight: 0,
      set: exercise.sets.length + 1,
    };

    // if exerxise has copy the last set
    // otherwise create a new set with default values
    if (exercise.sets.length > 0) {
      const lastSet = exercise.sets[exercise.sets.length - 1];

      newSet = {
        reps: lastSet.reps,
        weight: lastSet.weight,
        set: exercise.sets.length + 1,
      };
    }

    setsMethods.append(newSet);
  };

  const _buildTrainingPlanTypeItem = (
    label: string,
    value: WorkoutMode
  ): JSX.Element => {
    const trainingTypeItemClassName = classNames("training-type-toggle-item", {
      "training-type-toggle-item--selected": watchedWorkoutMode === value,
    });

    return (
      <div
        className={trainingTypeItemClassName}
        onClick={() => {
          setValueAndMarkDirty(`exercises.${exerciseIndex}.workoutMode`, value);
        }}
      >
        <TitleText className="bold">{label}</TitleText>
      </div>
    );
  };

  const _buildTrainingType = (): JSX.Element => {
    return (
      <Row className="training-type-toggle">
        {_buildTrainingPlanTypeItem("Wiederholung", WorkoutMode.REPS)}
        {_buildTrainingPlanTypeItem("Zeit", WorkoutMode.TIME)}
      </Row>
    );
  };

  const _buildTypeRepBased = (): JSX.Element => {
    return (
      <Column alignItems="center">
        {setsMethods.fields.map((set, index) => {
          return (
            <SizedContainer key={`$set-item-${index}`} size={ContainerSizes.L}>
              <Row alignItems="center">
                <div className="exercise-index-label">
                  <TitleText
                    className={index !== 0 ? "mr-10" : "mr-10 mt-25"}
                  >{`${index + 1}`}</TitleText>
                </div>
                <Column className="mr-15">
                  {index === 0 && <TitleText>Wiederholung</TitleText>}
                  <OutlinedTextInput
                    type="number"
                    inputRef={register(
                      `exercises.${exerciseIndex}.sets.${index}.reps`
                    )}
                    inputRefValue={getValues(
                      `exercises.${exerciseIndex}.sets.${index}.reps`
                    )}
                    validationMessage={errors?.exercises?.[
                      exerciseIndex
                    ]?.sets?.[index]?.reps?.message?.toString()}
                  />
                </Column>

                <Column>
                  {index === 0 && <TitleText>Kilogram</TitleText>}
                  <OutlinedTextInput
                    type="number"
                    allowDecimalNumbers
                    inputRef={register(
                      `exercises.${exerciseIndex}.sets.${index}.weight`
                    )}
                    inputRefValue={getValues(
                      `exercises.${exerciseIndex}.sets.${index}.weight`
                    )}
                    validationMessage={errors?.exercises?.[
                      exerciseIndex
                    ]?.sets?.[index]?.weight?.message?.toString()}
                  />
                </Column>
                <div className="trash-icon">
                  {setsMethods.fields.length > 1 ? (
                    <IconButton
                      icon={faTrash}
                      className={index === 0 ? "mt-30" : ""}
                      onClick={() => {
                        onRemoveSet(index);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </SizedContainer>
          );
        })}
        <LinkButton
          disabled={setsMethods.fields.length >= 9}
          label="Satz hinzufügen"
          onClick={() => {
            onAddSet();
          }}
        />
      </Column>
    );
  };

  const _buildTypeTimeBased = (): JSX.Element => {
    return (
      <Column>
        <SelectDropDown
          className="mb-15"
          label="Übungsart"
          inputRef={register(`exercises.${exerciseIndex}.exerciseType`)}
          items={exerciseTypes}
          labelPropertyName="label"
          valuePropertyName="value"
          selectedItem={exerciseTypes.find(
            (item) => item.value === watchedExerciseType
          )}
          onChange={(exerciseType) => {
            if (!exerciseType?.value) return;

            setValueAndMarkDirty(
              `exercises.${exerciseIndex}.exerciseType`,
              exerciseType.value
            );
          }}
        />
        <InputSlider
          className="mb-10"
          label="Countdown"
          minValue={minutesToMilliseconds(0)}
          maxValue={minutesToMilliseconds(5)}
          step={5000}
          formattedValue={formatDuration(watchedCountdown)}
          value={watchedCountdown ?? 0}
          onChange={(value) => {
            setValueAndMarkDirty(
              `exercises.${exerciseIndex}.timeSettings.countdownInMilliseconds`,
              value as number
            );
          }}
        />
        <InputSlider
          className="mb-10"
          label="Training"
          minValue={minutesToMilliseconds(0)}
          maxValue={minutesToMilliseconds(15)}
          step={5000}
          formattedValue={formatDuration(watchedTrainingDuration)}
          value={watchedTrainingDuration ?? 0}
          onChange={(value) => {
            setValueAndMarkDirty(
              `exercises.${exerciseIndex}.timeSettings.trainingDurationInMilliseconds`,
              value as number
            );
          }}
        />
        {watchedExerciseType === PlanExerciseType.HIIT && (
          <InputSlider
            className="mb-10"
            label="Pause"
            minValue={minutesToMilliseconds(0)}
            maxValue={minutesToMilliseconds(10)}
            step={5000}
            formattedValue={formatDuration(watchedPauseDuration)}
            value={watchedPauseDuration ?? 0}
            onChange={(value) => {
              setValueAndMarkDirty(
                `exercises.${exerciseIndex}.timeSettings.pauseInMilliseconds`,
                value as number
              );
            }}
          />
        )}
        {watchedExerciseType === PlanExerciseType.HIIT && (
          <InputSlider
            className="mb-10"
            label="Wiederholungen"
            minValue={1}
            maxValue={50}
            step={1}
            formattedValue={watchedRepetitions?.toString()}
            value={watchedRepetitions ?? 1}
            onChange={(value) => {
              setValueAndMarkDirty(
                `exercises.${exerciseIndex}.timeSettings.repetitions`,
                value as number
              );
            }}
          />
        )}
        <InputSlider
          label="Stufe, Widerstand, Gewicht"
          minValue={1}
          maxValue={100}
          step={1}
          formattedValue={watchedEffortLevel?.toString()}
          value={watchedEffortLevel ?? 1}
          onChange={(value) => {
            setValueAndMarkDirty(
              `exercises.${exerciseIndex}.timeSettings.effortLevel`,
              value as number
            );
          }}
        />
      </Column>
    );
  };

  return (
    <CollapsibleComponent
      key={planExercise._id!}
      isOpened={isOpened}
      onOpening={onOpening}
      trigger={
        <Row alignItems="center">
          <FontAwesomeIcon icon={faBars} className="cursor-pointer mr-15" />
          <Image
            imageUrl={planExercise.exercise.coverImageUrl}
            size={ImageSizes.M}
          />
          <Column className="ml-15">
            <TitleText className="bold">
              {planExercise.exercise.title.de}
            </TitleText>
            <RunningText>
              {planExercise?.exercise?.muscleGroups?.agonist?.map(
                (muscleGroup, index) =>
                  `${muscleGroup.title.de}${
                    index !==
                    planExercise?.exercise?.muscleGroups?.agonist?.length - 1
                      ? ", "
                      : ""
                  }`
              )}
            </RunningText>
          </Column>

          <IconButton
            icon={faTrash}
            className="mr-15"
            onClick={() => {
              onRemoveExercise?.(exerciseIndex);
            }}
          />
        </Row>
      }
      transitionTime={200}
      overflowWhenOpen="initial"
      className="training-plan-exercise-item"
      openedClassName="training-plan-exercise-item-opened"
    >
      <Row className="mobile-row-break mt-15" gap={15}>
        <Column className="training-plan-exercise-item-content">
          {_buildTrainingType()}
          <Column className="training-plan-exercise-item-content-main">
            {watchedWorkoutMode === WorkoutMode.REPS && _buildTypeRepBased()}
            {watchedWorkoutMode === WorkoutMode.TIME && _buildTypeTimeBased()}
          </Column>
        </Column>
        <Column justifyContent="flex-start" alignItems="flex-start">
          <FormWrapper title="Notiz" className="full-height">
            <OutlinedTextArea
              rows={4}
              inputRef={register(`exercises.${exerciseIndex}.info`)}
              validationMessage={formState.errors?.exercises?.[
                exerciseIndex
              ]?.info?.message?.toString()}
            />
          </FormWrapper>
        </Column>
      </Row>
    </CollapsibleComponent>
  );
};

export default TrainingPlanExerciseListItem;
