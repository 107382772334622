import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpTaskService } from "services/httpClients/http.task.client";
import { Task } from "schemas/task.schemas/task.schema";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";

class TaskStore {
  private stores: RootStore;

  // Properties
  private _tasksDataList: PaginationDataList<Task> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentTask: DataItem<Task> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setTasks = (tasks: Task[]): void => {
    this._tasksDataList.data = tasks;
  };

  setCurrentTask = (task: Task | undefined): void => {
    this._currentTask.data = task;
  };

  //! Getters
  get tasks(): PaginationDataList<Task> | undefined {
    if (this._tasksDataList == null) {
      return;
    }
    return this._tasksDataList;
  }

  get currentTask(): DataItem<Task> | undefined {
    if (this._currentTask == null) {
      return;
    }
    return this._currentTask;
  }

  //! Methods
  fetchAndSetTasks = async (args: { filter?: any }): Promise<void> => {
    const { filter } = args;

    try {
      if (this._tasksDataList.isLoading) {
        return;
      }

      this._tasksDataList.isLoading = true;

      const tasks = await HttpTaskService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._tasksDataList.pageIndex,
            itemsInPage: this._tasksDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (tasks == null) {
        this._tasksDataList.isLoading = false;
        return;
      }

      this.setTasks(tasks);

      this._tasksDataList.isLoading = false;
    } catch (err) {
      this._tasksDataList.isLoading = false;

      Logging.error({
        className: "TaskStore",
        methodName: "fetchAndSetTasks",
        message: "Could not get tasks",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeTask = async (args: {
    taskId: string;
    filter?: any;
  }): Promise<void> => {
    const { taskId, filter } = args;

    try {
      await HttpTaskService.getInstance().archiveOne({ id: taskId });

      const tasks = await HttpTaskService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._tasksDataList.pageIndex,
            itemsInPage: this._tasksDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (tasks == null) {
        return;
      }

      this.setTasks(tasks);
    } catch (err) {
      Logging.error({
        className: "TaskStore",
        methodName: "removeTask",
        message: "Could not remove task",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateTask = async (args: {
    taskId: string;
    data: any;
    filter?: any;
  }): Promise<void> => {
    const { taskId, data, filter } = args;

    try {
      await HttpTaskService.getInstance().updateOne({
        id: taskId,
        data,
        query: filter,
      });

      const tasks = await HttpTaskService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._tasksDataList.pageIndex,
            itemsInPage: this._tasksDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (tasks == null) {
        return;
      }

      this.setTasks(tasks);
    } catch (err) {
      Logging.error({
        className: "TaskStore",
        methodName: "updateTask",
        message: "Could not update task",
        exception: err,
        showAlert: true,
      });
    }
  };

  createTask = async (args: { data: any; filter?: any }): Promise<void> => {
    const { data, filter } = args;

    try {
      await HttpTaskService.getInstance().create({
        data,
        query: filter,
      });

      const tasks = await HttpTaskService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._tasksDataList.pageIndex,
            itemsInPage: this._tasksDataList.itemsInPage,
          }),
          ...filter,
        },
      });

      if (tasks == null) {
        return;
      }

      this.setTasks(tasks);
    } catch (err) {
      Logging.error({
        className: "TaskStore",
        methodName: "createTask",
        message: "Could not create task",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default TaskStore;
