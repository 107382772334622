import React, { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { inject, observer } from "mobx-react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import {
  DataAttribute,
  dataAttributeSchema,
} from "schemas/data.attribute.schemas/data.attribute.schema";
import DataAttributeStore from "stores/data.attribute.store";
import { ModalStore } from "stores/modal.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import {
  dataAttributeTypeOptions,
  dataAttributeUploadRestrictionsOptions,
  validationPatternOptions,
} from "globals/data/globals.data";
import DataAttributeCollectionStore from "stores/data.attribute.collection.store";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { HttpDataAttributeService } from "services/httpClients/http.data.attribute.client";

interface DataAttributeFormModalProps {
  modalStore?: ModalStore;
  dataAttributeStore?: DataAttributeStore;
  dataAttributeCollectionStore?: DataAttributeCollectionStore;
}

const DataAttributeFormModal = ({
  modalStore,
  dataAttributeStore,
  dataAttributeCollectionStore: collectionStore,
}: DataAttributeFormModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(
    t,
    "accountPage.dataAttributeTab.attributes.modal"
  );

  const [type, setType] = React.useState<string>("string");

  const isEditing = modalStore?.customData?.isEditing;
  const currentDataAttribute = dataAttributeStore?.currentDataAttribute?.data;
  const collections = collectionStore?.dataAttributeCollections?.data;

  const {
    register,
    setValue,
    getValues,
    setError,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<DataAttribute>({
    resolver: yupResolver(dataAttributeSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: {
      dataAttributeType: type,
    },
    defaultValues: dataAttributeStore?.currentDataAttribute?.data ?? {},
  });

  //! Watchers

  const collectionWatch = useWatch({
    control,
    name: "collection",
    defaultValue: dataAttributeStore?.currentDataAttribute?.data?.collection,
  });

  const referenceCollections = useMemo(() => {
    return collections?.filter(
      (collection) => collection._id !== collectionWatch?._id
    );
  }, [collectionWatch]);

  const dataAttributeTypeWatch = useWatch({
    control,
    name: "dataAttributeType",
    defaultValue:
      dataAttributeStore?.currentDataAttribute?.data?.dataAttributeType,
  });

  //! Effects

  useEffect(() => {
    if (dataAttributeStore?.currentDataAttribute?.data) {
      const type = getValues("dataAttributeType");
      setType(type);
    }
  }, [dataAttributeTypeWatch]);

  //! Functions

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const containError = (path: string): string | undefined => {
    const pathArray = path.split(".");
    let currentErrors: any = errors;

    for (const segment of pathArray) {
      currentErrors = currentErrors[segment as any];
      if (!currentErrors) return undefined;
    }

    return currentErrors.message?.toString();
  };

  const onSubmit = async (data: any): Promise<void> => {
    if (!isEditing) {
      // check if fieldID is already in use for current collection
      const fieldIdExists =
        await HttpDataAttributeService.getInstance().checkIfFieldIdExists({
          fieldID: data?.fieldID,
          collectionID: data.collection?._id,
        });

      // show error and exit if fieldID already exists
      if (fieldIdExists) {
        toast.error(translate("fieldIDAlreadyExists"));
        setError("fieldID", {
          type: "manual",
          message: translate("fieldIDAlreadyExists"),
        });
        return;
      }

      await dataAttributeStore?.createDataAttribute({
        dataAttribute: data,
      });
    } else {
      await dataAttributeStore?.updateDataAttribute({ dataAttribute: data });
    }

    // close modal and reset current data attribute
    modalStore?.closeModal();
    dataAttributeStore?.setCurrentDataAttribute(undefined);
    collectionStore?.setCurrentDataAttributeCollection(data?.collection);
  };

  return (
    <ModalHeader
      title={translate(`headline${isEditing ? "Edit" : "New"}`)}
      description={translate(`description${isEditing ? "Edit" : "New"}`)}
    >
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          toast.error(translate("invalidForm"));
        })}
      >
        <SelectDropDown
          label={translate("collection")}
          valuePropertyName="_id"
          labelPropertyName="title"
          selectedItem={currentDataAttribute?.collection}
          items={collections ?? []}
          validationMessage={errors.collection?.message?.toString()}
          disabled={isEditing}
          onChange={(item) => {
            if (item != null) {
              setValueAndMarkDirty("collection", item);
              setValueAndMarkDirty("referenceTo", null);
            }
          }}
        />

        <SelectDropDown
          label={translate("type")}
          selectedItem={dataAttributeTypeOptions.find(
            (item) => item.value === currentDataAttribute?.dataAttributeType
          )}
          items={dataAttributeTypeOptions ?? []}
          // inputRef={register("dataAttributeType")}
          validationMessage={errors.dataAttributeType?.message?.toString()}
          onChange={(item) => {
            if (item?.value != null) {
              setValueAndMarkDirty("dataAttributeType", item?.value);

              // reset validation if dataAttributeType is not string
              if (item?.value !== "string") {
                setValueAndMarkDirty("attributeSettings.validation", null);
              }

              // reset restrictions if dataAttributeType is not upload
              if (item?.value !== "upload") {
                setValueAndMarkDirty("attributeSettings.restrictions", null);
              }

              // if the type is boolean, set required to false
              if (item?.value === "boolean") {
                setValueAndMarkDirty("required", false);
              }
            }
          }}
        />

        {/* If the dataAttributeType is obect or array, show the referenceTo dropdown */}
        {(dataAttributeTypeWatch === "object" ||
          dataAttributeTypeWatch === "array") && (
          <SelectDropDown
            key={`referenceTo-${getValues("collection._id")}`}
            label={translate("referenceTo")}
            selectedItem={
              referenceCollections?.find(
                (item) =>
                  item?._id ===
                  (currentDataAttribute?.attributeSettings as any)?.referenceTo
              ) ?? null
            }
            valuePropertyName="_id"
            labelPropertyName="title"
            items={referenceCollections ?? []}
            validationMessage={containError("attributeSettings.referenceTo")}
            onChange={(item) => {
              if (item != null) {
                setValueAndMarkDirty("attributeSettings.referenceTo", item);
              }
            }}
          />
        )}

        {/* If the dataAttributeType is string, show the validation pattern dropdown */}
        {dataAttributeTypeWatch === "string" && (
          <SelectDropDown
            label={translate("validation")}
            selectedItem={validationPatternOptions.find(
              (item) =>
                item.value ===
                (currentDataAttribute?.attributeSettings as any)?.validation
            )}
            items={validationPatternOptions ?? []}
            validationMessage={containError("attributeSettings.validation")}
            onChange={(item) => {
              if (item != null) {
                setValueAndMarkDirty(
                  "attributeSettings.validation",
                  item?.value
                );
              }
            }}
          />
        )}

        {/* If the dataAttributeType is upload, show the restrictions dropdown */}
        {dataAttributeTypeWatch === "upload" && (
          <SelectDropDown
            label={translate("restrictions")}
            selectedItem={dataAttributeUploadRestrictionsOptions.find(
              (item) =>
                item.value ===
                (currentDataAttribute?.attributeSettings as any)?.restrictions
            )}
            items={dataAttributeUploadRestrictionsOptions ?? []}
            inputRef={register("attributeSettings.restrictions")}
            validationMessage={containError("attributeSettings.restrictions")}
            onChange={(item) => {
              if (item != null) {
                setValueAndMarkDirty(
                  "attributeSettings.restrictions",
                  item?.value
                );
              }
            }}
          />
        )}

        <OutlinedTextInput
          label={translate("label")}
          inputRef={register("label")}
          inputRefValue={getValues("label")}
          validationMessage={errors.label?.message}
          className="mb-25 mt-25"
        />

        <OutlinedTextInput
          label={translate("fieldID")}
          inputRef={register("fieldID")}
          inputRefValue={getValues("fieldID")}
          validationMessage={errors.fieldID?.message}
          className="mb-25"
          disabled={isEditing}
        />

        <OutlinedTextInput
          label={translate("placeholder")}
          inputRef={register("placeholder")}
          inputRefValue={getValues("placeholder")}
          validationMessage={errors.placeholder?.message}
          className="mb-25"
        />

        {(dataAttributeTypeWatch === "array" ||
          dataAttributeTypeWatch === "object") && (
          <FilledCheckbox
            {...register("attributeSettings.showInNewTab")}
            label={translate("showInNewTab")}
            className="mb-25"
          />
        )}

        {dataAttributeTypeWatch !== "boolean" && (
          <FilledCheckbox
            {...register("required")}
            label={translate("required")}
            className="mb-25"
          />
        )}

        <FilledCheckbox
          {...register("readOnly")}
          label={translate("readOnly")}
          className="mb-25"
        />

        <FilledButton
          disabled={!isDirty}
          label={translate("saveButton")}
          type="submit"
          color="secondary"
        />
      </form>
    </ModalHeader>
  );
};

export default inject(
  "dataAttributeStore",
  "dataAttributeCollectionStore",
  "modalStore"
)(observer(DataAttributeFormModal));
