import React, { useState } from "react";
import ExerciseStore from "stores/exercise.store";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import {
  CollectionDataType,
  ContainerSizes,
  PropertyType,
} from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useFormContext } from "react-hook-form";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { difficultyLevelOptions } from "globals/data/globals.data";
import PropertyStore from "stores/property.store";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import StudioStore from "stores/studio.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { ModalStore } from "stores/modal.store";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import { createTranslate } from "globals/helpers/global.helper";
import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface ExerciseFormProps {
  isEditing?: boolean;
  propertyStore?: PropertyStore;
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  isUploading: (isUploading: boolean) => void;
}

const ExerciseForm = ({
  isEditing = false,
  propertyStore,
  exerciseStore,
  studioStore,
  modalStore,
  isUploading: isUploadingCallback,
}: ExerciseFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "exerciseDetailPage.form");
  const navigate = navigationHelper();

  const exercise = exerciseStore?.currentExercise?.data;
  const currentLanguage = studioStore?.currentLanguage;
  const muscleGroupProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.MUSCLE_GROUP
  );
  const exerciseProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.EXERCISE_PROPERTY
  );

  const [isUploading, setIsUploading] = useState(false);
  const isTemplate = exercise?.type === CollectionDataType.TEMPLATE;

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  if (exercise == null || currentLanguage == null) {
    return <></>;
  }

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  // handle exercise  form submit
  const onSubmit = async (data: any): Promise<void> => {
    exerciseStore?.setCurrentExercise({
      ...exercise,
      ...data,
    });

    if (!isEditing) {
      // create exercise
      const createdExercise = await exerciseStore?.createExercise(data);

      if (createdExercise?._id == null) return;

      // navigate to created exercise
      navigate(`inventory/exercises/${createdExercise._id}/info`);
    } else {
      if (exercise?._id == null) return;

      // update exercise
      await exerciseStore?.updateExercise({
        id: exercise._id,
        exercise: data,
      });
    }
  };

  const _handleCloneExercise = async (): Promise<void> => {
    if (exercise == null) {
      return;
    }

    // show confirm alert and clone and redirect to new exercise
    modalStore?.showConfirmAlert({
      title: translate("cloneModal.title"),
      description: translate("cloneModal.description"),
      confirmLabel: translate("cloneModal.confirmLabel"),
      onConfirm: async () => {
        const clonedExercise = await exerciseStore?.cloneLibraryExercise(
          exercise
        );

        if (clonedExercise?._id == null) return;

        // navigate to cloned exercise
        navigate(`inventory/exercises/${clonedExercise._id}/info`);
      },
    });
  };

  const _builCopyExerciseSection = (): JSX.Element => {
    if (!isEditing || !isTemplate) {
      return <></>;
    }

    return (
      <InfoBox className="mt-20" title={translate("cloneInfo.title")}>
        <Row justifyContent="space-between" alignItems="center" gap={25}>
          <RunningText>{translate("cloneInfo.description")}</RunningText>
          <SizedContainer size={ContainerSizes.S}>
            <FilledButton
              disabled={isUploading}
              label={translate("cloneInfo.buttonLabel")}
              color="secondary"
              className="mr-15"
              onClick={async () => {
                await _handleCloneExercise();
              }}
            />
          </SizedContainer>
        </Row>
      </InfoBox>
    );
  };

  return (
    <form
      id="exercise-info-form"
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error(translate("toastError"));
      })}
    >
      <ComponentWrapper
        title={isEditing ? translate("editButton") : translate("addButton")}
      >
        <FormWrapper>
          <OutlinedTextInput
            disabled={isTemplate}
            label={translate("title")}
            inputRef={register(`title.${currentLanguage?.value}`)}
            inputRefValue={getValues(`title.${currentLanguage?.value}`)}
            validationMessage={getFormattedErrorMessage("Titel", errors.title)}
          />

          <SelectDropDown
            disabled={isTemplate}
            label={translate("difficultyLevel")}
            selectedItem={difficultyLevelOptions?.find(
              (item) => item.value === exercise?.difficultyLevel
            )}
            items={difficultyLevelOptions ?? []}
            onChange={(item) => {
              setValueAndMarkDirty("difficultyLevel", item?.value);
              clearErrors("difficultyLevel");
            }}
            inputRef={register("difficultyLevel")}
            validationMessage={errors.difficultyLevel?.message?.toString()}
          />
        </FormWrapper>

        <FormWrapper className="mt-20">
          <SelectDropDown
            disabled={isTemplate}
            isMulti
            label={translate("agonist") ?? ""}
            isLoading={propertyStore?.properties?.isLoading}
            selectedItem={exercise?.muscleGroups?.agonist}
            labelPropertyName={`title.${currentLanguage.value}`}
            valuePropertyName="_id"
            items={muscleGroupProperties ?? []}
            inputRef={register("muscleGroups.agonist")}
            validationMessage={getFormattedErrorMessage(
              "Agonist",
              (errors as any).muscleGroups?.agonist
            )}
            onChange={(item) => {
              setValueAndMarkDirty("muscleGroups.agonist", item);
              clearErrors("muscleGroups.agonist");
            }}
          />
          <SelectDropDown
            disabled={isTemplate}
            isMulti
            label={t("exerciseDetailPage.form.synergist") ?? ""}
            isLoading={propertyStore?.properties?.isLoading}
            selectedItem={exercise?.muscleGroups?.synergist}
            labelPropertyName={`title.${currentLanguage.value}`}
            valuePropertyName="_id"
            items={muscleGroupProperties ?? []}
            inputRef={register("muscleGroups.synergist")}
            validationMessage={getFormattedErrorMessage(
              "Synergist",
              (errors as any).muscleGroups?.synergist
            )}
            onChange={(item) => {
              setValueAndMarkDirty("muscleGroups.synergist", item);
              clearErrors("muscleGroups.synergist");
            }}
          />
          <SelectDropDown
            disabled={isTemplate}
            isMulti
            label={t("exerciseDetailPage.form.antagonist") ?? ""}
            isLoading={propertyStore?.properties?.isLoading}
            selectedItem={exercise?.muscleGroups?.antagonist}
            labelPropertyName={`title.${currentLanguage.value}`}
            valuePropertyName="_id"
            items={muscleGroupProperties ?? []}
            inputRef={register("muscleGroups.antagonist")}
            validationMessage={getFormattedErrorMessage(
              "Antagonist",
              (errors as any).muscleGroups?.antagonist
            )}
            onChange={(item) => {
              setValueAndMarkDirty("muscleGroups.antagonist", item);
              clearErrors("muscleGroups.antagonist");
            }}
          />
        </FormWrapper>

        <FormWrapper className="mt-20">
          <SelectDropDown
            disabled={isTemplate}
            isMulti
            label={t("exerciseDetailPage.form.properties") ?? ""}
            isLoading={propertyStore?.properties?.isLoading}
            selectedItem={exercise?.properties}
            labelPropertyName={`title.${currentLanguage.value}`}
            valuePropertyName="_id"
            items={exerciseProperties ?? []}
            inputRef={register("properties")}
            validationMessage={getFormattedErrorMessage(
              "properties",
              (errors as any).muscleGroups?.synergist
            )}
            onChange={(item) => {
              setValueAndMarkDirty("properties", item);
              clearErrors("properties");
            }}
          />
        </FormWrapper>

        <FormWrapper className="mt-20">
          <Wrap alignItems="flex-start">
            <SizedContainer size={ContainerSizes.M} className="mr-30">
              <FileUpload
                disabled={isTemplate}
                label={t("exerciseDetailPage.form.imageUpload") ?? ""}
                aspectRatio={1 / 1}
                folder="exercise-images"
                inputRef={register("coverImageUrl")}
                fileUrl={getProperty(exercise, "coverImageUrl")}
                validationMessage={errors.coverImageUrl?.message?.toString()}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValueAndMarkDirty("coverImageUrl", files[0].path);
                    clearErrors("coverImageUrl");
                  }
                }}
                isUploading={(uploading) => {
                  setIsUploading(uploading);
                  isUploadingCallback(uploading);
                }}
              />
            </SizedContainer>
            <SizedContainer size={ContainerSizes.M}>
              <FileUpload
                disabled={isTemplate}
                label={t("exerciseDetailPage.form.videoUpload") ?? ""}
                accept={{ "video/*": [".mp4"] }}
                aspectRatio={9 / 16}
                folder="exercise-videos"
                inputRef={register("videoUrl")}
                fileUrl={getProperty(exercise, "videoUrl")}
                validationMessage={errors.videoUrl?.message?.toString()}
                isUploading={(uploading) => {
                  setIsUploading(uploading);
                }}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValueAndMarkDirty("videoUrl", files[0].path);
                    clearErrors("videoUrl");
                  } else {
                    setValueAndMarkDirty("videoUrl", undefined);
                  }
                }}
              />
            </SizedContainer>
          </Wrap>
        </FormWrapper>
      </ComponentWrapper>

      {_builCopyExerciseSection()}
    </form>
  );
};

export default inject(
  "exerciseStore",
  "propertyStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseForm));
