import React, { useEffect } from "react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { inject, observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import FeedStore from "stores/feed.store";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import Column from "components/general.compoenents/column.component/column.component";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface FeedContentItemFormProps {
  feedStore?: FeedStore;
  isEditing?: boolean;
  isUploading?: (uploading: boolean) => void;
  onDirty?: (isDirty: boolean) => void;
}

const FeedContentItemForm = ({
  feedStore,
  isEditing,
  isUploading,
  onDirty,
}: FeedContentItemFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.content.form");
  const navigate = navigationHelper();

  const feedItem = feedStore?.currentFeed?.data;
  const feedConfig = feedStore?.currentFeedConfig?.data;

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useFormContext();

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty]);

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  // handle feed form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (feedItem == null) {
      return;
    }

    feedStore?.setCurrentFeed({
      ...feedItem,
      ...data,
    });

    if (!isEditing) {
      const createdItem = await feedStore?.createFeedItem(data);

      // navigate to created feed item
      if (createdItem?._id != null && feedConfig?._id != null) {
        navigate(`feed/${feedConfig?._id}/content/${createdItem._id}`);
      }
    } else {
      await feedStore?.updateFeedItem(data);
    }

    onDirty?.(false);
  };

  // if feed item is null (if reload page or navigate to wrong page)
  if (feedItem == null) {
    navigate(`feed/${feedConfig?._id}/content`);
  }

  return (
    <form
      id="feed-content-item-form"
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error(translate("onSubmitError"));
      })}
    >
      <ComponentWrapper>
        <FormWrapper title={translate("headlineInfo")}>
          {/* // TODO add if new or edit */}
          <Row gap={20}>
            <SizedContainer size={ContainerSizes.M}>
              <FileUpload
                label={translate("coverImage")}
                aspectRatio={1 / 1}
                folder="articles"
                inputRef={register("coverImageUrl")}
                validationMessage={errors.coverImageUrl?.message?.toString()}
                fileUrl={getProperty(feedItem, "coverImageUrl")}
                onLibraryChoosed={(data) => {
                  setValueAndMarkDirty("coverImageUrl", data.url);
                  clearErrors("coverImageUrl");
                }}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValueAndMarkDirty("coverImageUrl", files[0].path);
                    clearErrors("coverImageUrl");
                  }
                }}
                isUploading={(uploading: boolean) => {
                  if (isUploading != null) {
                    isUploading(uploading);
                  }
                }}
              />
            </SizedContainer>

            <Column className="mt-15">
              <OutlinedTextInput
                label={translate("title")}
                inputRef={register("title")}
                inputRefValue={getValues("title")}
                validationMessage={errors.title?.message?.toString()}
              />
              <OutlinedTextInput
                label={translate("subTitle")}
                inputRef={register("subTitle")}
                inputRefValue={getValues("subTitle")}
                validationMessage={errors.subTitle?.message?.toString()}
              />
            </Column>
          </Row>
        </FormWrapper>
        <FormWrapper title={translate("headlineText")} className="mt-20">
          <TextEditor
            content={feedItem?.content}
            inputContentChanged={(content: any) => {
              setValueAndMarkDirty("content", content);
              clearErrors("content");
            }}
            validationMessage={errors.content?.message?.toString()}
          />
        </FormWrapper>
      </ComponentWrapper>
    </form>
  );
};

export default inject("feedStore")(observer(FeedContentItemForm));
