import { Asset } from "schemas/asset.schemas/asset.schema";
import { v4 as uuidv4 } from "uuid";

export const transformToAssets = (files: any, folder: string = ""): Asset[] => {
  return files.map((file: Asset) => {
    return {
      _id: file._id || uuidv4(),
      url: file?.url,
      name: file?.originalName,
      type: file?.type,
      folder,
    };
  });
};
