import React, { useState, useEffect, TextareaHTMLAttributes } from "react";
import "./text.area.component.scss";
import classNames from "classnames";

interface TextAreaComponentProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  inputRef?: any;
  inputRefValue?: any; // use with inputRef to catch the value and set the label
  label?: string;
  initialValue?: string;
  validationMessage?: string;
  rows?: number;
  onChange?: (value: any) => void;
}

const TextAreaComponent = ({
  className,
  inputRef,
  inputRefValue,
  label,
  initialValue,
  rows = 5,
  validationMessage,
  onChange,
  ...props
}: TextAreaComponentProps): JSX.Element => {
  const { required, value, disabled, placeholder, readOnly, autoFocus } = props;

  const [currentValue, setCurrentValue] = useState(undefined as any);

  useEffect(() => {
    if (value) setCurrentValue(value);
    if (inputRefValue) setCurrentValue(inputRefValue);
  }, [value, inputRefValue]);

  let rOnChange: any = null;
  let restInputRef = {};

  if (inputRef) {
    const { onChange: rOnC, ...restInputR } = inputRef;
    rOnChange = rOnC;
    restInputRef = restInputR;
  }

  const handleChange = (e: any): void => {
    if (onChange) {
      onChange(e.target.value);
    }

    if (rOnChange) {
      rOnChange(e);
    }
    setCurrentValue(e.target.value);
  };

  const textAreaContainerClassName = classNames(
    {
      "default-text-area-container": true,
    },
    className
  );

  const textAreaFieldClassName = classNames({
    "default-text-area-field": true,
    "default-text-area-field--disabled": disabled,
    "default-text-area-field--readonly": readOnly,
    "default-text-area-field--error": validationMessage != null,
  });

  const textAreatLabelClassName = classNames({
    "default-text-area-label": label != null,
    "default-text-area-label--disabled": disabled,
    "default-text-area-label--readonly": readOnly,
    "default-text-area-label--on-top": placeholder ?? currentValue,
    "default-text-area-label--error": validationMessage != null,
  });

  const textAreaRequiredClassName = classNames({
    "text-area-required-field": true,
    "text-area-required-field--error": validationMessage != null,
  });

  return (
    <div className={textAreaContainerClassName}>
      <textarea
        {...restInputRef}
        disabled={disabled}
        readOnly={readOnly}
        value={currentValue}
        defaultValue={initialValue}
        placeholder={placeholder}
        className={textAreaFieldClassName}
        style={{ resize: "vertical" }}
        autoFocus={autoFocus}
        rows={rows}
        onChange={handleChange}
      />
      {label != null && (
        <label aria-required className={textAreatLabelClassName}>
          {validationMessage ?? label}
          {required && <span className={textAreaRequiredClassName}> *</span>}
        </label>
      )}
    </div>
  );
};

export default TextAreaComponent;
