import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import FeedConfigForm from "./components/feed.config.form.component";
import Column from "components/general.compoenents/column.component/column.component";
import { isEqual } from "lodash";

interface FeedConfigDetailPageProps {
  feedStore?: FeedStore;
}

const FeedConfigDetailPage = ({
  feedStore,
}: FeedConfigDetailPageProps): JSX.Element => {
  const { feedConfigID } = useParams();

  const isEditing = feedConfigID !== "new";
  const [formIsDirty, setFormIsDirty] = useState(false);

  const currentFeedConfig = feedStore?.currentFeedConfig;

  useEffect(() => {
    initializePage();
  }, [feedConfigID]);

  const initializePage = async (): Promise<void> => {
    if (feedConfigID == null) {
      return;
    }

    if (feedConfigID !== "new") {
      await feedStore?.fetchAndSetFeedConfigItem({ feedConfigID });
    }
  };

  if (!isEditing && !isEqual(feedStore?.currentFeedConfig?.data, {})) {
    feedStore?.createInitialFeedConfigItem();
  }

  const onSubmit = async (data: any): Promise<void> => {
    if (currentFeedConfig?.data == null) return;

    feedStore?.setCurrentFeedConfig({
      ...currentFeedConfig?.data,
      ...data,
    });

    if (!isEditing) {
      // create new feed config item
      await feedStore?.createFeedConfig({
        feedConfig: data,
      });
    } else {
      // update feed config item
      await feedStore?.updateFeedConfig({
        id: currentFeedConfig?.data?._id as string,
        feedConfig: data,
      });
    }

    setFormIsDirty(false);
  };

  if (feedStore?.currentFeedConfig?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <Column alignItems="center">
      <FeedConfigForm
        key={feedConfigID}
        onFormSubmit={onSubmit}
        formIsDirty={formIsDirty}
        onDirty={(isDirty) => {
          setFormIsDirty(isDirty);
        }}
      />
    </Column>
  );
};

export default inject("feedStore")(observer(FeedConfigDetailPage));
