import React, { useEffect } from "react";
import "../task.management.modal.component.scss";
import { UseFormReturn } from "react-hook-form";
import { inject, observer } from "mobx-react";
import AssetStore from "stores/asset.store";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { Task } from "schemas/task.schemas/task.schema";
import { redirectToExternalResource } from "globals/helpers/navigation.helper";
import { AssetType } from "globals/enums/global.enum";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { transformToAssets } from "globals/helpers/asset.transform.helper";

import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import Column from "components/general.compoenents/column.component/column.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";

interface TaskAttachmentFormProps {
  assetStore?: AssetStore;
  formMethods: UseFormReturn<Task, any, undefined>;
}

const TaskAttachmentForm = ({
  assetStore,
  formMethods,
}: TaskAttachmentFormProps): JSX.Element => {
  const folder = "attachments";
  const assetSelectionLimit = 10;

  const taskAttachments = assetStore?.assets?.data ?? [];
  const uploadedAssets = assetStore?.uploadedAssets;
  const selectedUploadedAssets = assetStore?.selectedUploadedAssets;

  const { setValue } = formMethods;

  useEffect(() => {
    if (selectedUploadedAssets?.length) {
      // Set the form dirty when select uploaded assets
      setValue("attachments", [""], { shouldDirty: true });
    }
  }, [selectedUploadedAssets]);

  return (
    <Column className="task-info-container" gap={10}>
      {!!taskAttachments.length && (
        <ListDataTable
          data={taskAttachments}
          tableClassName="attachments-list"
          columns={[
            {
              flex: 1,
            },
            {
              flex: 1,
            },
            {
              flex: 1,
            },
          ]}
          dataTableItemBuilder={(dataItem: Asset, index: number) => {
            return {
              key: dataItem._id,
              children: [
                {
                  child: (
                    <Row className="attachment-title" alignItems="center">
                      <Image
                        className="mr-10 attachment-image"
                        rounded={false}
                        imageUrl={
                          dataItem.type && dataItem.type !== AssetType.IMAGE
                            ? ""
                            : dataItem.url
                        }
                      />
                      <span style={{ fontSize: "14px" }}>
                        {dataItem.name ?? ""}
                      </span>
                    </Row>
                  ),
                },
                {
                  child: (
                    <Row justifyContent="center" alignItems="center">
                      <FontAwesomeIcon
                        className="uploaded-check-icon"
                        icon={faCheck}
                      />
                      {`Completed`}
                    </Row>
                  ),
                },
                {
                  child: (
                    <Row justifyContent="flex-end">
                      <FontAwesomeIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          redirectToExternalResource(dataItem.url);
                        }}
                        className="uploaded-item-icon"
                        icon={faEye}
                      />
                      <FontAwesomeIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          const updatedAssets = taskAttachments?.filter(
                            (item: any) => item?._id !== dataItem._id
                          );
                          // Remove attachment from Task attachments list
                          assetStore?.setAssets(updatedAssets);
                          // Set updated attachment ids for the form field to save
                          const attachmentIds = updatedAssets.map(
                            (item) => item._id
                          );
                          setValue("attachments", attachmentIds, {
                            shouldDirty: true,
                          });
                        }}
                        className="uploaded-item-icon"
                        icon={faXmark}
                      />
                    </Row>
                  ),
                },
              ],
            };
          }}
          gap={30}
          disableHeader={true}
        />
      )}
      <FileUpload
        className="upload-container"
        aspectRatio={1 / 1}
        minHeight={160}
        folder={folder}
        fileUrl={getProperty({}, "coverImageUrl")}
        onFilesUploaded={(files) => {
          if (files && files.length > 0) {
            const assets = transformToAssets(files, folder);

            if (uploadedAssets) {
              let uploaded = assetStore?.uploadedAssets;
              uploaded = [...uploaded, ...assets];
              assetStore?.setUploadedAssets(uploaded);
            } else {
              assetStore?.setUploadedAssets(assets);
            }
          }
        }}
        isAssetsList={true}
        assetSelectionLimit={assetSelectionLimit}
      />
    </Column>
  );
};

export default inject("assetStore")(observer(TaskAttachmentForm));
