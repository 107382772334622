import React, { useEffect, useState } from "react";
import "./text.editor.component.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";

import Youtube from "@tiptap/extension-youtube";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";

import TextEditorToolbar from "../text.editor.toolbar.component/text.editor.toolbar.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Attachment from "components/general.compoenents/attachement.component/attachment.component";

interface TextEditorProps {
  content?: any;
  inputContentChanged?: any;
  handleSaveContent?: any;
  closeContentEditor?: any;
  handleAttachAsset?: any;
  validationMessage?: string;
  className?: string;
  minHeight?: number;
  isPlainText?: boolean;
  isEditing?: boolean;
  isSaveButton?: boolean;
  isAttachmentButton?: boolean;
  placeholder?: string;
}

const TextEditor = ({
  content,
  inputContentChanged,
  handleSaveContent,
  closeContentEditor,
  handleAttachAsset,
  validationMessage,
  className,
  minHeight = 330,
  isPlainText = false,
  isEditing = false,
  isSaveButton = true,
  isAttachmentButton = true,
  placeholder,
}: TextEditorProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "textEditor");

  const [contentEditor, setContentEditor] = useState(content);
  const [initalized, setInitalized] = useState(false);
  const [isContentChanged, setContentChanged] = useState(false);

  const editorClassName = classNames("text-editor-container", className);

  useEffect(() => {
    if (inputContentChanged != null && initalized) {
      inputContentChanged(contentEditor);
    }

    setInitalized(true);
  }, [contentEditor]);

  useEffect(() => {
    // Clear content if null
    if (content == null || content === "") {
      editor?.commands.setContent({});
    }
  }, [content]);

  const editor = useEditor(
    {
      editable: true,
      extensions: [
        StarterKit.configure({
          heading: false,
          codeBlock: {},
        }),
        Placeholder.configure({
          placeholder,
        }),
        Heading.configure({
          levels: [1, 2],
        }),
        Image,
        TextStyle,
        Youtube.configure({ controls: true }),
      ],
      content,
      onUpdate({ editor }) {
        setContentEditor(editor?.getJSON());
        setContentChanged(true);
      },
    },
    []
  );

  const _buildSaveButton = (): JSX.Element => {
    if (isEditing) {
      return isContentChanged ? (
        <FilledButton
          label={translate("save")}
          color="primary"
          onClick={() => handleSaveContent(contentEditor)}
        />
      ) : (
        <FilledButton
          label={translate("close")}
          color="primary"
          onClick={() => closeContentEditor()}
        />
      );
    }

    return (
      <FilledButton
        className="text-editor-container-button"
        label={translate("send")}
        icon={faPaperPlane}
        color="primary"
        onClick={() => handleSaveContent(contentEditor)}
      />
    );
  };

  return (
    <>
      <div className={editorClassName} style={{ minHeight }}>
        <div className="text-editor-container-content">
          <EditorContent editor={editor} />
        </div>
        {!isPlainText && (
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="text-editor-container-panel"
            gap={10}
          >
            <div className="text-editor-container-toolbar">
              <TextEditorToolbar editor={editor} />
            </div>
            {isAttachmentButton && <Attachment onAttach={handleAttachAsset} />}
            {isSaveButton && _buildSaveButton()}
          </Row>
        )}
      </div>
      {validationMessage != null && (
        <SmallText className="validation-message mt-5 ml-5">
          {validationMessage}
        </SmallText>
      )}
    </>
  );
};

export default TextEditor;
