import { toast } from "react-toastify";

export const showErrorMessage = (errors: any): void => {
  Object.keys(errors).forEach((key: string) => {
    if (errors[key].message) {
      toast.error(errors[key].message);
      return;
    }
    // get internal object errors recursively
    if (Object.keys(errors[key]).length) {
      showErrorMessage(errors[key]);
    }
  });
};
