import React, { useRef, useState } from "react";
import classNames from "classnames";
import Row from "components/general.compoenents/row.component/row.component";
import "./list.dropdown.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClickedOutside } from "globals/helpers/hook.helper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type DropdownPlacements = "left" | "right";
type DropDownColorSchema = "primary" | "secondary";

export interface ListDropdownItem<T> {
  icon?: IconProp;
  label: string;
  onClick?: (item: T, index?: number) => void;
}

export interface ListDropdownMenuProps<T> {
  className?: string;
  dropdownMenuButton: (props: any) => JSX.Element;
  dropdownButtonClasses?: string;
  items: Array<ListDropdownItem<T>>;
  placement?: DropdownPlacements;
  dataItem?: T;
  colorScheme?: DropDownColorSchema;
  onDropDownToggle?: (value: boolean) => void;
}

const ListDropdownMenu = <T,>({
  className,
  dropdownMenuButton: Button,
  dropdownButtonClasses: buttonClasses,
  items,
  placement = "right",
  dataItem,
  colorScheme = "primary",
  onDropDownToggle: onToggle,
}: ListDropdownMenuProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggleDropdown = (): void => {
    if (onToggle) {
      onToggle(!isOpen);
    }
    setIsOpen(!isOpen);
  };

  useClickedOutside(dropdownRef, () => {
    if (!isOpen) {
      return;
    }

    if (onToggle) {
      onToggle(false);
    }
    setIsOpen(false);
  });

  const simpleDropdownWrapperClasses = classNames({
    "simple-dropdown-wrapper": true,
    className,
  });

  const simpleDropdownMenuWrapperClasses = classNames({
    "simple-dropdown-menu-wrapper": true,
    "mt-10": true,
    "left-sided": placement === "left",
    "right-sided": placement === "right",
  });

  const dropdownButtonClasses = classNames(
    {
      "simple-dropdown-button": true,
      "simple-dropdown-button-is-open": isOpen && colorScheme === "primary",
      "simple-dropdown-button--secondary": colorScheme === "secondary",
      "simple-dropdown-button--secondary-is-open":
        isOpen && colorScheme === "secondary",
    },
    buttonClasses
  );

  const simpleDropDownItemClasses = classNames({
    "simple-dropdown-item": true,
    "simple-dropdown-item--secondary": colorScheme === "secondary",
  });

  const handleItemClick = (item: ListDropdownItem<T>): void => {
    item?.onClick?.((dataItem ?? item) as T);
    onToggle?.(false);
    setIsOpen(false);
  };

  return (
    <div className={simpleDropdownWrapperClasses} ref={dropdownRef}>
      <Button
        className={dropdownButtonClasses}
        onClick={() => handleToggleDropdown()}
      />
      {isOpen && (
        <div className={simpleDropdownMenuWrapperClasses}>
          <ul className="simple-dropdown-menu">
            {items.map((item) => (
              <li
                key={`${item.label}-list-dropdown-item`}
                className={simpleDropDownItemClasses}
                onClick={() => handleItemClick(item)}
              >
                <Row alignItems="center">
                  {item?.icon ? (
                    <FontAwesomeIcon icon={item.icon} className="mr-15" />
                  ) : (
                    ""
                  )}
                  {item.label}
                </Row>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ListDropdownMenu;
