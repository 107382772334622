import RootStore from "./root.store";
import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable } from "mobx";
import { HttpCommentService } from "services/httpClients/http.comment.client";
import { Comment } from "schemas/comment.schemas/comment.schema";
import {
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";

class CommentStore {
  private stores: RootStore;

  // Properties
  private _commentsDataList: PaginationDataList<Comment> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentComment: DataItem<Comment> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setComments = (comments: Comment[]): void => {
    this._commentsDataList.data = comments;
  };

  setCurrentComment = (comment: Comment): void => {
    this._currentComment.data = comment;
  };

  //! Getters
  get comments(): PaginationDataList<Comment> | undefined {
    if (this._commentsDataList == null) {
      return;
    }
    return this._commentsDataList;
  }

  get currentComment(): DataItem<Comment> | undefined {
    if (this._currentComment == null) {
      return;
    }
    return this._currentComment;
  }

  //! Methods
  fetchAndSetCommentsForTask = async (args: {
    taskId: string;
  }): Promise<void> => {
    const { taskId } = args;

    try {
      if (this._commentsDataList.isLoading) {
        return;
      }

      this._commentsDataList.isLoading = true;

      const comments = await HttpCommentService.getInstance().find({
        query: { task: taskId },
      });

      if (comments == null) {
        this._commentsDataList.isLoading = false;
        return;
      }

      this.setComments(comments);

      this._commentsDataList.isLoading = false;
    } catch (err) {
      this._commentsDataList.isLoading = false;

      Logging.error({
        className: "CommentStore",
        methodName: "fetchAndSetComments",
        message: "Could not get comments",
        exception: err,
        showAlert: true,
      });
    }
  };

  removeComment = async (args: {
    commentId: string;
    taskId: string;
  }): Promise<void> => {
    const { commentId, taskId } = args;

    try {
      await HttpCommentService.getInstance().archiveOne({ id: commentId });

      this._commentsDataList.isLoading = true;

      const comments = await HttpCommentService.getInstance().find({
        query: { task: taskId },
      });

      if (comments == null) {
        this._commentsDataList.isLoading = false;
        return;
      }

      this.setComments(comments);

      this._commentsDataList.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "CommentStore",
        methodName: "removeComment",
        message: "Could not remove comment",
        exception: err,
        showAlert: true,
      });
    }
  };

  createComment = async (args: {
    content: string;
    taskId: string;
    attachments: string[];
  }): Promise<void> => {
    const { content, taskId, attachments } = args;

    try {
      await HttpCommentService.getInstance().create({
        data: {
          text: content,
          task: taskId,
          attachments,
        },
      });

      this._commentsDataList.isLoading = true;

      const comments = await HttpCommentService.getInstance().find({
        query: { task: taskId },
      });

      if (comments == null) {
        this._commentsDataList.isLoading = false;
        return;
      }

      this.setComments(comments);

      this._commentsDataList.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "CommentStore",
        methodName: "createComment",
        message: "Could not create comment",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateComment = async (args: {
    taskId: string;
    commentId: string;
    data: any;
  }): Promise<void> => {
    const { taskId, commentId, data } = args;

    try {
      await HttpCommentService.getInstance().updateOne({
        id: commentId,
        data,
      });

      this._commentsDataList.isLoading = true;

      const comments = await HttpCommentService.getInstance().find({
        query: { task: taskId },
      });

      if (comments == null) {
        this._commentsDataList.isLoading = false;
        return;
      }

      this.setComments(comments);

      this._commentsDataList.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "CommentStore",
        methodName: "updateComment",
        message: "Could not update comment",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default CommentStore;
