import React, { useState } from "react";
import "../task.management.modal.component.scss";
import { UseFormReturn } from "react-hook-form";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import TaskStore from "stores/task.store";
import CommentStore from "stores/comment.store";
import { ModalStore } from "stores/modal.store";
import { Task } from "schemas/task.schemas/task.schema";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";

import Column from "components/general.compoenents/column.component/column.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import CommentCard from "./task.comment.card";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import AttachmentItem from "./task.attachment.item";

enum ContentType {
  DESCRIPTION = "DESCRIPTION",
  COMMENT = "COMMENT",
}

interface TaskInfoFormProps {
  errors: any;
  onErrorClear: (name: string) => void;
  formMethods: UseFormReturn<Task, any, undefined>;
  taskStore?: TaskStore;
  commentStore?: CommentStore;
  modalStore?: ModalStore;
}

const TaskInfoForm = ({
  errors,
  onErrorClear,
  formMethods,
  taskStore,
  commentStore,
  modalStore,
}: TaskInfoFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "taskPage.modal");

  const isNewTask = modalStore?.customData?.isNewTask;
  const task = taskStore?.currentTask?.data;
  const comments = commentStore?.comments?.data ?? [];

  const commentPlaceholder = translate("form.writeComment");
  const descriptionPlaceholder = translate("form.writeDescription");

  // open/close the text editor to edit the description
  const [isDescriptionEditing, setDescriptionEditing] = useState(false);
  // open/close the text editor to edit the comment
  const [isCommentEditing, setCommentEditing] = useState(false);

  const [commentContent, setCommentContent] = useState(null);
  const [attachments, setAttachments] = useState([] as Asset[]);

  const { register, setValue, getValues } = formMethods;

  const _buildCommentsContainer = (): JSX.Element => {
    return (
      <Column className="comments-container" gap={10}>
        {!comments?.length ? (
          <></>
        ) : (
          comments?.map((comment: any) => (
            <Row key={comment._id} justifyContent={"flex-end"}>
              <CommentCard
                data={{
                  id: comment._id,
                  profileImageUrl: comment.author.profileImageUrl,
                  email: comment.author.email,
                  text: comment.text,
                  modifiedAt: comment.system.modifiedAt,
                  attachments: comment.attachments,
                }}
                listDropdownMenu={{
                  items: [
                    {
                      label: translate("listDropdownMenu.edit"),
                      icon: faEdit,
                      onClick: (comment: any) => {
                        if (comment?.id == null) return;

                        comment._id = comment.id;
                        delete comment.id;
                        commentStore?.setCurrentComment(comment);
                        setCommentEditing(true);
                      },
                    },
                    {
                      label: translate("listDropdownMenu.delete"),
                      icon: faTrash,
                      onClick: (comment: any) => {
                        if (comment?.id == null) return;
                        removeComment(comment.id, comment.task);
                      },
                    },
                  ],
                  placement: "left",
                }}
              />
            </Row>
          ))
        )}
      </Column>
    );
  };

  const _buildCurrentAttachmentsContainer = (): JSX.Element => {
    if (!attachments.length) return <></>;
    return (
      <Row justifyContent="flex-start" className="current-attachment-container">
        {attachments.map((item: Asset) => {
          return (
            <AttachmentItem
              key={item._id}
              asset={item}
              isRemovable={true}
              handleRemoveAttachment={handleRemoveAttachment}
            />
          );
        })}
      </Row>
    );
  };

  const handleContentChange = (type: ContentType, content: any): void => {
    if (type === ContentType.DESCRIPTION) {
      setValue("description", JSON.stringify(content));
    }
  };

  const handleSaveContent = async (
    type: ContentType,
    content: any
  ): Promise<void> => {
    if (type === ContentType.COMMENT) {
      if (task?._id == null) return;

      if (isCommentEditing && commentStore?.currentComment?.data?._id) {
        const data = {
          text: JSON.stringify(content),
        };

        await commentStore?.updateComment({
          taskId: task?._id,
          commentId: commentStore.currentComment.data._id,
          data,
        });

        setCommentEditing(false);
      } else {
        setCommentContent(content);

        await commentStore?.createComment({
          content: JSON.stringify(content),
          taskId: task?._id,
          attachments: attachments.map((item) => item._id),
        });

        setAttachments([]);
        // clear Text editor field after creating a new comment
        setCommentContent(null);
      }
    }
    if (type === ContentType.DESCRIPTION) {
      if (taskStore?.currentTask?.data == null) return;

      const modifiedTask = {
        ...taskStore?.currentTask?.data,
        description: JSON.stringify(content),
      };
      taskStore?.setCurrentTask(modifiedTask);

      if (task?._id) {
        await taskStore?.updateTask({
          taskId: task._id,
          data: {
            description: JSON.stringify(content),
          },
        });
      }
      setDescriptionEditing(false);
    }
  };

  const handleAttachAsset = (type: ContentType, data: Asset[]): void => {
    if (type === ContentType.COMMENT) {
      if (task?._id == null) return;

      setAttachments([...attachments, ...data]);
    }
  };

  const handleRemoveAttachment = (id: string): void => {
    const updatedAttachements = attachments.filter((item) => item._id !== id);
    setAttachments(updatedAttachements);
  };

  const removeComment = (commentId: string, taskId: string): void => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        commentStore?.removeComment({ commentId, taskId });
      },
      confirmLabel: translate("deleteComment.confirmLabel"),
      title: translate("deleteComment.title"),
      description: translate("deleteComment.description"),
    });
  };

  const setDescription = (description: string): string | object | undefined => {
    try {
      return JSON.parse(description);
    } catch (err) {
      if (typeof description === "string") {
        return description;
      } else {
        toast.error(translate("form.onParseError"));
      }
    }
  };

  if (isNewTask) {
    return (
      <Column
        justifyContent="space-between"
        className="task-info-container"
        gap={10}
      >
        <OutlinedTextInput
          label={translate("form.title")}
          inputRef={register("title")}
          inputRefValue={getValues("title")}
          validationMessage={errors.title?.message?.toString()}
          onChange={(value) => {
            if (value) {
              onErrorClear("title");
            }
          }}
        />
        <TextEditor
          isSaveButton={false}
          isAttachmentButton={false}
          minHeight={150}
          placeholder={descriptionPlaceholder}
          inputContentChanged={(content: any) =>
            handleContentChange(ContentType.DESCRIPTION, content)
          }
          className="task-info-editor"
        />
      </Column>
    );
  } else {
    if (isDescriptionEditing) {
      return (
        <TextEditor
          content={task?.description && setDescription(task?.description)}
          inputContentChanged={(content: any) =>
            handleContentChange(ContentType.DESCRIPTION, content)
          }
          handleSaveContent={async (content: any) =>
            await handleSaveContent(ContentType.DESCRIPTION, content)
          }
          closeContentEditor={() => setDescriptionEditing(false)}
          className="task-info-editor--editable-description"
          isEditing={isDescriptionEditing}
          isAttachmentButton={false}
        />
      );
    } else if (isCommentEditing) {
      return (
        <TextEditor
          content={
            commentStore?.currentComment?.data?.text &&
            setDescription(commentStore?.currentComment?.data?.text)
          }
          handleSaveContent={async (content: any) =>
            await handleSaveContent(ContentType.COMMENT, content)
          }
          closeContentEditor={() => setCommentEditing(false)}
          className="task-info-editor--editable-comment"
          isEditing={isCommentEditing}
          isAttachmentButton={false}
        />
      );
    } else {
      return (
        <Column
          justifyContent="space-between"
          className="task-info-container"
          gap={10}
        >
          <Column
            justifyContent="flex-start"
            className="task-cards-container"
            gap={10}
          >
            <CommentCard
              data={{
                id: task?._id,
                profileImageUrl: task?.creator.profileImageUrl,
                email: task?.creator.email,
                text: task?.description,
                modifiedAt: task?.system.modifiedAt,
              }}
              isDescription={true}
              listDropdownMenu={{
                items: [
                  {
                    label: translate("listDropdownMenu.edit"),
                    icon: faEdit,
                    onClick: () => setDescriptionEditing(true),
                  },
                ],
                placement: "left",
              }}
            />
            {_buildCommentsContainer()}
          </Column>
          {_buildCurrentAttachmentsContainer()}
          <TextEditor
            minHeight={150}
            content={commentContent}
            placeholder={commentPlaceholder}
            inputContentChanged={(content: any) =>
              handleContentChange(ContentType.COMMENT, content)
            }
            handleSaveContent={async (content: any) =>
              await handleSaveContent(ContentType.COMMENT, content)
            }
            handleAttachAsset={(data: any) =>
              handleAttachAsset(ContentType.COMMENT, data)
            }
            className="task-info-editor"
          />
        </Column>
      );
    }
  }
};

export default inject(
  "taskStore",
  "commentStore",
  "modalStore"
)(observer(TaskInfoForm));
