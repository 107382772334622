import { useEffect, useRef, useState } from "react";

// helper to detect if a click was outside of a specific element
export const useClickedOutside = (ref: any, callback: any): void => {
  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

// helper to focus and select the initial value of an input field
export const useInitialFocusAndSelect = (
  inputValue: string,
  lastSearchTerm: string | undefined,
  isSelectText = true
): React.RefObject<HTMLInputElement> => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialFocusDone, setInitialFocusDone] = useState(false);

  useEffect(() => {
    if (!initialFocusDone && !inputValue && !lastSearchTerm) {
      setInitialFocusDone(true);
    }
    // focus and select the input field if it has a value
    if (!initialFocusDone && inputRef.current && isSelectText && inputValue) {
      inputRef.current.focus();
      inputRef.current.select();
      setInitialFocusDone(true); // mark as done to prevent re-triggering
    }
  }, [inputValue, lastSearchTerm]);

  return inputRef;
};
