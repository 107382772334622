import React from "react";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ExerciseStore from "stores/exercise.store";
import { infoListSchema } from "schemas/exercise.schemas/exercise.schema";
import { arrayMoveImmutable } from "array-move";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import ExerciseInfoContent from "./exercise.info.content.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import StudioStore from "stores/studio.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { CollectionDataType } from "globals/enums/global.enum";
import { ModalStore } from "stores/modal.store";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import { toast } from "react-toastify";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";
import { useTranslation } from "react-i18next";
import Column from "components/general.compoenents/column.component/column.component";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import {
  createTranslate,
  generatePlaceholderAvatarUrl,
} from "globals/helpers/global.helper";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface ExerciseInfoDetailPageProps {
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const ExerciseInfoDetailPage = ({
  exerciseStore,
  studioStore,
  modalStore,
}: ExerciseInfoDetailPageProps): JSX.Element => {
  const navigate = navigationHelper();
  const { topicID } = useParams();
  const { t } = useTranslation();
  const translate = createTranslate(t, "exerciseInfoDetailPage");

  const currentLanguage = studioStore?.currentLanguage.value;
  const currentLanguages = studioStore?.studio?.localizations ?? [];

  const currentExercise = exerciseStore?.currentExercise?.data;
  const currentTopic = currentExercise?.infos![topicID != null ? +topicID : 0];
  const isTemplate = currentExercise?.type === CollectionDataType.TEMPLATE;

  const topicData = (currentTopic?.data ?? []).map((data) => {
    return { ...data, id: Math.random().toString() };
  });

  const methods = useForm({
    resolver: yupResolver(infoListSchema(currentLanguages)),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: currentTopic,
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    setValue,
    getValues,
    register,
    watch,
  } = methods;

  const title = watch(`label.${currentLanguage}`);

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const onSubmit = async (data: any): Promise<void> => {
    if (currentExercise == null || CollectionDataType.TEMPLATE) {
      return;
    }

    const updatedInfos = currentExercise.infos;
    updatedInfos![topicID != null ? +topicID : 0] = data;

    exerciseStore?.setCurrentExercise({
      ...currentExercise,
      infos: updatedInfos,
    });

    await exerciseStore?.updateExercise({
      id: currentExercise._id!,
      exercise: {
        infos: updatedInfos,
      } as any,
    });
  };

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    const sortedData = arrayMoveImmutable(
      getValues("data"),
      oldIndex,
      newIndex
    );

    setValueAndMarkDirty("data", sortedData);
  };

  const deleteExerciseInfoListItem = (index: number): void => {
    const updatedInfoTopic = getValues("data").filter((_, i) => i !== index);

    setValueAndMarkDirty("data", updatedInfoTopic);

    const updatedTopic = {
      ...currentTopic!,
      data: updatedInfoTopic,
    };

    const updatedInfos = currentExercise?.infos;
    updatedInfos![topicID != null ? +topicID : 0] = updatedTopic;

    exerciseStore?.setCurrentExercise({
      ...currentExercise!,
      infos: updatedInfos ?? [],
    });
  };

  const handleDeleteInfoItem = async (): Promise<void> => {
    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        exerciseStore?.removeExerciseInfoListItem(+topicID!);
        navigate(`/inventory/exercises/${currentExercise?._id}/topics`);
      },
    });
  };

  return (
    <Column alignItems="center">
      <ComponentWrapper
        title={
          title?.length > 0 ? title : translate("componentWrapperHeadline")
        }
      >
        <FormProvider {...methods}>
          <form
            id="exercise-info-element-form"
            onSubmit={handleSubmit(onSubmit, (errors) => {
              toast.error(translate("toastError"));
            })}
          >
            <FormWrapper className="mb-15">
              <OutlinedTextInput
                disabled={isTemplate}
                placeholder={translate("themaTitle")}
                inputRef={register(`label.${currentLanguage}`)}
                inputRefValue={getValues(`label.${currentLanguage}`)}
                validationMessage={errors.label?.[currentLanguage!]?.message}
              />
            </FormWrapper>
            <FormWrapper
              title={translate("themaContentHeadline")}
              actions={
                <LinkButton
                  noPadding
                  label={translate("addElementButton")}
                  disabled={
                    (currentTopic?.data != null
                      ? currentTopic?.data.length >= 20
                      : false) || isTemplate
                  }
                  onClick={() => {
                    exerciseStore?.addNewExerciseInfoDataItem(
                      topicID != null ? +topicID : 0
                    );
                  }}
                />
              }
            >
              <SortableList
                disabled={isTemplate}
                data={topicData}
                onSortEnd={onSortEnd}
                itemBuilder={(_data, index) => {
                  return (
                    <>
                      <ExerciseInfoContent
                        disabled={isTemplate}
                        index={index}
                        onDeleted={(indexItem: number) => {
                          deleteExerciseInfoListItem(indexItem);
                        }}
                      />
                      {index !== topicData.length - 1 && (
                        <Spacer className="mb-20 mt-20" />
                      )}
                    </>
                  );
                }}
              />
            </FormWrapper>
          </form>
        </FormProvider>
      </ComponentWrapper>
      <FixedControllersToolbar
        handleDelete={!isTemplate ? handleDeleteInfoItem : undefined}
        formID="exercise-info-element-form"
        disabled={!isDirty}
        isLoading={false}
        hasUnsavedChanges={isDirty}
        keyString="exercise-info-form-btn"
        title={watch(`label.${currentLanguage}`)}
        imageUrl={generatePlaceholderAvatarUrl(
          watch(`label.${currentLanguage}`)
        )}
      />
    </Column>
  );
};

export default inject(
  "exerciseStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseInfoDetailPage));
